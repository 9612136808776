import { UserInfo, UserRow, UserAttributes, UserAttribute, AttributeNames, UserGroup, GroupNames } from "../interfaces/hooks"
import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'

export interface OriRfp {
    ori?: string
    sori?: string
    rfp: Array<string>
}

export interface OriGroup {
    primaryOri?: {
        field: string
        value: string
    }
    secondaryOri?: {
        field: string
        value: string
    }
    rfp: {
        field: string
        value: string
    }
}

export const oriRfpParse = (oriRfp: string | undefined, key: string) => {

    const oriRfpGroups: Array<OriGroup> = []

    if (oriRfp) {
        const parsedOriRfp = JSON.parse(oriRfp)

        //For updating and display in drawer

        //DLTODO Create more graceful parse checks
        if (key === "ori") {
            parsedOriRfp.forEach((oriRfpGroup: OriRfp) => {
                if (oriRfpGroup.ori && oriRfpGroup.rfp) {
                    oriRfpGroups.push({
                        primaryOri: {
                            field: "Primary Ori",
                            value: oriRfpGroup.ori!
                        },
                        rfp: {
                            field: "RFP",
                            value: oriRfpGroup.rfp.toString()
                        }
                    })
                }
            })
        } else if (key === "sori") {
            parsedOriRfp.forEach((oriRfpGroup: OriRfp) => {
                if (oriRfpGroup.sori && oriRfpGroup.rfp) {
                    oriRfpGroups.push({
                        secondaryOri: {
                            field: "Secondary Ori",
                            value: oriRfpGroup.sori!
                        },
                        rfp: {
                            field: "RFP",
                            value: oriRfpGroup.rfp.toString()
                        }
                    })
                }
            })
        }

        //For UI DISPLAY in Table
        let oris = ""
        let oriArray: Array<string> = []

        parsedOriRfp.forEach((oriRfp: OriRfp, index) => {

            if (!oriArray.includes(oriRfp[key])) {
                oriArray.push(oriRfp[key])
                //return oris = oris.concat( oriRfp.ori + " " || "")
            }

        })


        if (key !== "rfp") {
            oriArray.forEach((ori: string, index) => {
                if (index + 1 < oriArray.length) {
                    return oris = oris.concat(ori + " ")
                } else {
                    return oris = oris.concat(ori)
                }

            })
        } else {
            oriArray.forEach((rfpArray: any, index) => {
                if (rfpArray) {
                    console.log('rfpArray: ', typeof rfpArray[0])
                    //if(typeof rfpArray)
                    if (rfpArray[0].includes(",")) {
                        let rfps = rfpArray[0].split(",")
                        console.log('debug rfp display key rfp rfps: ', rfps)
                        rfps.forEach((rfp: string) => {
                            if (!oris.includes(rfp)) {
                                return oris = oris.concat(rfp + " ")
                            }
                        })
                    } else if (index + 1 < oriArray.length) {
                        return oris = oris.concat(rfpArray[0] + " ")
                    } else {
                        return oris = oris.concat(rfpArray[0].replace(',', ' '))

                    }
                } else {
                    return ""
                }
            })
        }

        const value = oris;
        const rfp = parsedOriRfp[0]?.rfp //Confirm how we want to display this in the case of multiple


        return {
            oriRfp: { [key]: value },
            oriRfpGroups
        }
    }
}

export const usersTransfrom = (userInfo: UserInfo, index: number, searchParams?: [{ column: string, value: string }]) => {
    //console.log('debug fetch users  usersTransform userInfo: ', userInfo)
    const attributes: UserAttributes = userInfo.Attributes
    const userGroup = userInfo.userGroup;

    const findAttributeValue = (attributeName: AttributeNames) => {
        //console.log('userManagement attributes: ', attributes)
        //console.log('userManagement attributeName: ', attributeName)
        let value: string | undefined = attributes.find((attribute: UserAttribute) => attribute.Name === attributeName)?.Value
        if (value === undefined) {
            value = ''
        }
        //console.log('debug fetch users userManagement value ', value)
        return value
    }

    const findUserSecurity = (data: any, keyName: string) => {
        let value;
        if (data.length > 0) {
            const keyValue = data[0][keyName];
            switch (keyValue) {
                case 'mc_admin':
                    value = 'Admin Super User';
                    break;
                case 'mc_edit':
                    value = 'Edit';
                    break;
                case 'mc_readonly':
                    value = 'Read-Only';
                    break;
                case 'mc_readonly_restricted_view':
                    value = 'Read-Only Restricted';
                    break;
                case 'mc_agency_admin':
                    value = 'AGENCY ADMIN';
                    break;
                case 'mc_state_police_admin':
                    value = 'State Police Admin';
                    break;
                case 'mc_state_police_user':
                    value = 'State Police User';
                    break;
                default:
                    value = keyValue;
                    break;
            }
        } else {
            value = '';
        }
        return value;
    };

    const isActive = (status: boolean): string => {
        let isActive;
        if (status) {
            isActive = 'Active'
        } else {
            isActive = 'Inactive'
        }
        return isActive
    }

    const securityAndStatus = {
        securityLevel: findUserSecurity(userGroup, GroupNames.GROUP_NAME),
        status: isActive(userInfo.Enabled)
    }

    const getFullName = () => {
        let firstName = findAttributeValue(AttributeNames.GIVEN_NAME)
        let lastName = findAttributeValue(AttributeNames.FAMILY_NAME)
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
        lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)
        //console.log(`useUserManagement helpers getFullName firstName: ${firstName}, lastName: ${lastName} fullName ${firstName + " " + lastName}`)
        if (firstName && lastName) {
            //console.log('useUserManagement helpers getFullName returning fullName: ', firstName + " " + lastName)
            return firstName + " " + lastName
        } else if (firstName) {
            return firstName
        } else {
            return ""
        }
    }

    const oriRfpGroups: Array<OriGroup> = []

    const getOriRfpGroups = ({ primaryOriValue, secondaryOriValue, fullName }) => {
        //console.log(`getOriRfpGroups running for ${fullName} primaryOriValue: `, primaryOriValue)
        //console.log(`getOriRfpGroups running for ${fullName} secondaryOriValue: `, secondaryOriValue)
        const keys = ["ori", "sori"]

        let parsedPrimaryOriRfp, parsedSecondaryOriRfp
        if (secondaryOriValue) {
            parsedSecondaryOriRfp = JSON.parse(secondaryOriValue)
        }
        if (primaryOriValue) {
            parsedPrimaryOriRfp = JSON.parse(primaryOriValue)
        }


        keys.forEach((key) => {
            if (key === "ori" && primaryOriValue) {
                parsedPrimaryOriRfp.forEach((oriRfpGroup: OriRfp) => {
                    if (oriRfpGroup.ori && oriRfpGroup.rfp) {
                        oriRfpGroups.push({
                            primaryOri: {
                                field: "Primary Ori",
                                value: oriRfpGroup.ori!
                            },
                            rfp: {
                                field: "RFP",
                                value: oriRfpGroup.rfp.toString()
                            }
                        })
                    }
                })
            } else if (key === "sori" && secondaryOriValue) {
                parsedSecondaryOriRfp.forEach((oriRfpGroup: OriRfp) => {
                    if (oriRfpGroup.sori && oriRfpGroup.rfp) {
                        oriRfpGroups.push({
                            secondaryOri: {
                                field: "Secondary Ori",
                                value: oriRfpGroup.sori!
                            },
                            rfp: {
                                field: "RFP",
                                value: oriRfpGroup.rfp.toString()
                            }
                        })
                    }
                })
            }
        })


        return oriRfpGroups

    }

    const STATE = process.env.REACT_APP_CONFIG_STATE!
    const primaryOriValue = findAttributeValue(AttributeNames.CUSTOM_ORI_LONG) !== '' ? findAttributeValue(AttributeNames.CUSTOM_ORI_LONG) : findAttributeValue(AttributeNames.CUSTOM_ORI)
    const secondaryOriValue = findAttributeValue(AttributeNames.CUSTOM_SORI)

    const normalizeRfp = (primaryRfp, secondaryRfp) => {
        const deDupe = (array: Array<string>) => {
            var a = array.concat();
            for (var i = 0; i < a.length; ++i) {
                for (var j = i + 1; j < a.length; ++j) {
                    if (a[i] === a[j])
                        a.splice(j--, 1);
                }
            }

            return a;
        }

        let rfps: string = "TEST"
        let primaryRfpArray = primaryRfp.split(" ")
        let secondaryRfpArray = secondaryRfp.split(" ")
        let rfpArray = deDupe(primaryRfpArray.concat(secondaryRfpArray))
        return rfpArray.join(" ")
    }

    const row: UserRow = {
        key: uuid(),
        fullName: getFullName(),
        loginName: userInfo.Username || "",
        securityLevel: "" || "",
        status: "" || "",
        emailAddress: findAttributeValue(AttributeNames.EMAIL) || "",
        primaryOri: findAttributeValue(AttributeNames.CUSTOM_ORI_LONG) !== '' ? oriRfpParse(findAttributeValue(AttributeNames.CUSTOM_ORI_LONG), "ori")?.oriRfp.ori || "" : oriRfpParse(findAttributeValue(AttributeNames.CUSTOM_ORI), "ori")?.oriRfp.ori || "",
        secondaryOri: oriRfpParse(findAttributeValue(AttributeNames.CUSTOM_SORI), "sori")?.oriRfp.sori || "",
        reasonFingerprinted: normalizeRfp(findAttributeValue(AttributeNames.CUSTOM_ORI_LONG) !== '' ? oriRfpParse(findAttributeValue(AttributeNames.CUSTOM_ORI_LONG), "rfp")?.oriRfp.rfp || " " : oriRfpParse(findAttributeValue(AttributeNames.CUSTOM_ORI), "rfp")?.oriRfp.rfp || " ", oriRfpParse(findAttributeValue(AttributeNames.CUSTOM_SORI), "rfp")?.oriRfp.rfp || ""), //Do we list primary and secondary
        lastModified: dayjs(userInfo.UserLastModifiedDate).format('MMM D, YYYY'),
        // had to make another one like this because it would render the button & the result
        securityAndStatus: securityAndStatus,
        oriRfpGroups: getOriRfpGroups({ primaryOriValue, secondaryOriValue, fullName: getFullName() }),
        userStatus: securityAndStatus.status,
        loginStatus: userInfo.UserStatus
    }


    console.log('debug fetch users listUsers userManagementHelpers row: ', row)

    if (searchParams && searchParams.length > 0) {
        let columnFilter = searchParams[0].column
        console.log(`debug fetch users listUsers userManagementHelpers in filter case columnFilter ${columnFilter}`)

        let columnValue = searchParams[0].value

        if (columnFilter != "securityLevel" && columnFilter != "status") {
            console.log(`debug fetch users outter`)
            if (columnFilter && columnValue && row[columnFilter].toLowerCase().includes(columnValue.toLowerCase())) {
                console.log(`debug fetch users returing row`)

                return row
            }
        } else {
            console.log(`debug fetch users outter`)
            if (columnFilter === "securityLevel") {
                console.log(`debug fetch users outter`)
                if (row.securityAndStatus?.securityLevel?.toLowerCase().includes(columnValue.toLowerCase())) {
                    console.log(`debug fetch users returing row`)
                    return row
                }
            } else if (columnFilter === "status") {
                console.log(`debug fetch users outter`)
                if (row.securityAndStatus?.status?.toLowerCase() === columnValue.toLowerCase()) {
                    console.log(`debug fetch users returing row`)
                    return row
                }
            }
        }



    } else {
        console.log(`debug fetch users outter returning row: `, row)
        return row
    }

}