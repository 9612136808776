/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Navigate, useNavigate } from 'react-router-dom'
import { Appbar } from 'components/AppBar/AppBar'
import { Outlet, useOutletContext, useLocation } from "react-router-dom";
import {
    Box, Button,
    Container,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography
} from '@mui/material';
import NavLinks from "components/AppBar/components/NavLinks"
import React, { useState } from 'react';
import { useMUITheme } from 'theme/ThemeProvider';
import { DrawerHeader } from "../../common/DrawerHeader"
import { useUserPermissions } from 'contexts';
import { AppLayoutStyle } from 'customStyles/index';
import { ErrorBoundary } from "react-error-boundary"

type ContextType = { expanded: boolean }

const ErrorModal: React.FC<any> = ({ error, resetErrorBoundary }) => {
    return (
        <Dialog PaperProps={{
            style: {
                minWidth: "600px", minHeight: "221px",
            }
        }} open={true}>
            <Paper sx={{ minWidth: "600px", minHeight: "221px", bgcolor: 'background.default' }}>
                <DialogTitle>Unexpected Error</DialogTitle>
                <DialogContent sx={{ height: "100%", paddingRight: '2.5rem' }}>
                    <Typography>
                        We have experienced an unexpected error and have returned you to Transaction Summary section. We apologize, if this continues
                        to happen please contact support.
                    </Typography>

                </DialogContent>
                <DialogActions sx={{ p: '1rem 1.5rem', gap: '0.5rem' }}>
                    {/* <Button onClick={() => handleCancel()} variant="outlined" sx={{ color: mode === 'dark' ? 'text.primary' : '#000' }}>
                        {cancelButtonText}
                    </Button>*/}
                    <Button onClick={() => resetErrorBoundary()} variant="contained" color='secondary'>
                        ACKNOWLEDGE
                    </Button>


                </DialogActions>
            </Paper>
        </Dialog>
    )
}

export const ProtectedLayout = () => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const location = useLocation();
    const applicantDetails = location.pathname.includes('applicant-details')
    const [open, setOpen] = useState(false);
    const { permissions } = useUserPermissions();
    const navigate = useNavigate();

    let loggedIn = localStorage.getItem('loggedIn')

    if (!loggedIn) {
        return <Navigate to="/" />
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorModal} onReset={() => navigate("/app/transaction-summary", { replace: true })}>;
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Appbar open={open} handleDrawerOpen={handleDrawerOpen} />
                <Container maxWidth={false} disableGutters sx={AppLayoutStyle.appContainerStyle}>
                    {
                        !applicantDetails && (
                            <Paper sx={{
                                ...AppLayoutStyle.navLinksContainerStyle,
                                minWidth: expanded ? "256px" : "64px",
                            }}
                                elevation={0}
                            >
                                <NavLinks
                                    usersPermissions={permissions}
                                    handleDrawerOpen={handleDrawerOpen}
                                    handleDrawerClose={handleDrawerClose}
                                    expanded={open}
                                    setOpen={setOpen}
                                />
                            </Paper>
                        )
                    }
                    <Box component="main" sx={{ flexGrow: 1, backgroundColor: 'background.paper' }}>
                        <Container maxWidth={false} disableGutters sx={{
                            ...AppLayoutStyle.protectedLayoutContainer,
                            width: applicantDetails ? "100%" : open
                                ? "calc(100vw - 256px)" : "calc(100vw - 64px)",
                        }} >
                            <DrawerHeader />
                            <Outlet context={open} />
                        </Container>
                    </Box>
                </Container>
            </Box>
        </ErrorBoundary>

    )
}

export function useExpanded() {
    return useOutletContext<ContextType>()
}
