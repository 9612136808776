/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { FooterLayout } from './Footer';
import { useLocalStorage } from "../../common";
import { Box, useTheme, Paper } from '@mui/material';
import { LoginNavbar } from '../Navbar/LoginNavbar'
import { AppLayoutStyle } from "customStyles/index";

export const AppLayout = () => {
    const [, , loggedIn] = useLocalStorage()
    const outlet = useOutlet();
    const theme = useTheme();
    const isDark = theme.palette.mode;
    const { loginLoading } = useAuth();

    if (loggedIn) {
        return <Navigate to="/app/transaction-summary" />;
    }

    return (
        <Paper sx={{
            ...AppLayoutStyle.loginBackground,
            background: isDark === 'dark' ? 'linear-gradient(180deg, #10111C 0%, #1C1D27 100%)' : '#F4F5F9'
        }} elevation={0}>
            <LoginNavbar />
            <Box sx={{
                ...AppLayoutStyle.loginContainer,
                backgroundColor: loginLoading ? 'linear-gradient(180deg, #10111C 0%, #1C1D27 100%)' : 'background.default',
                boxShadow: loginLoading ? 'none' : '0px 2px 1px -1px rgba(124, 124, 130, 0.20)'
            }}>
                {outlet}
            </Box>
            <FooterLayout />
        </Paper>
    )
};