import React, { useState } from 'react'
import { MenuItem, Menu, Tooltip, Button, IconButton, Divider } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useUserPermissions } from 'contexts/UserPermissionsContext';
import { useTableStore } from 'state/TableStore';
import EditIcon from '@mui/icons-material/Edit';
import { logger } from "../../../utils";

export const Actions: React.FC<any> = ({ row, pageName, isCurrentRowClicked, rowClickHandler }) => {
    const { permissions } = useUserPermissions();

    const setExtendModal = useTableStore(state => state.setExtendModal)
    const [tcns, setTcns] = useState<Array<any>>([])
    const [anchorEl, setAnchorEl] = useState(null);
    const openActions = Boolean(anchorEl);

    const currentRow = useTableStore((state) => state.currentRow)
    const setCurrentRow = useTableStore((state) => state.setCurrentRow)
    const recordId = useTableStore((state) => state.recordId)
    const setRecordId = useTableStore((state) => state.setRecordId)
    const extendTCN = useTableStore((state) => state.extendTCN)
    const setExtendTCN = useTableStore((state) => state.setExtendTCN)
    const openAuditHistory = useTableStore((state) => state.openAuditHistory)

    const actionsConfig = useTableStore(state => state.actionsConfig)
    const applicantName = useTableStore(state => state.applicantName);
    const setRowClicked = useTableStore(state => state.setRowClicked);

    const popoverId = openActions ? 'actions-popover' : undefined;

    const handleOpenActions = (e: any, key: string) => {
        e.stopPropagation()
        setCurrentRow(key)
        setAnchorEl(e.currentTarget);
    }

    const handleClose = (e: any) => {
        e.stopPropagation()
        setAnchorEl(null);
        setRowClicked(false);
    };

    const debugExtend = {
        identifier: "debug extendView",
        pageName: "transaction-summary",
        thingToDebug: "Actions",
        paramsToLog: {
            actionsConfig
        }
    }

    // logger(debugExtend)

    return (
        <>
            {pageName === 'user-management' ? isCurrentRowClicked &&
                <Button size='small' sx={{ width: '67px', height: '30px' }} onClick={rowClickHandler}>
                    <EditIcon sx={{ mr: '0.5rem', width: '18px', height: '18px' }} />
                    Edit
                </Button>
                :
                <Tooltip title="Open Actions">
                    <IconButton
                        sx={{ marginLeft: "", height: "20px", width: "20px" }}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            handleOpenActions(e, row.key)
                        }
                        }>
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
            }
            {
                row.key === currentRow && (
                    <Menu
                        onClose={(e) => handleClose(e)}
                        slotProps={{ paper: { sx: { width: (pageName === 'transaction-summary' || pageName === 'applicant-details') ? '220px' : '270px', backgroundColor: "background.default", border: "none", display: 'flex', flexDirection: "column" } } }}
                        id={popoverId}
                        open={openActions}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        anchorEl={anchorEl}
                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                        {
                            (pageName === 'transaction-summary' || pageName === 'applicant-details' || pageName === 'rapback-summary') && (
                                actionsConfig.map((action) => {
                                    return (
                                        <>
                                            {
                                                action.actionLabel === "Extend"
                                                    ? permissions.extendTransactionRecord && <MenuItem
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            handleClose(e)
                                                            action.actionHandler(e, row)

                                                        }}
                                                        sx={{ width: "270px", height: "36px" }}>
                                                        {action.actionLabel}
                                                    </MenuItem>
                                                    : <MenuItem
                                                        disabled={action.actionLabel === "No PDF available" ? true : false }
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            handleClose(e)
                                                            action.actionHandler(e, row)
                                                        }}
                                                        sx={{ width: '270px', height: "36px", }}>
                                                        {action.actionIcon ? action.actionIcon : <></>}
                                                        {action.actionLabel}
                                                    </MenuItem>
                                            }

                                        </>
                                    )
                                })
                            )
                        }
                        {
                            pageName === 'rapback-maintenance' && (
                                <>
                                    {
                                        actionsConfig.map((action) => {
                                            return (
                                                <>
                                                    {
                                                        <MenuItem
                                                            disabled={(action.actionLabel === "Edit Subscription" || action.actionLabel === "Unsubscribe") && (row.status.toLowerCase() === "unsubscribed" || row.status.toLowerCase().includes("pending"))}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                handleClose(e)
                                                                action.actionHandler(e, row)
                                                            }}
                                                            sx={{ width: "270px", height: "36px" }}>
                                                            {action.actionLabel}
                                                        </MenuItem>

                                                    }
                                                    {
                                                        action.actionLabel === "Unsubscribe" && (
                                                            <Divider />
                                                        )
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </>
                            )
                        }
                        {
                            pageName === 'rapback-prenotifications' && (
                                <>
                                    {

                                        actionsConfig.map((action) => {
                                            return (
                                                <MenuItem
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleClose(e)
                                                        action.actionHandler(e, row)
                                                    }}
                                                    sx={{ width: "270px", height: "36px" }}>
                                                    {action.actionLabel}
                                                </MenuItem>
                                            )
                                        })
                                    }

                                </>
                            )
                        }
                        {
                            pageName === 'rapback-validations' && (
                                <>
                                    {

                                        actionsConfig.map((action) => {
                                            return (
                                                <MenuItem
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleClose(e)
                                                        action.actionHandler(e, row)
                                                    }}
                                                    sx={{ width: "270px", height: "36px" }}>
                                                    {action.actionLabel}
                                                </MenuItem>
                                            )
                                        })
                                    }

                                </>
                            )
                        }
                    </Menu>
                )
            }
        </>
    )

}