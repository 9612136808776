import React, { useCallback, useEffect, useState } from 'react';
import {
    TableContainer,
    Table,
    Paper,
    Container,
    Divider
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CustomTableProps } from '@propTypes/index';
import { getComparator, stableSortNonQuery } from './helpers'
import { SimpleSearch, CustomTableHead, CustomTableBody, CustomTableFooter, TableTools } from './components'
import { CustomTableStyle } from 'customStyles/CustomTableStyle';
import { useTableStore } from 'state/TableStore';
import { PageNames } from 'interfaces/config';
import FilterListIcon from '@mui/icons-material/FilterList';
import { MenuState } from 'customEnums/MenuState';
import { useConfig } from "../../hooks/useConfig";
import { extractPageName } from './helpers';
import { LogConfig, logger } from "../../utils";
import { AdvancedSearchAction } from "customEnums/TransactionSummaryEnums";


export const CustomTable: React.FC<CustomTableProps> = ({
    rows,
    hideSearch,
    handleClickShowSSN,
    displaySSN,
    openModalBlur,
    openAgencyEdit,
    tcns,
    totalRecords,
    setSearched,
    handleDeselectAll,
    setValue,
    handleSelectAll,
    setDisplaySavedMessage,
    setCreateSearch,
    setSearchLength,
    advancedSearchNumParams,
    isSelectedRow,
    currentPage,
    pageSize,
    loading,
    columnStateUpdate,
    handleHideAll,
    handleShowAll,
    handleReset,
    searched,
    order,
    orderBy,
    handleRequestSort,
    selected,
    setSelected,
    value,
    selectAllState,
    rowClickHandler,
    tableName,
    setOrderBy,
    dispatchFormValues
}) => {
    const columnState = useTableStore((state => state.columnState))
    const setResetTable = useTableStore(state => state.setResetTable)
    const setSearchParams = useTableStore((state) => state.setSearchParams)
    const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)
    const setSimpleSearchRan = useTableStore(state => state.setSimpleSearchRan)

    const [datePlaceHolder, setDatePlaceholder] = React.useState(false)
    const [hoverLabel, setHoverLabel] = React.useState("")
    const [useQueryPagination, setUseQueryPagination] = useState(true)
    const [menuState, setMenuState] = useState(MenuState.CATEGORY)
    const [visibleRows, setVisibleRows] = useState([])

    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname);

    const applicantDetails = PageNames.APPLICANT_DETAILS === pageName
    const { config, headerCells } = useConfig()
    const tablesToPad = [
        "transaction-summary",
        "rapback-maintenance",
        "rapback-validations",
        "rapback-prenotifications",
        "rapback-summary",
        "agency-management",
        "user-management",
        "audit-history"
    ];


    const resetTable = () => {
        console.log('Debug CustomTable search resetSearchParams running dispatchFormValues: ', dispatchFormValues)
        dispatchFormValues?.({ type: AdvancedSearchAction.RESET })
        setSelected?.([])
        setValue([])
        setMenuState(MenuState.CATEGORY)
        setDatePlaceholder(false)
        handleDeselectAll?.()
        setSearched?.(false)
        setSimpleSearchRan(false)
        setSearchParams([])
        setDisplayNoSearchResults(false)
        setSearchLength?.(0)
        if (pageName === "transaction-summary") setOrderBy?.("transactionDate")
    }

    setResetTable(resetTable)

    const getUseQueryPagination = useCallback((tableName: string) => {
        const getUseQueryPaginationLog: LogConfig = {
            identifier: "getUseQueryPagination",
            pageName,
            thingToDebug: "getUserQueryPagination param and return",
            paramsToLog: {
                tableName,
                headerCells,
                returnValue: headerCells.find((cell: any) => cell.tableName === tableName)!.useQueryPagination!
            }
        }
        logger(getUseQueryPaginationLog);
        return headerCells.find((cell: any) => cell.tableName === tableName)!.useQueryPagination!
    }, [headerCells])

    useEffect(() => {
        if (tableName && headerCells.length) setUseQueryPagination(getUseQueryPagination(tableName))
    }, [tableName, headerCells.length])

    //update row display for userManagement here
    useEffect(() => {
        if (rows && useQueryPagination) {
            if ((currentPage && pageSize)) {
                setVisibleRows(rows)
            }
        } else if (rows && !useQueryPagination) {
            let visibleRows = stableSortNonQuery(rows, getComparator(order, orderBy)).slice(
                currentPage * pageSize,
                currentPage * pageSize + pageSize,
            )
            const debugTablePagination: LogConfig = {
                identifier: "debugTablePagination",
                pageName,
                thingToDebug: "CustomTableFooter onRowsPerPageChange visibleRows",
                paramsToLog: {
                    start: currentPage * pageSize,
                    end: currentPage * pageSize + pageSize,
                    currentPage,
                    pageSize,
                    rows,
                    visibleRows
                }
            }
            // console.log utility 
            logger(debugTablePagination)
            setVisibleRows(visibleRows);
        }
    }, [
        loading,
        rows,
        rows?.length,
        order,
        orderBy,
        currentPage,
        pageSize,
        isSelectedRow?.length,
        selected?.length,
        columnState,
        useQueryPagination
    ])

    useEffect(() => {
        if (isSelectedRow) {
            if (isSelectedRow.length > 0) {
                setSelected(isSelectedRow)
            }
        }
    }, [isSelectedRow?.length])

    useEffect(() => {
        const debugTablePagination: LogConfig = {
            identifier: "debugTablePagination",
            pageName: "transaction-summary",
            thingToDebug: "CustomTable visibleRows",
            paramsToLog: {
                visibleRows: visibleRows
            }
        }
        logger(debugTablePagination)
    }, [visibleRows.length]);

    return (
        <Paper sx={{ maxHeight: "calc(100% - 40px)", display: "flex", flexDirection: "column", width: '100%', backgroundColor: tableName !== "rapback-maintenance" && tableName && tableName !== "rapback-maintenance" ? 'common.black' : 'background.default' }} elevation={0}>
            {hideSearch ? null :
                <Container disableGutters maxWidth={false} sx={CustomTableStyle.tableTopStyle}>
                    <SimpleSearch
                        useQueryPagination={useQueryPagination}
                        tableName={tableName}
                        menuState={menuState}
                        setMenuState={setMenuState}
                        searched={searched}
                        value={value}
                        setValue={setValue}
                        label={pageName}
                        advancedSearchNumParams={advancedSearchNumParams ? advancedSearchNumParams : 0}
                        setCreateSearch={setCreateSearch ? setCreateSearch : () => { }}
                        datePlaceHolder={datePlaceHolder}
                        setDatePlaceholder={setDatePlaceholder}
                        setDisplaySavedMessage={setDisplaySavedMessage ? setDisplaySavedMessage : () => { }}
                    />
                    {
                        config.states && (<TableTools tableName={tableName} useQueryPagination={useQueryPagination} resetTable={resetTable} />)
                    }
                </Container>
            }
            {
                !applicantDetails && (
                    <Divider />
                )
            }
            <TableContainer sx={{ boxShadow: "none", borderRadius: 0 }} component={Paper}>
                <Table sx={{ overFlowX: 'scroll', width: "100%", paddingLeft: tableName && !tablesToPad.includes(tableName) ? 0 : 3, paddingBottom: 1, paddingRight: tableName && !tablesToPad.includes(tableName) ? 0 : 3, backgroundColor: tableName && tableName !== "rapback-maintenance" && tableName !== 'rb-sub-audit-history' && tableName !== "reject-codes" ? 'common.black' : "background.default", borderRadius: 0 }} stickyHeader aria-label={pageName}>
                    <CustomTableHead
                        currentPage={currentPage}
                        selectAllState={selectAllState}
                        tableName={tableName}
                        hoverLabel={hoverLabel}
                        setHoverLabel={setHoverLabel}
                        columnStateUpdate={columnStateUpdate}
                        handleHideAll={handleHideAll}
                        handleShowAll={handleShowAll}
                        handleReset={handleReset}
                        label={pageName}
                        numSelected={selected?.length}
                        rowCount={visibleRows?.length}
                        order={order}
                        orderBy={orderBy}
                        handleInternalSelectAll={handleSelectAll}
                        onRequestSort={handleRequestSort}
                        visibleRows={visibleRows.length ? visibleRows : []}
                    />
                    <CustomTableBody
                        tableName={tableName}
                        rowClickHandler={rowClickHandler}
                        openModalBlur={openModalBlur}
                        openAgencyEdit={openAgencyEdit}
                        loading={loading}
                        visibleRows={visibleRows.length ? visibleRows : []}
                        displaySSN={displaySSN}
                        handleClickShowSSN={handleClickShowSSN}
                    />
                </Table>
            </TableContainer>
            <Divider />
            <CustomTableFooter useQueryPagination={useQueryPagination} tcns={tcns} totalRecords={totalRecords} tableName={tableName} />
        </Paper>
    )
}