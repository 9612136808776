/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from 'react';
import {
    generatePersonalInformation,
    generateTransactionalInformation,
    generateUserProfileInfo,
} from 'utils/generatePersonData';
import { urls } from '../urls'
import { useConfig } from './useConfig';
import { ApplicantInfo, ApplicantFetchStatus } from '@hookTypes/index';

const initialApplicantInfo: ApplicantInfo = { userProfile: {}, personalInfo: {}, transactionalInfo: {} };

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json');

export const useApplicantDetails = (registrationId: string) => {
    const [applicantFetchStatus, setApplicantFetchStatus] = useState<ApplicantFetchStatus>('idle');
    const [applicantInfo, setApplicantInfo] = useState<ApplicantInfo>(initialApplicantInfo);
    const [applicantImageUrl, setApplicantImageUrl] = useState<any>();
    const { config } = useConfig()
    const url = urls.PERSON
    const STATE = process.env.REACT_APP_CONFIG_STATE!
    console.log('useApplicantDetails config STATE: ', STATE)
    const controller = new AbortController();
    console.log('debug config: ', config)
    console.log(`environment: ${JSON.stringify(process.env, null, "\t")}`)
    useEffect(() => {
        if(config.hasOwnProperty("states")) {
            console.log('useApplicantDetails got config: ', config)

            const { nistParsing } = config.states?.[STATE]
            console.log('debug nistparsing', nistParsing)

            setApplicantFetchStatus('loading');
            const { signal } = controller;
            const fetchApplicantData = async () => {
                try {
                    const applicantData = await (await fetch(`${process.env.NODE_ENV === 'production' ? url : ""}/registration/${registrationId}`, {
                        method: 'GET',
                        credentials: "include",
                        signal
                    })).json();

                    console.log('update decision applicantData: ', applicantData)

                    const formattedPersonInfo = {
                        userProfile: generateUserProfileInfo(applicantData, nistParsing),
                        personalInfo: generatePersonalInformation(applicantData, nistParsing),
                        transactionalInfo: generateTransactionalInformation(applicantData),

                    }
                    console.log('ApplicantDetails useApplicantDetaisl formattedPersonInfo: ', formattedPersonInfo)
                    setApplicantInfo(formattedPersonInfo);

                    //we need to get the image access url some other way as we do not use identity pool and it is required for authentication to the aws bucket. 
                    /*if(applicantData.biometricItems) {
                        const imagePath = `${applicantData.biometricItems[0].image.data.content}`;
                        console.log(`applicant2 imagePath: ${JSON.stringify(imagePath)}`);
                        const imageAccessURL = await Storage.get(imagePath);
                        console.log(`applicant2 imageAccessURL: ${JSON.stringify(imageAccessURL)}`);

                        setApplicantImageUrl(imageAccessURL);
                    }*/


                    setApplicantFetchStatus('success');
                } catch (err) {
                    console.error(err);
                    setApplicantFetchStatus('error');
                }
            }

                fetchApplicantData();
        } else {
            console.log('useApplicantDetails config EMPTY')
        }
        

        return () => controller.abort()
        
    }, [config, registrationId, url]);

    return { applicantInfo, applicantImageUrl, applicantFetchStatus };
}
