/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { ExtendModal, AdvancedSearch, RejectCodeTable } from './components'
import { CustomTable } from 'common/CustomTable/CustomTable';
import { Paper, Typography, IconButton, AlertTitle, Tooltip } from '@mui/material'
import { CustomTableTitleAndAlert, RightDrawer, useLocalStorage } from '../../common'
import { useExpanded } from 'components/layouts/ProtectedLayout';
import { useConfig, useQueryRapBack, useResultsResponse, useUserCognito } from "hooks";
import { useQueryTransactions } from "hooks";
import Alert from '@mui/material/Alert';
import { useConfigService } from 'hooks/useConfigService';
import CloseIcon from '@mui/icons-material/Close';
import { useUserPermissions } from 'contexts'
import { useLocation } from "react-router-dom";
import { SavedSearchActionTypes } from '../../customEnums/TransactionSummaryEnums';
import { Order } from 'customTypes/index';
import { AlertStyles, pageStyles } from 'customStyles/common/index';
import { getCurrentEnv } from 'utils/getCurrentEnv'
import { useTableStore } from 'state/TableStore';
import { GetMaintenanceSchedule } from 'utils/getMaintenanceSchedule';
import { Auth } from 'aws-amplify';
import { useAudit } from "hooks/useAudit";
import { searchReducer } from "./helpers/searchReducer";
import { AlertColor } from '../../customEnums/AlertColor';
import { extractPageName } from '../../common/CustomTable/helpers';
import { savedSearchesReducer } from "./helpers/savedSearchesReducer";
import { useRapbackSubscription } from 'hooks/useRapbackSubscription';
import { LogConfig, logger } from "../../utils";

export const TransactionSummary: React.FC = () => {
  const STATE = getCurrentEnv
  const locationObj = useLocation()
  const pageName = extractPageName(locationObj.pathname)
  const configService = useTableStore(state => state.configService)
  const setConfigService = useTableStore(state => state.setConfigService)
  const searchParams = useTableStore((state) => state.searchParams)
  const setSearchParams = useTableStore((state) => state.setSearchParams)
  const pagination = useTableStore((state) => state.pagination)
  const setPagination = useTableStore(state => state.setPagination)
  const displayNoSearchResults = useTableStore((state) => state.displayNoSearchResults)
  const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)
  const outstandingRapback = useTableStore(state => state.outstandingRapback)

  const { rowsPerPage, page } = pagination

  const { addAuditEvent } = useAudit();
  const [sortOrder, setSortOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('transactionDate');
  const [displaySSN, setDisplaySSN] = useState('')
  const [showSSN, setShowSSN] = useState<boolean>(false);
  const { userORIs, userRole, userSecondaryORIs } = useUserCognito();
  const [extendMessage, setExtendMesage] = useState<string>('');
  const extendModal = useTableStore(state => state.extendModal)
  const setExtendModal = useTableStore(state => state.setExtendModal)
  const [tcns, setTcns] = useState<Array<any>>([])
  const [extendTCN, setExtendTCN] = useState<string>("")
  const [selected, setSelected] = useState<any>([])
  const [createSearch, setCreateSearch] = useState(false);
  const [formValues, setFormValues] = useState<any>({})
  const [extendStatus, setExtendStatus] = useState()
  const [closeAlert, setCloseAlert] = useState<boolean>(false);
  const [loadConfig, setLoadConfig] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState(false)
  const [data, setData] = useState<any[]>([]);
  //Set true when a simple singular parameter search has run
  const [searched, setSearched] = useState<boolean>(false); //Set true when an advanced form multi parameter search has run
  const [value, setValue] = React.useState<any>([]);
  const [appName, setAppName] = useState<string>('CARES');
  const [displaySavedMessage, setDisplaySavedMessage] = useState<boolean>(false);
  const [searchLength, setSearchLength] = useState(0)
  const [rejectName, setRejectName] = useState("")

  const isPost = useTableStore((state) => state.isPost)
  const setIsPost = useTableStore((state) => state.setIsPost)
  const isDelete = useTableStore((state) => state.isDelete)
  const setIsDelete = useTableStore((state) => state.setIsDelete)
  const configFile = useTableStore((state) => state.configFile)
  const setConfigFile = useTableStore((state) => state.setConfigFile)
  const configBody = useTableStore((state) => state.configBody)
  const setConfigBody = useTableStore((state) => state.setConfigBody)
  const simpleSearchRan = useTableStore((state) => state.simpleSearchRan)
  const recordId = useTableStore((state) => state.recordId)
  const setRecordId = useTableStore((state) => state.setRecordId)
  const setSeverity = useTableStore(state => state.setSeverity);
  const setAlertTitle = useTableStore(state => state.setAlertTitle);
  const setAlertMessage = useTableStore(state => state.setAlertMessage);
  const needsAttention = useTableStore(state => state.needsAttention);
  const setHandleOpenRejectDrawer = useTableStore(state => state.setHandleOpenRejectDrawer)
  const setActionsConfig = useTableStore(state => state.setActionsConfig)
  const openNotification = useTableStore((state) => state.openNotification)
  const setOpenNotification = useTableStore((state) => state.setOpenNotification)

  const { startDate, endDate, currentDate } = GetMaintenanceSchedule();
  const { config, transactionViewing, filters } = useConfig();
  const { expanded } = useExpanded()
  const { searchData, alertData, postedConfig } = useConfigService(configFile, configBody, true, isPost, isDelete);
  const { permissions } = useUserPermissions();
  console.log('permissions', permissions)
  const [formState, dispatchFormValues] = useReducer(searchReducer, { firstName: '' })
  const [clearRecordLimitAlert, setClearRecordLimitAlert] = useLocalStorage("clearRecordLimit")
  const loadedData = useMemo(() => ({ ...searchData }), [searchData]);

  const {} = useRapbackSubscription(pageName, 
    rowsPerPage,
    page,
    sortOrder,
    orderBy,
    userORIs,
    userSecondaryORIs,
    userRole,
    searchParams, true
  );

  const {
    data: mappedData,
    extendViewing,
    error,
    loading,
    totalRecords
  } = useQueryTransactions(
    rowsPerPage,
    page,
    sortOrder,
    orderBy,
    userORIs,
    userSecondaryORIs,
    userRole,
    searchParams,
    true);

  const [savedSearches, dispatchSearch] = useReducer(savedSearchesReducer, loadedData);
  const [registrationId, setRegistrationId] = useState("")

  const { loadingRejectCodes, rejectData, totalRejectCodes } = useResultsResponse(registrationId as string);
  const [rejectTableData, setRejectTableData] = useState([])


  console.log('Debug userRole: ', userRole)

  const setInternalSearchArray = useTableStore(state => state.setInternalSearchArray)
  //Needed for all pages that use query service
  useEffect(() => {
    setPagination({ ...pagination, page: 1 })
    console.log(`debug visibleRows page ${page}, rowsPerPage: ${rowsPerPage}`)
  }, [])

  useEffect(() => {
    console.log('Reject Drawer reject data: ', rejectData)
    if (rejectData) setRejectTableData(rejectData)
  }, [rejectData]);

  useEffect(() => {
    console.log('createSearch formState: ', formState)
    const formStateProps = Object.keys(formState)
    if (formStateProps.length === 1 && formStateProps[0] === "days") {
      console.log('createSearch update searchParams')
      setSearchParams([{ column: "days", value: formState.days }])
    }
  }, [Object.keys(formState).length])

  useEffect(() => {
    // commented out due to privacy violation
    // console.log('TransactionSummary mappedData data: ', data)
  }, [data])

  useEffect(() => {
    console.log('TransactionSummary mappedData: ', mappedData)
    setData(
      mappedData?.map((data: any) => {
        return {
          key: data.key,
          ...(STATE != "ga" ? { rfp: data.rfp } : { stateRFPCode: data.stateRFPCode }),
          ...(STATE === "ga" && { stateRFPLiteral: data.stateRFPLiteral }),
          fullName: data.fullName,
          lastName: data.lastName,
          firstName: data.firstName,
          middleName: data.middleName,
          ueid: data.ueid,
          dob: data.dob,
          ssn: data.ssn,
          gender: data.gender,
          race: data.race,
          status: data.status,
          hitNoHit: data.hitNoHit,
          agency: data.agency,
          secondaryOri: data.secondaryOri,
          transactionType: data.transactionType,
          tcn: data.tcn,
          transactionDate: data.transactionDate,
          viewed: data.viewed,
          viewedDate: data.viewedDate,
          viewedUser: data.viewedUser
        };
      })
    );
  }, [mappedData?.length, mappedData])

  useEffect(() => {
    console.log('debug saved search data here', searchData)

    if (searchData) {
      dispatchSearch({
        type: SavedSearchActionTypes.LOAD_SEARCHES,
        searchData,
        configService,
        setters: {
          setIsDelete,
          setConfigFile,
          setConfigService,
          setIsPost,
          setInternalSearchArray
        }
      })

    }
  }, [searchData]);

  // After we read in the sticky-setting data, read in the alert data
  useEffect(() => {
    setTimeout(() => {
      setLoadConfig(true);
      setIsDelete(false);
      setConfigFile('alert-closed.json');
      setConfigBody({});
      setIsPost(false);
    }, 5000)
  }, [searchData])

  useEffect(() => {
    console.log('debug extend extendStatus', extendStatus)
    setTimeout(() => {
      setExtendStatus(undefined);
    }, 10000)
  }, [extendStatus])

  useEffect(() => {
    console.log('disableHeader transaction summary handleExtendItemSelect tcn:  ', extendTCN)
    if (extendTCN.length < 1) {
      //setDisableHeader(true) do this some other way 
    } else if (extendTCN.length > 0) {
      //setDisableHeader(false)
      const newSelected = mappedData.map((row: any) => extendTCN === row.transactionNumber);
      console.log('transaction  summary newSelected: ', newSelected)
      //setIsSelected(newSelected.indexOf)
      setSelected(newSelected);
    }
  }, [extendTCN])

  useEffect(() => {
    console.log(`debug alert displaySavedMessage ${displaySavedMessage}`)
    if (!displaySavedMessage && postedConfig) {
      setConfigService({ ...configService, postedConfig: false })
    }
  }, [displaySavedMessage])

  useEffect(() => {
    if (permissions.viewAllTransactionsNoLimit) {
      setAdvancedSearch(true)
    }
  }, [permissions.viewAllTransactionsNoLimit])

  const handleClickShowSSN = useCallback(async (record: string, e: any) => {
    if (displaySSN !== record) {
      setDisplaySSN(record)
      setShowSSN(!showSSN)

    } else {
      setDisplaySSN('')
      setShowSSN(!showSSN)
    }
  }, [displaySSN])

  const handleCloseFirstTimeAlert = async () => {
    setClearRecordLimitAlert(true)
    setCloseAlert(true);
    setIsDelete(true);
    setConfigFile('alert-closed.json');
    setConfigBody({ closedAlert: 'true' });
    setIsPost(true);
  }

  const closeExtend = () => {
    setExtendModal(false)
  }

  const handleExtendViewing = useCallback(async () => {
    console.log("debug extendView handleExtendViewing... id: ", recordId);
    //setclickLoading(true);
    if (recordId) {
      const res: any = await extendViewing(recordId, config);
      console.log("debug extendView handleExtend res: ", res);
      if (res.status === 200) {
        addAuditEvent("Extended Record", "Transaction Summary", new Date(), recordId);
        setAlertTitle('Success')
        setAlertMessage(res.msg);
        setSeverity(AlertColor.SUCCESS)
        setOpenNotification(true)
        setTimeout(() => {
          setOpenNotification(false)
          setExtendMesage('')
          setExtendStatus(undefined)
          setSeverity('')
        }, 10000)
      } else if (res.status !== 200) {
        setAlertTitle('Error')
        setSeverity('error');
        setAlertMessage(res.msg);
        setOpenNotification(true)
        setSeverity(AlertColor.ERROR)
        setTimeout(() => {
          setOpenNotification(false)
          setExtendMesage('')
          setExtendStatus(undefined)
          setSeverity('')
        }, 10000)
      }
    } else {
      return
    }

    setExtendModal(false)

  }, [recordId]);

  const handleExtendItemSelect = (params: any) => {
    console.log('debug extend transaction summary handleExtendItemSelect params:  ', params)

    const { e, id, tcn } = params
    console.log('debug extend transaction summary handleExtendItemSelect tcn:  ', tcn)
    setRecordId(id)
    e.stopPropagation()
    const tcnList = tcns

    if (tcn === extendTCN) {
      console.log(`debug extend transaction summary handleExtendItemSelect  tcn ${tcn} ===  ${extendTCN}  `)
      setExtendTCN("")
    } else {
      console.log(`debug extend transaction summary handleExtendItemSelect  tcn ${tcn} !=  ${extendTCN}  `)
      setExtendTCN(tcn)
    }
    //setTcns(tcnList)
    console.log('debug extend transaction summary handleExtendItemSelect id:  ', id)
  }

  const handleRunSearch = () => {
    setPagination({ ...pagination, page: 1 })
    setDisplaySavedMessage(false)
    console.log(`createSearch handleRunSearch formState ${JSON.stringify(formState, null, 2)}`)
    const searchParams = Object.keys(formState).map((key: string) => {
      return { column: key, value: formState[key] }
    })
    console.log(`createSearch handleRunSearch searchParams ${JSON.stringify(searchParams, null, 2)}`)
    const searchParamsFiltered = searchParams.filter((obj) => obj.value !== "" && obj.value !== "30")
    console.log('createSearch searchLength: ', searchParamsFiltered.length)
    setSearchLength(searchParamsFiltered.length)
    setSearchParams(searchParams)
    setCreateSearch(false)
    setSearched(true)
    setValue([])
  }

  useEffect(() => {
    if (displayNoSearchResults) {
      setTimeout(() => {
        setOpenNotification(false)
      }, 10000)
    }
  }, [displayNoSearchResults])

  useEffect(() => {
    const debugEmptyRows: LogConfig = {
      identifier: "debugEmptyRows",
      pageName: "transaction-summary",
      thingToDebug: "Transaction Summary useEffect to set displayNoSearchResults",
      paramsToLog: {
        simpleSearchRan,
        mappedDataLength: mappedData.length,
        setDisplayNoSearchResults: mappedData.length === 0 && simpleSearchRan
      }
    }
    logger(debugEmptyRows)
    if (mappedData.length === 0 && simpleSearchRan) {
      setDisplayNoSearchResults(true)
      setOpenNotification(true)
    }
    console.log('debug open notification display no seasrch', displayNoSearchResults)
    console.log('debug open notification', openNotification)

  }, [mappedData.length, simpleSearchRan])

  const handleRequestSort = (event: any, property) => {
    if (property === "reasonFingerprinted") {
      property = "rfp"
    } else if (property === "transactionType") {
      property = "tot"
    } else if (property === "transactionNumber") {
      property = "tcn"
    }
    console.log(`debug sort date handleReuqestSort sortOrder: ${sortOrder} orderBy ${orderBy} property: ${property}`)
    const isAsc = orderBy === property && sortOrder === 'asc';
    console.log(`sort date handleRequestSort sortOrder: isAsc ${isAsc}`)
    setSortOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property);
  };

  const setAdvancedSearch = useTableStore(state => state.setAdvancedSearch)
  useEffect(() => {
    if (permissions.viewAllTransactionsNoLimit) {
      setAdvancedSearch(true)
    }
  }, [permissions.viewAllTransactionsNoLimit])

  const { upsertViewedRecord } = useQueryRapBack(rowsPerPage, page, sortOrder, orderBy, searchParams, pageName)

  const doesNotTriggerLastViewed = ["mc_admin", "mc_state_police_admin", "mc_state_police_user"]
  const openApplicantDetails = useCallback(async (e, row: any) => {
    const { username } = await Auth.currentAuthenticatedUser()
    let key = row.key
    window.open(`/app/applicant-details/${key}`, '_blank')
    // How audit events are saved - (Action, Details (location of the event), Date(), key = id))
    addAuditEvent("Viewed", "Details / Adjudication Document", new Date(), key)
    if (userRole && !doesNotTriggerLastViewed.includes(userRole!.toString())) {
      upsertViewedRecord(key, username);
      setData((prevData: any) => {
        return prevData.map((data: any) => {
          if (data.key === key) {
            return {
              ...data,
              viewed: true,
              viewedUser: username,
              viewedDate: new Date().toISOString()
            }
          }
          return data
        })
      })
    }
  }, [mappedData, userRole])

  const getCount = (obj: object) => {
    return Object.values(obj).filter(value => value === true).length
  }

  const [storedValue, setRBSubAlertShown] = useLocalStorage("rbSubAlertShown")

  useEffect(() => {
    if (getCount(outstandingRapback) > 0 && needsAttention && !storedValue) {
      setAlertTitle("Attention Required")
      setAlertMessage("Rapback subscriptions require your attention.")
      setOpenNotification(true)
      setSeverity(AlertColor.WARNING)
      setRBSubAlertShown(true)
    } else {
      setAlertTitle("")
      setAlertMessage("")
      setOpenNotification(false)
      setSeverity("")
    }
  }, [needsAttention])

  const handleOpenRejectDrawer = (e, row) => {
    console.log('reject drawer clicked row: ', row)
    setRejectName(row.firstName + " " + row.lastName)
    let id = row.key
    e.stopPropagation()
    setRegistrationId(id)
    setOpenDrawer(true)

    console.log('Reject Drawer handleOpenRejectDrawer id ', id)
  }

  setHandleOpenRejectDrawer(handleOpenRejectDrawer)

  const handleCancel = () => {
    setOpenDrawer(false)
  }

  const setApplicantName = useTableStore(state => state.setApplicantName);
  const setTransactionDate = useTableStore(state => state.setTransactionDate);

  const [extendRow, setExtendRow] = useState<any>({})

  const actionsConfig: { actionLabel: string, actionHandler: Function }[] = [
    ...STATE != "me" ? [{
      actionLabel: "Extend",
      actionHandler: (e, row) => {
        e.stopPropagation()
        handleExtendItemSelect({ e, id: row.key, tcn: row.tcn })
        setExtendModal(true)
        setApplicantName(`${row.firstName} ${row.middleName || ""} ${row.lastName}`)
        setTransactionDate(`${row.transactionDate}`)
        setExtendRow(row)

      }
    }] : [],
    {
      actionLabel: "View Applicant Details",
      actionHandler: openApplicantDetails
    }
  ]

  setActionsConfig(actionsConfig)

  return (
    <>
      <Paper elevation={0} sx={
        {
          ...pageStyles.pagePaperStyle,
          maxWidth: `calc(100vw - ${expanded ? "256px" : "64px"})`
        }}>
        <CustomTableTitleAndAlert
          name="Transaction Summary"
        />
        {(startDate && currentDate < startDate) ?
          <Alert severity='warning' sx={AlertStyles.warning}>
            <AlertTitle color='warning.contrastText'>
              Maintenance Scheduled.
            </AlertTitle>
            <Typography color='warning.contrastText' variant='body2'>
              {`Please be aware that ${appName} will be offline starting at ${startDate} for scheduled maintenance and will be back online after ${endDate}.`}
            </Typography>
          </Alert> : null
        }
        {loadConfig ?
          !alertData?.closedAlert && !closeAlert && !clearRecordLimitAlert &&
          <Alert severity='info'
            sx={AlertStyles.info}
            action={
              <Tooltip title="Close">
                <IconButton aria-label="close" size="small" onClick={handleCloseFirstTimeAlert}>
                  <CloseIcon sx={{ color: 'info.contrastText' }} />
                </IconButton>
              </Tooltip>
            }
          >
            <AlertTitle sx={{ color: 'info.contrastText' }}>
              30 Days Record Display Limit
            </AlertTitle>
            <Typography color='info.contrastText' variant='body2'>
              Transaction Summary table only displays the last 30 days of applicants.
              {permissions.viewAllTransactionsNoLimit && ' To adjust the 30 day window, use "Advanced Search" to customize your date range of applicants.'}
            </Typography>
          </Alert>
          : null
        }
        <RightDrawer openDrawer={openDrawer} closeDrawer={handleCancel}>
          <Typography sx={{ marginBottom: "10px" }} variant="h5">{rejectName} Reject Information</Typography>
          <RejectCodeTable loadingRejectCodes={loadingRejectCodes} totalRejectCodes={totalRejectCodes} rejectData={rejectTableData} id={registrationId} name="reject-codes" />
        </RightDrawer>

        <CustomTable
          //Required props
          tableName="transaction-summary"
          loading={loading}
          rows={data}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          order={sortOrder}
          totalRecords={totalRecords}
          currentPage={page}
          pageSize={rowsPerPage}
          searched={searched}
          value={value}
          handleRequestSort={handleRequestSort}
          setValue={setValue}
          setSearched={setSearched}
          //Per page props
          headerButtonAriaLabel="extend-record(s)"
          headerButtonText="Extend"
          rowClickHandler={(e, row) => openApplicantDetails(e, row)}
          isSelectedRow={selected}
          tcns={tcns}
          extendTCN={extendTCN}
          setSelected={setSelected}
          //Advanced search props
          advancedSearchNumParams={searchLength}
          setSearchLength={setSearchLength}
          setCreateSearch={setCreateSearch}
          setDisplaySavedMessage={setDisplaySavedMessage}
          savedSearchState={[savedSearches, dispatchSearch]}
          handleClickShowSSN={handleClickShowSSN}
          displaySSN={displaySSN}
          dispatchFormValues={dispatchFormValues}
        />

        {
          extendModal && (
            <ExtendModal
              open={extendModal}
              handleExtend={handleExtendViewing}
              handleClose={closeExtend}
              data={mappedData}
              config={config}
              row={extendRow}
            />
          )
        }

      </Paper>
      {
        createSearch && (
          <AdvancedSearch
            filters={filters}
            transactionViewing={transactionViewing}
            usersPermissions={permissions}
            setCreateSearch={setCreateSearch}
            handleRunSearch={handleRunSearch}
            //updateForm={updateForm}
            formValues={formValues}
            formState={[formState, dispatchFormValues]}
            setFormValues={setFormValues}
            savedSearchState={[savedSearches, dispatchSearch]}
            postedConfig={postedConfig}
            //setPostedConfig={setPostedConfig}
            displaySavedMessage={displaySavedMessage}
            setDisplaySavedMessage={setDisplaySavedMessage}
          />
        )
      }
    </>
  )
} 