/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import ReactDOM from "react-dom/client";
import './index.scss';
import { router } from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider_MUI } from "theme/ThemeProvider";
import { IdleTracker } from "./components/Idle-Tracker/idle-tracker";
import { UserProvider } from "./contexts"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
if (process.env.NODE_ENV !== "development") console.log = () => {};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <CookiesProvider>
    <ThemeProvider_MUI>
      <UserProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* 15 minutes and 5 minutes time out  */}
        <IdleTracker idleTimeout={5 * 180_000} promptTimeout={5 * 60_000} />
        <RouterProvider router={router} />
      </LocalizationProvider>
      </UserProvider>
    </ThemeProvider_MUI>
  </CookiesProvider>
);

reportWebVitals();
