export const errorTypes = {
    'Password attempts exceeded': {
            title: 'Too many sign-in attempts.',
            description: 'Your account has been locked. Please try again in a couple of minutes.',
        },
        'Incorrect username or password.': {
            title: 'Invalid Credentials',
            description: "Please try again or select 'Forgot password.'",
        },
        'UserLambdaValidationException': {
            title: 'Unable To Validate Username',
            description: 'Please verify your information and try again.',
        },
        'CodeMismatchException': {
            title: 'Invalid Verification Code.',
            description: 'Please Try again. You have 3 minutes to complete the authentication.',
        },
        'EnableSoftwareTokenMFAException': {
            title: 'Invalid Verification Code.',
            description: 'Please Try again. You have 3 minutes to complete the authentication.',
        },
        'Invalid session for the user, session is expired.': {
            title: 'Invalid session for the user, session is expired.',
            description: 'Please click cancel to restart.',
        },
        'Password has been used within the last year': {
            title: 'Password has been used within the last year',
            description: 'Password has been used within the last year, please try an alternate password.',
        },
        'Attempt limit exceeded': {
            title: 'Attempt limit exceeded',
            description: 'Attempt limit exceeded, please try after some time.',
        },
        "Invalid verification code provided, please try again.": {
            title: "Invalid verification code provided, please try again.",
            description: '',
        },
        "LimitExceededException": {
            title: "Attempt limit exceeded, please try after some time.",
            description: '',
        },
}