enum NistEnvironmentKeys {
    firstName = "firstName",
    middleName = "middleName",
    lastName = "lastName",
    suffix = "suffix",
    fullName = "fullName",
    fullAddress = "fullAddress"
}

export enum PageNames {
    TRANSACTION_SUMMARY = "transaction-summary",
    RAPBACK_SUMMARY = "rapback-summary",
    AGENCY_MANAGEMENT = "agency-management",
    BATCH_PRINTING = "batch-printing",
    USER_MANAGEMENT = "user-management",
    APPLICANT_DETAILS = "applicant-details",
    RAPBACK_MAINTENANCE = "rapback-maintenance",
    RAPBACK_PRENOTIFICATIONS = "rapback-prenotifications",
    RAPBACK_VALIDATIONS = "rapback-validations"
}

export interface UserRole {
    value: Role
    label: RoleLabel
}

export type UserRoles = UserRole[]
export type RoleLabel = Role.mc_admin | Role.mc_edit | Role.mc_readonly | Role.mc_readonly_restricted_view | Role.mc_agency_admin | Role.mc_state_police_user | Role.mc_state_police_admin

export enum Role {
    mc_admin = "Admin Super User",
    mc_edit = "Edit",
    mc_readonly = "Read-only",
    mc_readonly_restricted_view = "Read-Only Restricted",
    mc_agency_admin = "Agency Admin",
    mc_state_police_user = "State Police User",
    mc_state_police_admin = "State Police Admin"
}

export enum Group {
    "Admin Super User" = "mc_admin",
    "Edit" = "mc_edit",
    "Read-only" = "mc_readonly",
    "Read-Only Restricted" = "mc_readonly_restricted_view",
    "Agency Admin" = "mc_agency_admin",
    "State Police User" = "mc_state_police_user",
    "State Police Admin" = "mc_state_police_admin"
}

enum DeploymentStates {
    me = "me",
    nm = "nm",
    ga = "ga"
}

export type NistParsing = {
    [key in NistEnvironmentKeys]: { key: string, value?: string }

}

export type LabelValue = { label: string, value: string }

export type TableHeaderCell = { dataIndex: string, label: string, searchable?: boolean }
export type TableHeaderCells = Array<TableHeaderCell>

export type HeaderCell = {
    manageColumns?: boolean
    renderCustomSortIcon?: boolean,
    menuItems?: LabelValue[] | string[]
    tableName?: string,
    useQueryPagination?: boolean,
    tableHeaderCells: TableHeaderCells
}

export type HeaderCells = Array<HeaderCell>

interface FilterStatus<T> {
    filters: {
        [key: string]: {
            selected: boolean,
            label: string,
            boolMap?: boolean
        }
    }

}

type FilterType<T> = FilterStatus<T> & {
    filterIsBool?: boolean
    filterLabel: string
    filterColumn: string
}


export type Pages = {
    [key in PageNames]: {
        useQueryPagination: boolean,
        headerCells: HeaderCells,
        advancedSearch: boolean,
        columnVisibility: any,
        filterStatus: FilterType<string>
        tableTools: { filter: boolean, manageColumns: boolean }
    }
}

type Cognito = {
    userRoles: UserRoles[]
}

interface State {
    cognito?: Cognito
    nistParsing: NistParsing
    pages: Pages
    transactionViewing: {
        hardLimit: number
        softLiimt: number
        extendDays: number
    }
}

type States = {
    [key in DeploymentStates]: State[]
}

enum date {
    startDate = "startDate",
    endDate = "endDate"
}


type Schedule = {
    [key in date]: string
}

export interface Config {
    states?: States
    scheduledMaintenance?: Schedule
}
