import { useState, useEffect  } from 'react'
import { useUserRole } from './useUserRole';
import { useUserCognito } from './useUserCognito';
import { urls } from '../urls'
import { generateBatchPrintQueryParams } from 'utils/queryTransactionsHelpers';
import { QueryRequestBody } from 'interfaces/common/QueryRequestBody';
import { transformBatchPrintData } from 'utils/queryTransactionsHelpers';


export const useBatchPrint = (pageSize: number,
    currentPage: number,
    order: string,
    orderBy: string,
    tableFiltersData: any,
    searchParams: any
    ) => {

    const [mapped, setMappedData] = useState<any>([])
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false)
    const [countError, setError] = useState();
    const { userORIs } = useUserCognito();
    const { userRole } = useUserRole()

    const url = urls.QUERY

    useEffect(() => {
        console.log('batch print flow running useBatchPrint')
        const controller = new AbortController()
       
        const param = searchParams.find((param) => param.column === "transactionDate" || param.column === "lowerDate" || param.column === "upperDate" )

        if(userORIs.length > 0) {

            console.log('query batch print useEffect')
            
            const queryParams = generateBatchPrintQueryParams(orderBy, param, searchParams)

            let queryDataRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [
                           ...queryParams,
                            {
                                "value": order,
                                "name": "order"
                            }
                        ],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "query_ME_batchprint_unprinted" 
                    }
                ]
            }

            let queryCountRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "query_ME_batchprint_unprinted_count"
                    }
                ]
            }

            const fetchUnprinted = async (queryDataRequest: any, queryCountRequest: any) => {
                console.log('query batch print fetchUnprinted running')

                try {
                    const batchPrintUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;

                    setLoading(true)
    
                    const data = await (await fetch(batchPrintUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(queryDataRequest)})).json();

                    const countData = await (await fetch(batchPrintUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(queryCountRequest)})).json();
 
                    const {results} = data['queriesResults'][0];
                    const count = countData['queriesResults'][0].results[0]['count'];
                    const transformedData = results.map((obj: any, index: number) => transformBatchPrintData(obj, index));
                    console.log('batch print flow setMappedData: ', transformedData)
                    setMappedData(transformedData);
                    setTotalRecords(count);
                    setLoading(false);
    
                } catch (err: any) {
                    setError(err);
                    setLoading(false);
                }
            }

            setLoading(true);
            fetchUnprinted(queryDataRequest, queryCountRequest)
        }

        return () => {
            controller.abort();
        }
    }, [url, order, orderBy, currentPage, pageSize, userRole, userORIs, tableFiltersData, searchParams])

    return { data: mapped, totalRecords, countError, loading }
}