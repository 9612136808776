import {DatePicker} from "@mui/x-date-pickers";
import {Container, Typography} from "@mui/material";
import * as React from "react";
import {EditSubscriptionAction} from "./RapbackMaintenance";
import dayjs from 'dayjs';

const EditSubTextRow: React.FC<any> = ({ children }) => {
    return (
        <Container disableGutters sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
            {children}
        </Container>
    )
}

const EditSubTextDisplay: React.FC<{ title: string, value: string }> = ({ title, value }) => {
    return (
        <Container disableGutters sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>{title.toUpperCase()}</Typography>
            <Typography>{value}</Typography>
        </Container>
    )
}

export const EditSubscriptionForm: React.FC<any> = ({formState}) => {

    const { dob, expirationDate, fullName, rapbackId, ori, confirmation, status, startDate } = formState[0]
    const dispatchFormValues = formState[1]


    return (
        <Container disableGutters sx={{ marginBottom: "70%", height: "45%", gap: '2rem', display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
            <EditSubTextRow>
                <EditSubTextDisplay title="FULL NAME" value={fullName} />
                <DatePicker
                    label="Date of Birth"
                    name="dob"
                    value={dayjs(dob)}
                    slotProps={{
                        textField: {
                            placeholder: "MM / DD / YYYY",
                        },
                    }}
                    sx={{ minWidth: "50%" }}
                    //value={formValues.lowerDate}
                    onChange={(newValue) => dispatchFormValues({ type: EditSubscriptionAction.DOB, value: newValue })}
                />
            </EditSubTextRow>
            <EditSubTextRow>
                <EditSubTextDisplay title="Rapback ID" value={rapbackId} />
                <EditSubTextDisplay title="PRIMARY ORI" value={ori} />
            </EditSubTextRow>
            <EditSubTextRow>
                <EditSubTextDisplay title="SUBSCRIPTION START DATE" value={dayjs(startDate).format("MM/DD/YYYY")} />
                <DatePicker
                    label="Expiration Date"
                    name="expirationDate"
                    maxDate={dayjs(expirationDate)}
                    value={dayjs(expirationDate)}
                    slotProps={{
                        textField: {
                            placeholder: "MM / DD / YYYY",
                        },
                    }}
                    sx={{ minWidth: "50%" }}
                    //value={formValues.lowerDate}
                    onChange={(newValue) => dispatchFormValues({ type: EditSubscriptionAction.EXPIRATION_DATE, value: newValue })}
                />
            </EditSubTextRow>
            <EditSubTextRow>
                <EditSubTextDisplay title="Confirmation" value={confirmation} />
                <EditSubTextDisplay title="Status" value={status} />
            </EditSubTextRow>
        </Container>
    )
}