/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { role } from "constants/roles";
import ApiService from "utils/apiService";
import { formatPermissionsAfterFetch } from "components/UserPermissions/permissionsConfig";
import { urls } from "urls";
import { useLocalStorage } from "common";

export const useUserCognito = () => {
  const [userRole, setUserRole] = useState<string | string[]>();
  const [userORIs, setUserORIs] = useState<any[]>([]);
  const [userSecondaryORIs, setuserSecondaryORIs] = useState<any[]>([]);
  const [usersPermissions, setUsersPermissions] = useState<any>({});
  const [userGivenName, setUserGivenName] =  useState("")
  const [userFamilyName, setUserFamilyName] = useState("")
  const [groups, setGroups] = useState([]);
  const [userEmail, setUserEmail] = useState<string>()
  const [, , loggedIn] = useLocalStorage<boolean>()
  const groupUrl = urls.GROUP;
  const roleUrl = urls.ROLE;
  const groupRoleUrl = urls.GROUPROLE;
  const groupRoles: any = [];

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const groupsService = new ApiService(
      `${process.env.NODE_ENV === "production" ? groupUrl : ""}/group`
    );
    const rolesService = new ApiService(
      `${process.env.NODE_ENV === "production" ? roleUrl : ""}/role`
    );
    const groupRoleService = new ApiService(
      `${process.env.NODE_ENV === "production" ? groupRoleUrl : ""}/groupRole`
    );

    const getUserInfoFromCognito = async () => {
      const user = await Auth.currentAuthenticatedUser();

      const idTokenPayload = user.signInUserSession.idToken.payload;
      if(idTokenPayload.given_name) {
        setUserGivenName(idTokenPayload.given_name)
      }
      if(idTokenPayload.family_name) {
        setUserFamilyName(idTokenPayload.family_name)
      }
      if(idTokenPayload.email) {
        setUserEmail(idTokenPayload.email)
      }

      const userGroups = idTokenPayload["cognito:groups"];

      const userOris = idTokenPayload["custom:ori-long"] || idTokenPayload["custom:ori"];
      const userSoris = idTokenPayload["custom:secondary-ori"]

      if (userOris.length) {
        setUserORIs(JSON.parse(userOris));
      }

      let userSecondaryORIs:any = []; 
      if(idTokenPayload["custom:secondary-ori"] !== undefined){
        userSecondaryORIs = idTokenPayload["custom:secondary-ori"]
      }

      if (userSecondaryORIs.length) {
        setuserSecondaryORIs(JSON.parse(userSecondaryORIs));
      }


      try {
        const { data, status } = await groupsService.get();
       
        const allGroups = data
        setGroups(allGroups);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        const resp = await groupRoleService.get(userGroups.join())
        groupRoles.push(resp.data)
        const formattedPermissions = await formatPermissionsAfterFetch(
          groupRoles
        );

        setUsersPermissions(formattedPermissions);

        if (userGroups.length) {
          const userRole = userGroups[0];

          if (userRole) setUserRole(userRole);
        } else {
          console.log(`httpOnly ${user.userusername} doesn't belong to any groups`);
        }
      } catch (err: any) {
        return { errorMessage: err.message };
      }
    };

    if (loggedIn) {
      getUserInfoFromCognito();
    }
    
    return () => controller.abort();
  }, [loggedIn]);

  return { userORIs, userSecondaryORIs, userRole, usersPermissions, groups, userGivenName, userFamilyName, userEmail };
};
