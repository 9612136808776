import { create } from "zustand"
import { Order } from "customTypes/OrderType"
import { SearchParams } from "@customTypes/SearchParam"
import { PageNames } from "interfaces/config"
import { columnVisibilityReducer } from "./reducers/columnVisibilityReducer";
import { ReactNode } from "react"

interface SavedSearchConfigArgs {
    configFile: string,
    configBody: any,
    isGet: boolean,
    isPost: boolean,
    isDelete: boolean
}

interface FilterStatus<T> {
    filters: {
        [key: string]: {
            selected: boolean,
            label: string
        }
    }

}

type FilterType<T> = FilterStatus<T> & {
    filterLabel: string
    filterColumn?: string
}

type FilterType2 = {
    type?: string;
    filterLabel?: string;
    filterColumn: string;
    filterIsBool?: boolean;
    filterValues?: any
}

type Filters = FilterType2[]


export interface ColumnState {
    columnStateDispatch: Function
    page: {
        [key in PageNames]?: {
            columnVisibility: {
                [key: string]: boolean
            },
            filterStatus?: FilterType<string>,
            filters?: Filters
        }
    }
}

interface Pagination {
    page: number,
    rowsPerPage: number,
    sortOrder: Order,
    orderBy: string,
}

export interface ConfigServiceState {
    searchData: any,
    alertData: any,
    loadingConfig: boolean,
    postedConfig: boolean,
    errorConfig: boolean
}

export enum Components {
    CUSTOM_FILTER = "CustomFilter"
}

export type ComponentConfig = {
    [key in Components]: {
        handleRadioChange: Function,
        selectedValue: string,
    }
}

export interface HeaderButtonConfig {
    handler: Function
    headerButtonIcon: React.ReactNode | null
    buttonLabel: string
    disabled: boolean
    text: string
}

export enum ApplicantDetailsTable {
    STATUS_HISTORY = "status-history",
    ADJUDICATION_DOCS = "adjudication-docs",
    OUTGOING_TRANSMISSION = "outgoing-transmission",
    INCOMING_RESPONSE = "incoming-response",
    AUDIT_HISTORY = "audit-history",
}

export enum RapbackMaintenance {
    RB_MAINTENANCE = "rapback-maintenance",
    RB_AUDIT_HISTORY = "rb-sub-audit-history"
}

export enum RapbackSummary {
    RAPBACK_SUMMARY = "rb-summary-audit-history"
}

export enum TransactionSummary {
    TRANSACTION_SUMMARY = "transaction-summary",
    REJECT_CODES = "reject-codes"
}

interface TableState {
    handleCustomRefetch: Function
    setHandleCustomRefetch: (handleCustomRefetch: Function) => void
    currentDataIndex: string
    customFilterAnchorEl: null | HTMLElement
    currentCell: string
    componentConfig: ComponentConfig
    extendModal: boolean
    configService: any
    detailsTable: string[]
    pagination: Pagination
    columnState: ColumnState
    formState: any
    headerButtonConfig: HeaderButtonConfig
    searchParams: SearchParams
    loadConfig: boolean
    closeAlert: boolean
    savedSearchConfigArgs: SavedSearchConfigArgs
    selected: Array<string>
    tcns: Array<any>
    value: Array<any>
    datePlaceholder: boolean
    reducer: Function
    formReducer: Function
    setReducer: any
    setFormReducer: any
    advancedSearch: boolean //Enables or disables advanced search
    resetTable: Function
    openNotification: boolean
    currentRow
    alertTitle: string
    alertMessage: string
    page: number
    rowsPerPage: number
    displayNoSearchResults: boolean
    open: boolean
    pageName: string,
    setPageName: (pageName: string) => void,
    setOpenPrint: (open: boolean) => void
    setTcns: (tcns: Array<any>) => void
    setSelected: (selected: Array<string>) => void
    setPage: (page: number) => void
    setRowsPerPage: (rowsPerPage: number) => void
    setCurrentDataIndex: (currentDataIndex: string) => void
    setCustomFilterAnchorEl: (customFilterAnchorEl: null | HTMLElement) => void
    setCurrentCell: (currentCell: string | undefined) => void
    setComponent: (component: any) => void
    setExtendModal: (extendModal: boolean) => void
    setConfigService: (configService: any) => void
    setPagination: (pagination: Pagination) => void
    setResetTable: (resetTable: Function) => void
    setHeaderButtonConfig: (headerButtonConfig: any) => void
    dispatchFormValues: React.Dispatch<any> | null
    setDispatchFormValues: (dispatchFormValues: React.Dispatch<any>) => void
    setSearchParams: (searchParams: SearchParams) => void
    setLoadConfig: (loadConfig: boolean) => void
    setCloseAlert: (closeAlert: boolean) => void
    setSavedSearchConfigArgs: (savedSearchConfigArgs: SavedSearchConfigArgs) => void
    setColumnState: (columnState: ColumnState) => void
    setFormState: (formState: any) => void
    setAdvancedSearch: (advancedSearch: boolean) => void
    setCurrentRow: (currentRow) => void
    setOpenNotification: (openNotification: boolean) => void
    setAlertTitle: (alertTitle: string | undefined) => void
    setAlertMessage: (alertMessage: string | undefined) => void
    setDisplayNoSearchResults: (displayNoSearchResults: boolean) => void
    formValuesDispatch: any
    configLoaded: string[]
    setConfigLoaded: (configLoaded: string[]) => void
    isDelete: boolean
    setIsDelete: (isDelete: boolean) => void
    configFile: string
    setConfigFile: (configFile: string) => void
    configBody: any
    setConfigBody: (configBody: any) => void
    isPost: boolean
    setIsPost: (isPost: boolean) => void
    simpleSearchRan: boolean
    setSimpleSearchRan: (simpleSearchRan: boolean) => void
    recordId: any
    setRecordId: (recordId: any) => void
    extendTCN: string
    setExtendTCN: (extendTCN: string) => void
    severity: any
    setSeverity: (severity: string) => void
    multiTablePagination: any
    setMultiTablePagination: (multiTablePagination: any) => void
    createUserStatus: any
    setCreateUserStatus: (createUserStatus: any) => void
    openAuditHistory: any
    setOpenAuditHistory: (openAuditHistory: any) => void
    actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler: Function }[]
    setActionsConfig: (actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler: Function }[]) => void
    outstandingRapback: any
    setOutStandingRapback: (outstandingRapback: any) => void
    needsAttention: boolean,
    setNeedsAttention: (needsAttention: any) => void
    applicantName: string
    setApplicantName: (applicantName: string) => void
    transactionDate: any
    setTransactionDate: (transactionDate: any) => void
    receivedResponse: boolean,
    setReceivedResponse: (receivedResponse: any) => void
    handleOpenRejectDrawer: Function
    setHandleOpenRejectDrawer: (handleOpenRejectDrawer: any) => void
    loadingConfig: boolean
    setLoadingConfig: (loadingConfig: any) => void
    internalSearchArray: any
    setInternalSearchArray: (internalSearchArray: any) => void
    rowClicked: boolean
    setRowClicked: (rowClicked: any) => void
    userActive: boolean,
    setUserActive: (userActive: boolean) => void

}

export const useTableStore = create<TableState>((set, get) => ({
    userActive: false,
    setUserActive: (userActive: boolean) => set({userActive}),
    rowClicked: false,
    setRowClicked: (rowClicked: boolean) => set({ rowClicked }),
    
    internalSearchArray: [],
    setInternalSearchArray: (internalSearchArray: any) => set({ internalSearchArray }),
    handleOpenRejectDrawer: () => { },
    setHandleOpenRejectDrawer: (handleOpenRejectDrawer: any) => set({ handleOpenRejectDrawer }),
    loadingConfig: false,
    setLoadingConfig: (loadingConfig: any) => set({ loadingConfig }),
    openAuditHistory: () => { },
    setOpenAuditHistory: (openAuditHistory: any) => set({ openAuditHistory }),
    outstandingRapback: {
        preNotification: false,
        validations: false
    },
    setOutStandingRapback: (outstandingRapback: any) => set({ outstandingRapback }),
    needsAttention: false,
    setNeedsAttention: (needsAttention: any) => set({ needsAttention }),
    pageName: "",
    setPageName: (pageName: string) => set({ pageName }),
    actionsConfig: [{ actionIcon: <div />, actionLabel: "", actionHandler: () => { } }],
    setActionsConfig: (actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler: Function }[]) => set({ actionsConfig }),
    multiTablePagination: {
        [ApplicantDetailsTable.STATUS_HISTORY]: {
            detailPage: 0,
            detailRowsPerPage: 5,
        },
        [ApplicantDetailsTable.ADJUDICATION_DOCS]: {
            detailPage: 0,
            detailRowsPerPage: 5,
        },
        [ApplicantDetailsTable.OUTGOING_TRANSMISSION]: {
            detailPage: 0,
            detailRowsPerPage: 5,
        },
        [ApplicantDetailsTable.INCOMING_RESPONSE]: {
            detailPage: 0,
            detailRowsPerPage: 5,
        },
        [ApplicantDetailsTable.AUDIT_HISTORY]: {
            detailPage: 0,
            detailRowsPerPage: 5,
        },
        [RapbackMaintenance.RB_AUDIT_HISTORY]: {
            detailPage: 0,
            detailRowsPerPage: 10,
        },
        [RapbackSummary.RAPBACK_SUMMARY]: {
            detailPage: 0,
            detailRowsPerPage: 10
        },
        [TransactionSummary.REJECT_CODES]: {
            detailPage: 0,
            detailRowsPerPage: 10,
        },
    },
    setMultiTablePagination: (multiTablePagination: any) => set({ multiTablePagination }),
    handleCustomRefetch: () => { },
    setHandleCustomRefetch: (handleCustomRefetch: Function) => ({ handleCustomRefetch }),
    configLoaded: [],
    setConfigLoaded: (configLoaded: string[]) => set({ configLoaded }),
    page: 0,
    rowsPerPage: 25,
    currentCell: "",
    currentDataIndex: "",
    customFilterAnchorEl: null,
    currentRow: null,
    openNotification: false,
    alertTitle: "",
    alertMessage: "",
    displayNoSearchResults: false,
    selected: [],
    tcns: [],
    open: false,
    recordId: null,
    setOpenPrint: (open: boolean) => set({ open }),
    setSelected: (selected) => set({ selected }),
    setTcns: (tcns: Array<any>) => set({ tcns }),
    setRecordId: (recordId) => set({ recordId }),
    setDisplayNoSearchResults: (displayNoSearchResults) => set({ displayNoSearchResults }),
    setPage: (page) => set({ page }),
    setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
    setAlertMessage: (alertMessage) => set({ alertMessage }),
    setAlertTitle: (alertTitle) => set({ alertTitle }),
    setCurrentRow: (currentRow) => set({ currentRow }),
    setCustomFilterAnchorEl: (customFilterAnchorEl: null | HTMLElement) => set({ customFilterAnchorEl }),
    setCurrentCell: (currentCell: string | undefined) => set({ currentCell }),
    setCurrentDataIndex: (currentDataIndex: string) => set({ currentDataIndex }),
    setOpenNotification: (openNotification: boolean) => set({ openNotification }),
    componentConfig: {
        [Components.CUSTOM_FILTER]: {
            selectedValue: "",
            handleRadioChange: () => { }
        },
    },
    extendModal: false,
    configService: {
        searchData: {},
        alertData: {},
        rfpListData: {},
        loadingConfig: false,
        postedConfig: false,
        errorConfig: false
    },
    detailsTable: ['incoming-responses', 'outgoing-transmission', 'status-history', 'adjudication-documents'],
    pagination: {
        page: 0,
        rowsPerPage: 25,
        sortOrder: "desc",
        orderBy: ""
    },
    headerButtonConfig: {
        handler: () => { },
        headerButtonIcon: null,
        buttonLabel: "",
        disabled: false,
        text: ""
    },
    resetTable: () => { },
    columnState: {
        columnStateDispatch: args => set(state => columnVisibilityReducer(state, args)),
        page: {
            [PageNames.TRANSACTION_SUMMARY]: {
                columnVisibility: {
                    rejectIndex: true,
                    rejectCode: true,
                    rejectDescription: true,
                    fullName: false,
                    firstName: true,
                    middleName: false,
                    lastName: true,
                    ueid: true,
                    stateRFPCode: true,
                    stateRFPLiteral: true,
                    status: true,
                    hitNoHit: true,
                    transactionDate: true,
                    tcn: true,
                    agency: true,
                    secondaryOri: true,
                    lastViewedDate: true,
                    ssn: true,
                    dob: false,
                    race: false,
                    gender: false,
                    actions: true
                },
                filters: [
                    {
                        filterLabel: "Filter Status",
                        filterColumn: "status",
                        filterIsBool: false,
                        filterValues: {
                            dev: { selected: true, label: "" },
                            errtError: { selected: true, label: "ERRT-Error" },
                            closed: { selected: true, label: "Closed" },
                            waitingForResponses: { selected: true, label: "Waiting for responses" },
                            decisionComplete: { selected: true, label: "Decision Complete" },
                            error: { selected: true, label: "Error" },
                            manualAdjudication: { selected: true, label: "Waiting for Manual Adjudication" }
                        }
                    },
                    {
                        filterLabel: "Last Viewed Date",
                        filterColumn: "viewed",
                        filterIsBool: true,
                        filterValues: {
                            viewed: { selected: true, label: "Viewed", boolMap: true },
                            notViewed: { selected: true, label: "Not Viewed", boolMap: false }
                        }
                    }
                ],

            },
            [PageNames.APPLICANT_DETAILS]: {
                columnVisibility: {
                    status: true,
                    statusDate: true,
                    agency: true,
                    addedDate: true,
                    decision: true,
                    fileNameControl: true,
                    actions: true,
                    date: true,
                    type1Field7: true,
                    type2Field59: true,
                    operator: true,
                    location: true,
                    action: true
                }
            },
            [PageNames.RAPBACK_MAINTENANCE]: {
                columnVisibility: {
                    fullName: true,
                    dateOfBirth: false,
                    rapbackId: true,
                    primaryOri: true,
                    secondaryOri: true,
                    startDate: true,
                    expirationDate: true,
                    unsubscribedDate: true,
                    status: true,
                    actions: true,
                    operator: true,
                    date: true,
                    action: true
                }
            },
            [PageNames.RAPBACK_PRENOTIFICATIONS]: {
                columnVisibility: {
                    fullName: true,
                    dateOfBirth: false,
                    rapbackId: true,
                    primaryOri: true,
                    secondaryOri: true,
                    startDate: true,
                    expirationDate: true,
                    unsubscribedDate: true,
                    status: true,
                    actions: true
                }

            },
            [PageNames.RAPBACK_VALIDATIONS]: {
                columnVisibility: {
                    fullName: true,
                    dateOfBirth: false,
                    rapbackId: true,
                    primaryOri: true,
                    secondaryOri: true,
                    startDate: true,
                    expirationDate: true,
                    unsubscribedDate: true,
                    status: true,
                    actions: true
                },
                filters: [
                    {
                        filterLabel: "Time Remaining",
                        filterColumn: "timeRemaining",
                        filterIsBool: false,
                        filterIsNumber: true,
                        filterValues: {
                            11: {
                                selected: true,
                                label: "11 - 60 Days"
                            },
                            10: {
                                selected: true,
                                label: "2 - 10 Days"
                            },
                            1: {
                                selected: true,
                                label: "1 Day"
                            }
                        }
                    }
                ]
            },
            [PageNames.RAPBACK_SUMMARY]: {
                columnVisibility: {
                    firstName: true,
                    middleName: false,
                    lastName: true,
                    reason: true,
                    ssn: true,
                    insertdate: true,
                    sid: true,
                    isHit: false,
                    oris: true,
                    lastViewedDate: true,
                    actions: true
                },
                filters: [
                    {
                        filterLabel: "Last Viewed",
                        filterColumn: "viewed",
                        filterIsBool: true,
                        filterValues: {
                            viewed: { selected: true, label: "Viewed", boolMap: true },
                            notViewed: { selected: true, label: "Not Viewed", boolMap: false }
                        }
                    }
                ]
            },
            [PageNames.BATCH_PRINTING]: {
                columnVisibility: {
                    checkbox: true,
                    firstName: true,
                    lastName: true,
                    reasonFingerPrinted: true,
                    status: true,
                    transactionDate: true,
                    transactionNumber: true,
                    ori: true
                },
            },
            [PageNames.AGENCY_MANAGEMENT]: {
                columnVisibility: {
                    agencyORI: true,
                    secondaryOri: true,
                    reasonFingerPrinted: true,
                    agencyName: true,
                    distributionMethod: true,
                    agencyEmail: true,
                    agencyAddress: true,
                    agencyCity: true,
                    agencyState: true,
                    agencyZipCode: true
                },

            },
            [PageNames.USER_MANAGEMENT]: {
                columnVisibility: {
                    fullName: true,
                    loginName: true,
                    securityLevel: true,
                    status: true,
                    emailAddress: true,
                    primaryOri: true,
                    secondaryOri: true,
                    reasonFingerprinted: true,
                    actions: true
                },
                filters: []
            }

        }
    },
    formState: {},
    searchParams: [],
    loadConfig: false,
    closeAlert: false,
    savedSearchConfigArgs: {
        configFile: "alert-closed.json",
        configBody: {},
        isGet: true,
        isPost: false,
        isDelete: false
    },
    dispatchFormValues: null,
    datePlaceholder: false,
    value: [],
    advancedSearch: false,
    setExtendModal: (extendModal: boolean) => set({ extendModal }),
    setConfigService: (configService: ConfigServiceState) => set({ configService }),
    setPagination: (pagination: Pagination) => set({ pagination }),
    setResetTable: (resetTable: Function) => set({ resetTable }),
    reducer: () => { },
    formReducer: () => { },
    setReducer: (reducer: (state, action) => {}) => set({ reducer }),
    setFormReducer: (formReducer: (state, action) => {}) => set({ formReducer }),
    formValuesDispatch: args => set(state => state.formReducer(state, args)),
    setColumnState: (columnState: ColumnState) => set({ columnState }),
    setAdvancedSearch: (advancedSearch: boolean) => set({ advancedSearch }),
    setFormState: (formState: any) => set({ formState }),
    setComponent: (componentConfig: any) => set({ componentConfig }),
    setHeaderButtonConfig: (headerButtonConfig: any) => set({ headerButtonConfig }),
    setDispatchFormValues: (dispatchFormValues: React.Dispatch<any>) => set({ dispatchFormValues }),
    setSearchParams: (searchParams: SearchParams) => set({ searchParams }),
    setLoadConfig: (loadConfig: boolean) => set({ loadConfig }),
    setCloseAlert: (closeAlert: boolean) => set({ closeAlert }),
    setSavedSearchConfigArgs: (savedSearchConfigArgs: SavedSearchConfigArgs) => set({ savedSearchConfigArgs }),
    isDelete: false,
    setIsDelete: (isDelete: boolean) => set({ isDelete }),
    configFile: 'alert-closed.json',
    setConfigFile: (configFile: string) => set({ configFile }),
    configBody: {},
    setConfigBody: (configBody: any) => set({ configBody }),
    isPost: false,
    setIsPost: (isPost: boolean) => set({ isPost }),
    simpleSearchRan: false,
    setSimpleSearchRan: (simpleSearchRan: boolean) => set({ simpleSearchRan }),
    extendTCN: "",
    setExtendTCN: (extendTCN: string) => set({ extendTCN }),
    severity: undefined,
    setSeverity: (severity: string) => set({ severity }),
    createUserStatus: undefined,
    setCreateUserStatus: (createUserStatus: any) => set({ createUserStatus }),
    applicantName: "",
    setApplicantName: (applicantName: string) => set({ applicantName }),
    transactionDate: "",
    setTransactionDate: (transactionDate: any) => set({ transactionDate }),
    receivedResponse: false,
    setReceivedResponse: (receivedResponse: any) => set({ receivedResponse }),
}))