export enum UserPermission {
    viewAgencies = "viewAgencies",
    addNewAgencies = "addNewAgencies",
    editExistingAgencies = "editExistingAgencies",
    deleteExistingAgencies = "deleteExistingAgencies",
    viewAllDetailsData = "viewAllDetailsData",
    allowManualAdjudication = "allowManualAdjudication",
    createNewNotes = "createNewNotes",
    editOwnNotes = "editOwnNotes",
    printNotes = "printNotes",
    viewOthersNotes = "viewOthersNotes",
    editOthersNotes = "editOthersNotes",
    viewOriginalResults = "viewOriginalResults",
    printOriginalResults = "printOriginalResults",
    viewRedactedResults = "viewRedactedResults",
    printRedactedResults = "printRedactedResults",
    editRedactedResults = "editRedactedResults",
    createNewRedactedResults = "createNewRedactedResults",
    viewAdministration = "viewAdministration",
    viewTransactionSummary = "viewTransactionSummary",
    sortTransactionSummary = "sortTransactionSummary",
    saveTransactionSummarySearches = "saveTransactionSummarySearches",
    viewRapBack = "viewRapBack",
    viewRapBackDetails = "viewRapBackDetails",
    viewAllTransactionsNoLimit = "viewAllTransactionsNoLimit",
    canResubmitTransaction= "canResubmitTransaction",
    extendTransactionRecord = 'extendTransactionRecord',
    viewBatchPrinting = 'viewBatchPrinting',
    viewAdvancedSearchExtend = 'viewAdvancedSearchExtend',
    viewRapbackSubscriptions = 'viewRapbackSubscriptions',
    viewReporting = 'viewReporting',
    viewUserManagement = 'viewUserManagement',
    viewUserHistory = 'viewUserHistory',
    viewLastViewedDate = 'viewLastViewedDate',
    viewFbiRapback = 'viewFbiRapback',
    viewUserPermissions = 'viewUserPermissions',
    viewUserPreferences = 'viewUserPreferences',
    printRapsheetPDF = 'printRapsheetPDF',
    viewRapsheetPDF = 'viewRapsheetPDF'
  };