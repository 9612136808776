
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect } from "react";
import { urls } from '../urls'

type PdfUrls = readonly string[];

export const usePdfURL = (registrationId: string): { pdfURLs: PdfUrls } => {
    const [pdfURLs, setPdfURLs] = React.useState<PdfUrls>([]);
    const url = urls.REPORTING
    console.log('usePdf url: ', url)

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let fetchUrl = `${process.env.NODE_ENV === 'production' ? url : ""}/adjudication/pdf/${registrationId}`
        console.log(`debug url usePdf fetchUrl: ${fetchUrl} `)
        const fetchUrls = async () => {
            try {
                const { urls } = await (await fetch(fetchUrl, {
                    method: "GET",
                    credentials: "include",
                    signal
                })).json();

                setPdfURLs(urls);

            } catch (err: any) {
                console.log('ERROR => ', err);
            }
        }

        fetchUrls();

        //Refresh pre-signed URLs every minute.
        const refreshUrlInterval = setInterval(() => {
            fetchUrls();
        }, 60000);



        return () => {
            clearInterval(refreshUrlInterval);
            controller.abort();
        }
    }, [registrationId, url])

    return { pdfURLs };
};


export const useRapbackPdfURL = (rapbackId: string | null, open: boolean) => {
    const [rapbackPdfURL, setRapbackPdfURL] = React.useState<PdfUrls>([]);
    const [loadingPDF, setLoadingPDF] = React.useState(false);
    const url = urls.REPORTING

    useEffect(() => {
        if (rapbackId === null && !open) {
            setRapbackPdfURL([])
            return
        } else {
            setLoadingPDF(true)
            const controller = new AbortController();
            const { signal } = controller;
            let fetchUrl = `${process.env.NODE_ENV === 'production' ? url : ""}/adjudication/pdf/rapbacks/${rapbackId}`
            console.log(`debug url usePdf fetchUrl: ${fetchUrl} `)
            const fetchUrls = async () => {
                try {
                    const { urls } = await (await fetch(fetchUrl, {
                        method: "GET",
                        credentials: "include",
                        signal
                    })).json();
                    urls ? setRapbackPdfURL(urls) : setRapbackPdfURL([]);
                    setLoadingPDF(false);

                } catch (err: any) {
                    setRapbackPdfURL([])
                    setLoadingPDF(false);
                    console.log('ERROR => ', err);
                }
            }
            fetchUrls();

            return () => {
                controller.abort();
            }
        }

    }, [rapbackId, url])

    return { rapbackPdfURL, loadingPDF };
}