import {SavedSearchActionTypes} from "../../../customEnums";

//Put all commented set states into handlers in TransactionSummary file then run those functions in the places where the associated actions are called
export const savedSearchesReducer = (currentState: any, action: {configService?: any, searchData?: any, searchName?: string, type: string, savedSearch?: {label: string, value: string}, setters?: any}) => {
    switch (action.type) {
        case SavedSearchActionTypes.DELETE_SEARCH:
            console.log(`debug saved search: DELETING SAVED SEARCH ${action.searchName} currrentState before delete`, currentState)
            var deleteArray = Object.keys(currentState).map(key => {
                return currentState[key];
            })
            deleteArray.forEach((object: any, index) => {
                if (object.label === action.searchName) {
                    deleteArray.splice(index, 1);
                }
            });
            action.setters.setIsDelete(true);
            action.setters.setConfigFile('sticky-settings.json');
            action.setters.setConfigBody(deleteArray);
            action.setters.setIsPost(true);
            action.setters.setInternalSearchArray(deleteArray)
            return {
                ...deleteArray
            };
        case SavedSearchActionTypes.ADD_SAVED_SEARCH:
            console.log(`debug saved search reducer: ADDING SAVED SEARCHasdasd ${JSON.stringify(action.savedSearch, null, 2)}`);
            var searchArray = Object.keys(currentState).map(key => {
                return currentState[key];
            })
            console.log('debug savedsearch searcharray', searchArray)
            const tempJsonObject: {} = action.savedSearch!;
            console.log('debug savedsearch tempjsonobject', tempJsonObject)
            searchArray.push(tempJsonObject);
            console.log('debug saved search searcharray after push', searchArray)
            action.setters.setIsDelete(false);
            action.setters.setConfigFile('sticky-settings.json');
            action.setters.setConfigBody(searchArray);
            action.setters.setIsPost(true)
            action.setters.setInternalSearchArray(searchArray)
            return {
                ...searchArray
            }
        case SavedSearchActionTypes.LOAD_SEARCHES:
            console.log(`debug saved search: LOADING SAVED SEARCHES actions: ${JSON.stringify(action, null, 2)} currentState`, currentState);
            action.setters.setIsDelete(false);
            action.setters.setConfigFile('sticky-settings.json');
            action.setters.setConfigService({...action.configService, postedConfig: false})
            //action.setters.setPostedConfig(false);
            action.setters.setIsPost(false);
            action.setters.setInternalSearchArray(action.searchData)
            return {
                ...action.searchData,
            };

        case SavedSearchActionTypes.UNLOAD_SEARCHES:
            action.setters.setInternalSearchArray({})
            return {}

        default: throw new Error('Invalid action type');
    }
};