import React, { useEffect, useState } from 'react'
import { TableFooter, Typography, TablePagination } from "@mui/material";
import { useTableStore } from "state/TableStore";
import { useLocation } from "react-router-dom";
import { useConfig } from 'hooks/useConfig';
import usePagination from '@mui/material/usePagination/usePagination';
import { useMUITheme } from 'theme/ThemeProvider';
import { table } from 'console';
import { LogConfig, logger } from "../../../utils";
import { extractPageName } from "../helpers";

export interface CustomTableFooterProps {
    tcns: any[],
    totalRecords: number,
    tableName: string,
    useQueryPagination: boolean
}

export const CustomTableFooter: React.FC<CustomTableFooterProps> = ({ useQueryPagination, tcns, totalRecords, tableName }) => {
    const { mode } = useMUITheme();

    const pagination = useTableStore((state) => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)
    const multiTablePagination = useTableStore((state) => state.multiTablePagination)
    const setMultiTablePagination = useTableStore(state => state.setMultiTablePagination)

    let detailPage: number;
    let detailRowsPerPage: number;

    if (tableName && multiTablePagination[tableName]) {
        const { detailPage: page, detailRowsPerPage: rowsPerPage } = multiTablePagination[tableName];
        detailPage = page;
        detailRowsPerPage = rowsPerPage;
    } else {
        detailPage = 0;
        detailRowsPerPage = 25;
    }

    const { rowsPerPage, page } = pagination
    const [internalCurrentPage, setInternalCurrentPage] = useState(0)
    const [internalPageSize, setInternalRowsPerPage] = useState(25)


    const setPage = useTableStore(state => state.setPage)
    const setRowsPerPage = useTableStore(state => state.setRowsPerPage)

    const detailsTable = useTableStore(state => state.detailsTable)
    const handleCustomRefetch = useTableStore(state => state.handleCustomRefetch)


    const { headerCells } = useConfig();
    const locationObj = useLocation()
    const label = locationObj.pathname.slice(1)
    const pageName = extractPageName(locationObj.pathname)

    //Forward paginationg works need to fix backwards. 
    useEffect(() => {
        if (useQueryPagination) {
            setRowsPerPage(internalPageSize)
            setPage(internalCurrentPage)
        } else {
            setRowsPerPage(rowsPerPage)
            setPage(page - 1)
        }
    }, [useQueryPagination, internalPageSize, internalCurrentPage])


    //console.log('useQueryPagination: ', useQueryPagination)

    const handleChangePage = (event: unknown, newPage: number) => {
        //console.log('change page handleChangePage running, ', newPage)
        setInternalCurrentPage(newPage);
    };

    useEffect(() => {
        //console.log('change page useQueryPagination: ', useQueryPagination)
        //console.log('change page useEffect internalCurrentPage: ', internalCurrentPage)
        //console.log(`useQueryPagination useEffect pagination: `, pagination)
        if (tableName === 'rb-sub-audit-history') {
            setPage(detailPage)
            setRowsPerPage(detailRowsPerPage)
        } else {
            setPage(pagination.page)
            setRowsPerPage(pagination.rowsPerPage)
        }
    }, [internalCurrentPage, useQueryPagination, pagination])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log(`useQueryPagination ${useQueryPagination} handleChangeRowsPerPage`)
        setInternalRowsPerPage(parseInt(event.target.value, 10));
        setInternalCurrentPage(0);
    };

    const debugFooterLogger: LogConfig = {
        identifier: "debugFooterLogger",
        pageName,
        thingToDebug: "table footer params",
        paramsToLog: {
            useQueryPagination,
            tableName,
            mulitTablePagination: multiTablePagination[tableName],
            pageProp: useQueryPagination && tableName !== 'rb-sub-audit-history' && tableName !== "reject-codes" ? "first condition" : "second condition",
            page,
            detailPage
        }
    }

    //loggerdebugFooterLogger)

    //console.log(`CustomTableFooter onRowsPerPageChange detailRowsPerPage ${detailRowsPerPage}`)

    return (
        <TableFooter sx={{ backgroundColor: tableName && tableName !== "rapback-maintenance" && mode === "dark" && tableName !== 'rb-sub-audit-history' ? "#1C1D27" : "background.default", display: "flex", justifyContent: label === 'batch-printing' ? "space-between" : 'flex-end', alignItems: "center", padding: detailsTable.includes(label) ? 0 : '0 0.5rem', }}>
            {label === 'batch-printing' &&
                <Typography color='text.primary' variant='body2' sx={{ pl: '1.5rem' }}>{tcns.length} row(s) selected</Typography>
            }

            {/* {(detailsTable.includes(label)) ? null :
                    <Pagination
                        sx={{ minHeight: "60px", display: "flex", alignItems: "center" }}
                        count={Math.ceil(totalRecords / rowsPerPage)}
                        page={page}
                        onChange={(e: any, value) => handlePageChange?.(value)}
                    />
                } */}
            <TablePagination
                sx={{ minHeight: "45px", border: "none" }}
                rowsPerPageOptions={[5, 10, 25, 100]}
                colSpan={9}
                count={totalRecords}
                rowsPerPage={tableName && multiTablePagination[tableName] ? detailRowsPerPage : rowsPerPage}
                page={totalRecords <= 0 ? 0 : useQueryPagination && tableName !== 'rb-sub-audit-history' && tableName !== "reject-codes" && tableName !== 'audit-history'
                    ? page - 1
                    : tableName && multiTablePagination[tableName]
                        ? detailPage
                        : page
                }
                SelectProps={{
                    inputProps: {
                        'aria-label': 'mappedData per page',
                    },
                    native: true,
                }}
                onPageChange={(e: any, value) => {

                    const debugTablePagination: LogConfig = {
                        identifier: "debugTablePagination",
                        pageName: "transaction-summary",
                        thingToDebug: "CustomTableFooter onPageChange",
                        paramsToLog: {
                            tableName,
                            multiTablePagination,
                            selectedPagination: tableName && multiTablePagination[tableName] ? "MultiTablePagination" : "RegularPagination",
                            MultiTablePaginationSetTo: {
                                [tableName]: {
                                    ...multiTablePagination[tableName],
                                    detailPage: value
                                }
                            },
                            RegularPaginationSetTo: { ...pagination, page: useQueryPagination ? value + 1 : value }
                        }

                    }
                    //loggerdebugTablePagination)


                    tableName && multiTablePagination[tableName]
                        ? setMultiTablePagination(
                            {
                                ...multiTablePagination,
                                [tableName]: {
                                    ...multiTablePagination[tableName],
                                    detailPage: value
                                }
                            }
                        )
                        : setPagination({ ...pagination, page: useQueryPagination ? value + 1 : value })
                    //handleCustomRefetch()
                }
                }
                onRowsPerPageChange={(e: any) => {
                    const debugTablePagination: LogConfig = {
                        identifier: "debugTablePagination",
                        pageName: "transaction-summary",
                        thingToDebug: `CustomTableFooter onRowsPerPageChange selected page ${parseInt(e.target.value)}`,
                        paramsToLog: {
                            tableName,
                            multiTablePagination,
                            selectedPagination: tableName && multiTablePagination[tableName] ? "MultiTablePagination" : "RegularPagination",
                            MultiTablePaginationSetTo: {
                                ...multiTablePagination, [tableName]: {
                                    ...multiTablePagination[tableName],
                                    detailRowsPerPage: parseInt(e.target.value),
                                    detailPage: 0
                                }
                            },
                            RegularPaginationSetTo: { ...pagination, rowsPerPage: parseInt(e.target.value), page: useQueryPagination ? 1 : 0 }
                        }

                    }
                    //loggerdebugTablePagination)
                    tableName && multiTablePagination[tableName] ?
                        setMultiTablePagination({
                            ...multiTablePagination, [tableName]: {
                                ...multiTablePagination[tableName],
                                detailRowsPerPage: parseInt(e.target.value),
                                detailPage: 0
                            }
                        })
                        :
                        setPagination({ ...pagination, rowsPerPage: parseInt(e.target.value), page: useQueryPagination ? 1 : 0 })
                }
                }
            />
        </TableFooter>
    )
}
