import React, { useEffect, useState } from 'react';
import {
    Button,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Box,
    Popover,
    RadioGroup,
    FormControlLabel,
    Radio,
    Skeleton,
    Tooltip,
    Grid,
    IconButton,
    Typography, Chip
} from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircleIcon from '@mui/icons-material/Circle';
import InboxIcon from '@mui/icons-material/Inbox';
import { useTableStore } from 'state/TableStore';
import { useLocation } from 'react-router-dom';
import { Actions } from 'pages/TransactionSummary/components';
import { useConfig } from 'hooks/useConfig';
import { CustomTableCellSelect } from './CustomTableCellSelect';
import dayjs from 'dayjs';
import { extractPageName } from '../helpers';
import { LogConfig } from "../../../utils";
import { HeaderCell } from 'interfaces/config';

export const CustomTableBody = ({
    openModalBlur,
    openAgencyEdit,
    loading,
    visibleRows,
    displaySSN,
    rowClickHandler,
    handleClickShowSSN,
    tableName
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { headerCells, userRoles } = useConfig()
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const columnState = useTableStore(state => state.columnState)
    const pagination = useTableStore(state => state.pagination)
    const { rowsPerPage } = pagination
    const currentRow = useTableStore((state) => state.currentRow)
    const setCurrentRow = useTableStore((state) => state.setCurrentRow)
    const displayNoSearchResults = useTableStore((state) => state.displayNoSearchResults)
    const selected = useTableStore(state => state.selected)
    const handleOpenRejectDrawer = useTableStore(state => state.handleOpenRejectDrawer)
    const [data, setData] = useState([])
    const STATE = process.env.REACT_APP_CONFIG_STATE!;
    const pageWithSSN = ["rapback-maintenance", "rapback-validations", "rapback-prenotifications", 'rapback-summary']

    const errorStatus = pageName === "transaction-summary" ? ["ERRT-Error", "QC Reject", "ERRT-Error NoRBsub"] : []

    const isSelected = (tcn: string) => {
        return selected?.indexOf(tcn) !== -1;  //this checks tcn which it only gets from select all
    }

    let tableHeaderCells = headerCells.find((headerCell: HeaderCell) => headerCell.tableName === tableName)?.tableHeaderCells!!
    const headerCellsLength = tableHeaderCells?.length
    const widthPercentage = 100 / headerCellsLength

    const tableRowStyle = {
        cursor: pageName === "applicant-details" || (pageName === "agency-management" && STATE === "ga") || tableName === 'rb-sub-audit-history' || tableName === "reject-codes" ? '' : "pointer",
        '&:hover': {
            backgroundColor: pageName === "applicant-details" || (pageName === "agency-management" && STATE === "ga") || tableName === 'rb-sub-audit-history' || tableName === "reject-codes"
                ? '' : "background.paper"
        },
        '&:last-child td, &:last-child th': {
            border: 0
        }
    }

    const tableBodyCellStyle = {
        paddingTop: "16px",
        paddingRight: "10px",
        paddingBottom: "16px",
        paddingLeft: "0px",
        width: `${widthPercentage}%`,
        filter: (openModalBlur || openAgencyEdit) ? 'blur(4px)' : null,
    }

    const handlePopover = ((e: any) => {
        setAnchorEl(e.currentTarget);
    })

    useEffect(() => {

    }, []);

    useEffect(() => {
        setData(visibleRows)
    }, [visibleRows]);

    // @ts-ignore
    const SkeletonLoading: React.FC<any> = ({ rowsPerPage }) => {
        let tableHeaderCells = headerCells.find(cell => cell.tableName === tableName)!.tableHeaderCells
        let activeCells: string[] = []

        tableHeaderCells.forEach((cell: any) => {
            if (columnState.page[pageName].columnVisibility[cell.dataIndex]) {
                activeCells.push(cell.dataIndex)
            }
        })

        const headerCellsLength = activeCells.length

        return Array.from({ length: headerCellsLength }).map((row, index) => (
            <TableRow key={index} sx={{ border: 0 }}>
                {
                    Array.from({ length: headerCellsLength }).map((cell: any, index) => {
                        return (
                            <TableCell key={index}>
                                <Skeleton sx={{ height: '25px' }} />
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        ))
    }

    return (
        <TableBody key={tableName} sx={{ minWidth: "1760px", backgroundColor: tableName !== "rapback-maintenance" && tableName && tableName !== 'rb-sub-audit-history' ? 'common.black' : 'background.default', }} >
            {
                loading && rowsPerPage > 0 && headerCells.length > 0
                    ? <SkeletonLoading key={rowsPerPage} />
                    : visibleRows?.length === 0 && displayNoSearchResults
                        ? <TableRow key={0}>
                            <TableCell colSpan={Object.keys(columnState.page[pageName].columnVisibility!).length} align="center">
                                <Grid>
                                    <InboxIcon sx={{ color: 'text.primary' }} />
                                    <Typography variant="subtitle2" color='text.primary'>
                                        No Results
                                    </Typography>
                                </Grid>
                            </TableCell>
                        </TableRow>

                        : visibleRows?.map(
                            (row: any, rowIndex: number) => {
                                const isItemSelected = isSelected(pageName === "batch-printing" && row.transactionNumber ? row.transactionNumber : "") //this always tcn
                                /*if (pageName !== "rapback-summary" && pageName !== "batch-printing" && row.agencyORI) isItemSelected = isSelected(row.agencyORI)
                                if (pageName === "rapback-summary" && row.key) isItemSelected = isSelected(row.key); */
                                //if (isItemSelected) console.log(`${pageName} last call after select tcn: ${row.transactionNumber} isItemSelected: `, isItemSelected)
                                const isCellRevealed = displaySSN === row.key
                                const isCurrentRowClicked = currentRow === row.key

                                return (
                                    <TableRow
                                        key={row.key + tableName}
                                        onMouseEnter={pageName !== 'rapback-summary' ? () => setCurrentRow(row.key) : () => {}}
                                        onMouseLeave={pageName !== 'rapback-summary' ? () => setCurrentRow(null) : () => {}}
                                        onClick={(e) => rowClickHandler?.(e, row)}
                                        sx={tableRowStyle}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                    >
                                        {
                                            tableHeaderCells?.map((cell: any, index: number) => {
                                                return (
                                                    columnState.page[pageName].columnVisibility[cell.dataIndex] && (

                                                        <TableCell sx={cell.dataIndex === 'actions' || cell.dataIndex === 'checkbox' ? { width: '1%', pl: 0 } : tableBodyCellStyle} key={cell.dataIndex}>
                                                            <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center" }}>
                                                                {
                                                                    cell.dataIndex === 'lastViewedDate' &&
                                                                    <Typography sx={{ minWidth: '100%', fontSize: '14px' }}>
                                                                        {
                                                                            row.viewed ? dayjs(row.viewedDate).format('MMM D, YYYY h:mm:ss A')
                                                                                :
                                                                                <>
                                                                                    <CircleIcon color='primary' sx={{ pt: '0.75rem', mr: '0.25rem' }} />
                                                                                    Not Viewed
                                                                                </>
                                                                        }
                                                                    </Typography>
                                                                }
                                                                {
                                                                    pageName === "batch-printing" && cell.dataIndex === "checkbox" && (
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={isItemSelected}
                                                                            inputProps={{ 'aria-labelledby': `select-${cell.dataIndex}` }}
                                                                        />
                                                                    )
                                                                }
                                                                {
                                                                    cell.dataIndex === 'actions' &&
                                                                    row.addedDate !== "NO DATA"
                                                                    &&
                                                                    <Actions
                                                                        row={row}
                                                                        pageName={pageName}
                                                                        isCurrentRowClicked={isCurrentRowClicked}
                                                                        rowClickHandler={(e) => rowClickHandler(e, row)}
                                                                    />
                                                                }
                                                                <Typography
                                                                    sx={{ fontSize: "14px", width: errorStatus.includes(row[cell.dataIndex]) ? 0 : cell.textWidth ? cell.textWidth : "100%", textOverflow: "ellipsis", overflow: "hidden" }} variant='body2'>
                                                                    {
                                                                        pageName === "transaction-summary"
                                                                            ? cell.dataIndex === "status"
                                                                                ? !errorStatus.includes(row[cell.dataIndex]) && row[cell.dataIndex]
                                                                                : cell.dataIndex !== "ssn"
                                                                                    ? row[cell.dataIndex]
                                                                                    : cell.dataIndex === 'ssn' && !isCellRevealed && row.ssn !== 'N/A'
                                                                                        ? '***-**-****'
                                                                                        : row[cell.dataIndex]
                                                                            : (pageWithSSN.includes(pageName)) && cell.dataIndex !== "timeRemaining"
                                                                                ? cell.dataIndex !== "ssn"
                                                                                    ? row[cell.dataIndex]
                                                                                    : cell.dataIndex === 'ssn' && !isCellRevealed && row.ssn !== 'N/A'
                                                                                        ? '***-**-****'
                                                                                        : row[cell.dataIndex]
                                                                                : pageName === "user-management"
                                                                                    ? cell.dataIndex !== "securityLevel" && cell.dataIndex !== "status" && row[cell.dataIndex]
                                                                                    : (pageName === "agency-management" || pageName === 'applicant-details' || pageName === "batch-printing" || pageName === "rapback-summary") && row[cell.dataIndex]
                                                                    }
                                                                    {
                                                                        cell.dataIndex === "timeRemaining" && (
                                                                            row[cell.dataIndex] <= 10
                                                                                ? <Chip variant={row[cell.dataIndex] === 1 ? "filled" : "outlined"} style={{ borderColor: 'red', color: row[cell.dataIndex] === 1 ? 'white' : 'red', backgroundColor: row[cell.dataIndex] === 1 ? 'red' : 'unset' }} label={row[cell.dataIndex] + " " + `${row[cell.dataIndex] === 1 ? "Day" : "Days"} Remaining`} />
                                                                                : <Typography>{row[cell.dataIndex] + " " + "Days Remaining"}</Typography>
                                                                        )
                                                                    }

                                                                    {
                                                                        cell.dataIndex === 'isActive' &&
                                                                        <div>
                                                                            <Button size='small' disabled onClick={handlePopover} endIcon={<ArrowDropDownIcon />}>{row.isActive ? 'Active' : 'Inactive'}</Button>
                                                                            <Typography sx={{ pl: '5px' }} variant='body2'>{'Oct 19, 2023'}</Typography>
                                                                            <Popover
                                                                                elevation={0}
                                                                                PaperProps={{
                                                                                    style: {
                                                                                        boxShadow: '0px 3px 5px -1px rgba(124, 124, 130, 0.20)'
                                                                                    }
                                                                                }}
                                                                                sx={{ borderRadius: '0.375rem', }}
                                                                                id={row.key}
                                                                                open={Boolean(anchorEl)}
                                                                                // open={true}
                                                                                anchorEl={anchorEl}
                                                                                onClose={() => setAnchorEl(null)}
                                                                                anchorPosition={{ top: 50, left: 140 }}
                                                                                anchorOrigin={{
                                                                                    vertical: "bottom",
                                                                                    horizontal: "center"
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: "top",
                                                                                    horizontal: "center"
                                                                                }}>
                                                                                <RadioGroup
                                                                                    sx={{ width: '9.68rem', padding: '0.5rem 1rem' }}
                                                                                    defaultValue={row.isActive === true ? 'Active' : 'Inactive'}
                                                                                >
                                                                                    <FormControlLabel label='Active' value='Active' control={<Radio />} sx={{ m: 0, fontFamily: 'Montserrat' }} />
                                                                                    <FormControlLabel label='Inactive' value='Inactive' control={<Radio />} sx={{ m: 0 }} />
                                                                                </RadioGroup>
                                                                            </Popover>
                                                                        </div>
                                                                    }


                                                                </Typography>
                                                                {
                                                                    row.ssn !== 'N/A' ?
                                                                        cell.dataIndex !== 'ssn' ?
                                                                            null : !isCellRevealed ?
                                                                                <Tooltip title="Hide">
                                                                                    <IconButton size='small' onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        handleClickShowSSN?.(row.key, e)
                                                                                    }}>
                                                                                        <VisibilityOffIcon sx={{ color: 'action.active' }} fontSize='small' />
                                                                                    </IconButton>
                                                                                </Tooltip>

                                                                                : <Tooltip title="Show">
                                                                                    <IconButton size='small' onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        handleClickShowSSN?.(row, e)
                                                                                    }}>
                                                                                        <VisibilityIcon sx={{ color: 'action.active' }} fontSize='small' />
                                                                                    </IconButton>
                                                                                </Tooltip>

                                                                        : null
                                                                }
                                                                {
                                                                    cell.dataIndex === "securityLevel" && row.securityAndStatus.securityLevel !== '' && (
                                                                        <CustomTableCellSelect
                                                                            rowData={row}
                                                                            dataIndex={cell.dataIndex}
                                                                            menuItems={userRoles}
                                                                            value={row.securityAndStatus.securityLevel}
                                                                        // value={hello?.securityAndStatus?.securityLevel}
                                                                        />
                                                                    )
                                                                }
                                                                {
                                                                    pageName === 'transaction-summary' && cell.dataIndex === "status" && errorStatus.includes(row.status) && (
                                                                        <Button onClick={(e) => handleOpenRejectDrawer(e, row)}
                                                                            sx={{ justifyContent: "flex-start", minWidth: "max-content", padding: 0 }}
                                                                            variant="text">
                                                                            {row.status}
                                                                        </Button>
                                                                    )
                                                                }
                                                                {
                                                                    pageName === 'user-management' && cell.dataIndex === "status" && cell.menuItems && (
                                                                        <>
                                                                            <CustomTableCellSelect
                                                                                rowData={row}
                                                                                dataIndex={cell.dataIndex}
                                                                                menuItems={cell.menuItems}
                                                                                value={row.securityAndStatus.status}
                                                                            />
                                                                        </>
                                                                    )
                                                                }
                                                            </Box>
                                                        </TableCell>)
                                                )
                                            })
                                        }
                                    </TableRow>

                                )
                            }

                        )

            }
        </TableBody>


    )
}