import {
    Drawer,
    Box,
    Typography,
    Button,
    TextField,
    MenuItem,
    Grid,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useEffect, useState } from 'react'
import { USSTATES } from "../helpers/USStates";
import { EditAgencyProps } from '@propTypes/index';
import { AgencyManagementStyles } from 'customStyles';
import { getCurrentEnv } from 'utils/getCurrentEnv';

let lowerSliceIndex = 4
let midSliceIndex = 5
let upperSliceIndex = 9

let titleToNameMap = {
    agencyORI: getCurrentEnv === 'me'  || getCurrentEnv === 'ga'? "Primary ORI" : "ORI",
    reasonFingerPrinted: "Reason Fingerprinted",
    agencyName: "Agency Name",
    distributionMethod: "Distribution Method",
    agencyEmail: "E-mail",
    agencyAddress: "Address",
    agencyCity: "City",
    agencyState: "State",
    agencyZipCode: "Zip Code",
    agencyStatus: "Status"
} as any;

export const EditAgencyDrawer: React.FC<EditAgencyProps> = ({
    openAgencyEdit,
    closeDrawer,
    fieldNames,
    submitEditedAgency,
    selectedRowText,
    handleEditInput,
    touchedFields,
    fieldError,
    error,
    handleTextFieldError,
    distributionMethodOptions,
    handleCancel,
    edited
}) => {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    if (getCurrentEnv === 'me') {
        titleToNameMap = { ...titleToNameMap, secondaryOri: "Secondary ORI" };
        fieldNames = [...fieldNames.slice(0, 1), "secondaryOri", ...fieldNames.slice(1)];
        lowerSliceIndex = 5
        midSliceIndex = 5
        upperSliceIndex = 10
    }

    return (
        <Drawer anchor='right' open={openAgencyEdit} onClose={closeDrawer} sx={{ zIndex: 5000 }}>
            <Box sx={AgencyManagementStyles.editAgencyModalBox}>
                <Grid container justifyContent='flex-end' alignItems='center'>
                    <Button size='small' startIcon={<CloseIcon />} sx={{ color: 'text.primary' }} onClick={closeDrawer}>CLOSE</Button>
                </Grid>
                <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, mb: '1rem' }}>EDIT AGENCY</Typography>
                <SimpleBar style={{ height: screenHeight < 1000 ? '65vh' : '80vh' }} >
                    <form style={{ paddingTop: '1rem' }} onSubmit={(e) => submitEditedAgency(e, selectedRowText, selectedRowText.key)}>
                        {fieldNames.slice(0, upperSliceIndex).map((fieldName: any) => {
                            return (
                                <Grid item xs={12} sx={{ mb: '1.5rem' }}>
                                    <TextField
                                        key={titleToNameMap[fieldName]}
                                        onChange={(e) => handleEditInput(fieldName, e.target.value)}
                                        helperText={touchedFields[fieldName] ? error : false}
                                        error={touchedFields[fieldName] ? fieldError : false}
                                        onBlur={(e) => handleTextFieldError(e, fieldName)}
                                        fullWidth
                                        label={titleToNameMap[fieldName]}
                                        select={fieldName.includes('agencyState') || fieldName.includes('distributionMethod') || fieldName.includes('agencyStatus')}
                                        required={fieldName.includes('agencyEmail') || fieldName.includes('secondaryOri') ? false : true}
                                        value={selectedRowText?.[fieldName]}
                                        inputProps={fieldName.includes('agencyZipCode') ? {
                                            pattern: "[0-9]*",
                                            maxLength: 5
                                        } : {}}
                                        SelectProps={{
                                            MenuProps: {
                                                style: {
                                                    zIndex: 5500,
                                                    height: '28.125rem'
                                                }
                                            }
                                        }}
                                    >
                                        {fieldName.includes('distributionMethod') && distributionMethodOptions.map((options: any) => {
                                            return (
                                                <MenuItem key={options.label} value={options.value} sx={{ backgroundColor: 'background.default' }}>{options.value}</MenuItem>
                                            )
                                        })}
                                        {fieldName.includes('agencyState') && USSTATES.map((value: any) => {
                                            return (
                                                <MenuItem key={value.label} value={value.value} sx={{ overflowY: 'auto', backgroundColor: 'background.default' }}>
                                                    {`${value.value} - ${value.label}`}
                                                </MenuItem>
                                            )
                                        })}
                                        {/* {fieldName.includes('agencyStatus') &&
                                                <span>
                                                    <MenuItem key='Active' value='Active'>Active</MenuItem>
                                                    <MenuItem key='Inactive' value='Inactive'>Inactive</MenuItem>
                                                </span>   
                                            } 
                                        */}
                                    </TextField>
                                </Grid>
                            )
                        })}
                        {/* <Grid item xs={12} >
                            <TextField fullWidth label={titleToNameMap['agencyStatus']} defaultValue='Active' select required disabled>
                                <MenuItem value='Active'>Active</MenuItem>
                                <MenuItem value='Inactive'>Inactive</MenuItem>
                            </TextField>
                        </Grid> */}
                        <Grid xs={12} sx={{ display: 'flex', mb: '1.5rem', mt: '1.5rem' }} bottom={0} position='fixed' width='36rem'>
                            <Button fullWidth variant='outlined' color='secondary' sx={{ mr: '1rem', color: 'text.primary' }} onClick={handleCancel} >CANCEL</Button>
                            <Button fullWidth type="submit" variant='contained' color='secondary' disabled={fieldError && !edited ? true : false} >UPDATE</Button>
                        </Grid>
                    </form>
                </SimpleBar>
            </Box>
        </Drawer>
    )
}
