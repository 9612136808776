import * as React from 'react';
import { pageStyles } from "customStyles/common";
import {
    Button, 
    Container, 
    Paper, 
    Typography,
} from "@mui/material";
import {
    ConfirmModal,
    CustomTable,
    CustomTableTitleAndAlert,
    RightDrawer
} from "../../../common";
import { useCallback, useEffect, useReducer, useState } from "react";
import { Order } from "@customTypes/OrderType";
import { useTableStore } from "../../../state";
import { useExpanded } from "../../../components/layouts/ProtectedLayout";
import { useUserCognito } from "hooks";
import { useRapbackSubscription } from "../../../hooks/useRapbackSubscription";
import { useLocation } from "react-router-dom";
import { extractPageName } from "../../../common/CustomTable/helpers";
import { editSubscriptionReducer } from "../../TransactionSummary/helpers/searchReducer";
import { useRapbackMaintenanceStore } from "./state";
import dayjs, { Dayjs } from 'dayjs';
import { useAudit } from "hooks/useAudit";
import { AuditHistoryTable } from '../../../common/AuditHistoryTable';
import {EditSubscriptionForm} from "./EditSubscriptionForm";

export enum EditSubscriptionAction {
    DOB = 'DOB',
    EXPIRATION_DATE = 'EXPIRATION_DATE',
    SET_VALUES = 'SET_VALUES',
    FIRST_NAME = 'FIRST_NAME',
    STATUS = 'STATUS',
    TYPE = 'TYPE',
    LOWER_DATE = 'LOWER_DATE',
    UPPER_DATE = 'UPPER_DATE',
    RFP = 'RFP',
    DAYS = 'DAYS',
    RESET = 'RESET',
    TCN = 'TCN'
}

export const RapbackMaintenance: React.FC = () => {
    const locationObj = useLocation()
    const currentPageName = extractPageName(locationObj.pathname)
    const { addAuditEvent } = useAudit();

    //Local States
    const [data, setData] = useState()
    const [sortOrder, setSortOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>('status');
    const [searched, setSearched] = useState<boolean>(false); //Set true when an advanced form multi parameter search has run
    const [value, setValue] = React.useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openAuditDrawer, setOpenAuditDrawer] = useState(false)
    const [id, setId] = useState("");
    const [displaySSN, setDisplaySSN] = useState('')
    const [showSSN, setShowSSN] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [confirmModalTitle, setConfirmModalTitle] = useState("")
    const [confirmModalContent, setConfirmModalContent] = useState("")
    const [cancelButtonText, setCancelButtonText] = useState("")
    const [confirmButtonText, setConfirmButtonText] = useState("")
    const [action, setAction] = useState("")
    const [applicantName, setApplicantName] = useState()
    const [internalRapbackId, setRapbackId] = useState("")
    
    //Table Store
    const setPagination = useTableStore(state => state.setPagination)
    const pagination = useTableStore((state) => state.pagination)
    const setOpenNotification = useTableStore((state) => state.setOpenNotification)
    const setOpenAuditHistory = useTableStore(state => state.setOpenAuditHistory)
    const pageName = useTableStore(state => state.pageName);
    const setPageName = useTableStore(state => state.setPageName);
    setPageName(currentPageName)

    const openNotification = useTableStore((state) => state.openNotification)
    const searchParams = useTableStore((state) => state.searchParams)
    const setSearchParams = useTableStore((state) => state.setSearchParams)
    const setActionsConfig = useTableStore(state => state.setActionsConfig)
    
    //Maintenance Table Store
    const setFullName = useRapbackMaintenanceStore(state => state.setFullName)
    const setOpenDrawerHandler = useRapbackMaintenanceStore((state) => state.setOpenDrawerHandler)
    setOpenDrawerHandler(setOpenDrawer)
    
    const { rowsPerPage, page } = pagination

    //Hooks
    const { expanded } = useExpanded()
    const { userORIs, userRole, userSecondaryORIs } = useUserCognito();
    const [formState, dispatchFormValues] = useReducer(editSubscriptionReducer, { expirationDate: '', dob: '' })

    //Data
    const { data: mappedData, totalRecords, loading, handleUnsubscribe, handleUpdateSubscription } = useRapbackSubscription(pageName, rowsPerPage,
        page,
        sortOrder,
        orderBy,
        userORIs,
        userSecondaryORIs,
        userRole,
        searchParams, true)

    console.log(`Maintenance data: `, mappedData)

    useEffect(() => {
        setData(
            mappedData?.map((data: any, index) => {
                return {
                    key: data.key,
                    fullName: data.fullName,
                    dob: data.dob,
                    ssn: data.ssn,
                    rapbackId: data.rapbackId, //is this rapback id?
                    ori: data.ori,
                    //secondaryOri: data.secondaryOri,
                    startDate: data.startDate,
                    expirationDate: data.expirationDate,
                    unsubscribedDate: data.unsubscribedDate,
                    status: data.status
                }
            })
        );
    }, [mappedData?.length, mappedData])

    //Needed for all pages that use query service
    useEffect(() => {
        setPagination({ ...pagination, page: 1 })
        console.log('pagination right here: ', pagination)
        console.log(`debug visibleRows page ${page}, rowsPerPage: ${rowsPerPage}`)
    }, [])

    useEffect(() => {
        console.log('createSearch formState: ', formState)
        const formStateProps = Object.keys(formState)
        if (formStateProps.length === 1 && formStateProps[0] === "days") {
            console.log('createSearch update searchParams')
            setSearchParams([{ column: "days", value: formState.days }])
        }
    }, [Object.keys(formState).length])

    const handleClickShowSSN = useCallback(async (record: string, e: any) => {
        if (displaySSN !== record) {
            setDisplaySSN(record)
            setShowSSN(!showSSN)

        } else {
            setDisplaySSN('')
            setShowSSN(!showSSN)
        }
    }, [displaySSN])

    //Handlers
    const handleOpenEditSubscriptions = (e, row) => {
        console.log(`handeOpenEditSubs row: `, row)
        dispatchFormValues(
            {
                type: EditSubscriptionAction.SET_VALUES,
                value: {

                    fullName: row.fullName,
                    dob: row.dob,
                    rapbackId: row.rapbackId,
                    ori: row.ori,
                    startDate: row.startDate,
                    expirationDate: row.expirationDate === "N/A" ? "9/29/2023" : row.expirationDate,
                    confirmation: row.startDate,
                    status: row.status
                }
            })
        setOpenDrawer(true)
    }

    const handleOpenAuditHistory = async (e, row) => {
        console.log('mappedData - Audit History row: ', row)
        setOpenAuditDrawer(true);
        setId(row.rapbackId)
        setApplicantName(row.fullName)

    }

    //Potentially make generic and import
    const handleRequestSort = (event: any, property) => {
        if (property === "startDate") {
            property = "subscriptionDate"
        }
        console.log(`sort date handleRequestSort sortOrder: ${sortOrder} orderBy ${orderBy} property: ${property}`)
        const isAsc = orderBy === property && sortOrder === 'asc';
        console.log(`sort date handleRequestSort sortOrder: isAsc ${isAsc}`)
        setSortOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property);
    };

    const EditSubTextDisplay: React.FC<{ title: string, value: string }> = ({ title, value }) => {
        return (
            <Container disableGutters sx={{ display: "flex", flexDirection: "column" }}>
                <Typography>{title.toUpperCase()}</Typography>
                <Typography>{value}</Typography>
            </Container>
        )
    }

    const EditSubTextRow: React.FC<any> = ({ children }) => {
        return (
            <Container disableGutters sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                {children}
            </Container>
        )
    }

    const handleCancel = useCallback(() => {
        setOpenDrawer(false)
        setOpenAuditDrawer(false)
    }, [])

    setOpenAuditHistory(handleOpenAuditHistory)

    const handleConfirmUnsubscribe = (e, row) => {
        setRapbackId(row.rapbackId)
        setAction("unsubscribe")
        setConfirmModalTitle("CONFIRM UNSUBSCRIBE REQUEST")
        setConfirmModalContent(`Are you sure you want to unsubscribe ${row.fullName} from receiving Rapbacks? This action cannot be undone.`)
        setCancelButtonText("CANCEL")
        setConfirmButtonText("UNSUBSCRIBE")
        setOpenConfirmModal(true)
        setFullName(row.fullName)
        addAuditEvent("Subscription Cancellation Request Submitted", "Rapback Maintenance", new Date(), row.rapbackId)
    }

    useEffect(() => {
        if (openNotification) {
            setTimeout(() => {
                setOpenNotification(false)
            }, 5000)
        }
    })

    const actionsConfig: { actionLabel: string, actionHandler: Function}[] = [
        {
            actionLabel: "Unsubscribe",
            actionHandler: handleConfirmUnsubscribe
        },
        {
            actionLabel: "Edit Subscription",
            actionHandler: handleOpenEditSubscriptions
        },
        {
            actionLabel: "Audit History",
            actionHandler: handleOpenAuditHistory
        }
    ]

    setActionsConfig(actionsConfig)

    const { dob, expirationDate, fullName, rapbackId, ori, confirmation, status, startDate } = formState

    console.log('maintenance formState: ', formState)

    const updateSubscription = useCallback(async (row) => {
        console.log(`updateSubscription dob ${dayjs(dob).format("MM/DD/YYYY")}  expirationDate ${dayjs(expirationDate).format("MM/DD/YYYY")} `)
        setOpenDrawer(false)
        addAuditEvent("Subscription Edit Request Submitted", "Rapback Maintenance", new Date(), row.rapbackId)
        handleUpdateSubscription(rapbackId, dayjs(expirationDate).format("MM/DD/YYYY"), dayjs(dob).format("MM/DD/YYYY"))
    }, [formState])

    const [internalExpirationDate, setInternalExpirationDate] = useState<Dayjs | null>(dayjs(expirationDate))

    useEffect(() => {
        setInternalExpirationDate(dayjs(expirationDate))
    }, [expirationDate]);

    return (
        <>
            <Paper elevation={0} sx={
                {
                    ...pageStyles.pagePaperStyle,
                    maxWidth: `calc(100vw - ${expanded ? "256px" : "64px"})`
                }}>
                <CustomTableTitleAndAlert name="Maintenance" />

                <RightDrawer openDrawer={openAuditDrawer} closeDrawer={handleCancel}>
                    <Container sx={{ marginBottom: "18px" }} disableGutters>
                        <Typography sx={{ fontSize: '1.5rem' }}>{applicantName} Audit History</Typography>
                    </Container>
                    <AuditHistoryTable id={id} name="rb-sub-audit-history" />
                </RightDrawer>

                <RightDrawer openDrawer={openDrawer} closeDrawer={handleCancel}>
                    <Container sx={{ marginBottom: "24px", mt: '24px' }} disableGutters>
                        <Typography variant="h5">Edit Subscription</Typography>
                    </Container>
                    <EditSubscriptionForm formState={[formState, dispatchFormValues]}/>
                    <Button onClick={(row) => updateSubscription(row)} variant="contained" sx={{ width: "100%" }}>UPDATE</Button>
                </RightDrawer>
                <CustomTable
                    //Required props
                    tableName="rapback-maintenance"
                    loading={loading}
                    rows={data}
                    orderBy={orderBy}
                    order={sortOrder}
                    totalRecords={totalRecords}
                    currentPage={page}
                    pageSize={rowsPerPage}
                    searched={searched}
                    value={value}
                    handleRequestSort={handleRequestSort}
                    setValue={setValue}
                    handleClickShowSSN={handleClickShowSSN}
                    displaySSN={displaySSN}
                    setSearched={setSearched}
                    //Per page props
                    defaultSortColumn="startDate"
                />
                <ConfirmModal
                    customWidth={'600px'}
                    customHeight={'193px'}
                    title={confirmModalTitle}
                    content={confirmModalContent}
                    cancelButtonText={cancelButtonText}
                    confirmButtonText={confirmButtonText}
                    handleCancel={() => setOpenConfirmModal(false)}
                    handleConfirm={async (e) => {
                        switch (action) {
                            case "unsubscribe":
                                setOpenConfirmModal(false)
                                const resp = await handleUnsubscribe(internalRapbackId)
                                break;
                            /*case "edit_subscription":
                                handleOpenEditSubscriptions()
                                break;*/
                        }
                    }}
                    openDialog={openConfirmModal}
                />
            </Paper>{ }
        </>
    )
}
