/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { CustomTable } from 'common/CustomTable/CustomTable';
import { Paper } from '@mui/material'
import { CustomTableTitleAndAlert } from '../../common'
import { useExpanded } from '../../components/layouts/ProtectedLayout';
import { useAudit } from "../../hooks/useAudit";
import AgencyManagementService from "./agencyManagementService/agencyManagementService";
import { urls } from "../../urls";
import { useQueryAgencyManagement } from 'hooks';
import { AddNewAgencyModal, EditAgencyDrawer } from './components/index';
import { useUserPermissions } from 'contexts';
import { Order } from '../../customTypes/OrderType';
import { pageStyles } from 'customStyles/common';
import { useTableStore } from 'state/TableStore';

const mapAgencyToApi = (agency: any) => {
  console.log("debug rfp mapAgencyToApi 1agencyManagement agency: ", agency);
  return {
    id: agency.agencyORI,
    ori: agency.agencyORI,
    secondaryOri: agency.secondaryOri,
    reasonFingerPrinted: agency.reasonFingerPrinted,
    agency_name: agency.agencyName,
    distribution_method: agency.distributionMethod,
    agency_email: agency.agencyEmail,
    agency_address: agency.agencyAddress,
    agency_city: agency.agencyCity,
    agency_state: agency.agencyState,
    agency_zipcode: agency.agencyZipCode,
  };
};

export const AgencyManagement = () => {

  let fieldNames = [
    "agencyORI",
    "agencyName",
    "reasonFingerPrinted",
    "distributionMethod",
    "agencyEmail",
    "agencyAddress",
    "agencyCity",
    "agencyState",
    "agencyZipCode",
    "agencyStatus",
  ];

  const distributionMethodOptions: Array<Object> = [
    { label: "Applicant", value: "Applicant" },
    { label: "Agency", value: "Agency" },
    { label: "Both", value: "Both" },
    { label: "No Distribution", value: "No Distribution" },
  ];

  const georgiaAgencyUrl =
  {
    "dev": 'agency.us-west-2.dev.athenajps.us.idemia.io',
    "staging": 'agency.us-west-2.stg.athenajps.us.idemia.io',
    "gapreprod": 'agency.us-gov-west-1.gapreprod.ue.state.identogo.com',
    "ga": 'agency.us-gov-west-1.ga.state.identogo.com'
  }
  const STATE = process.env.REACT_APP_CONFIG_STATE!
  const STAGE = process.env.REACT_APP_STAGE!

  const { expanded } = useExpanded();
  //const [currentPage, setCurrentPage] = useState(1);
  //const [pageSize, setPageSize] = useState(25)
  const { addAuditEvent } = useAudit();
  const [data, setData] = useState<any[]>([]);
  const { permissions } = useUserPermissions();
  console.log('agency management permissions', permissions)
  let url;
  if (STATE === 'ga') {
    url = georgiaAgencyUrl[STAGE]
  } else {
    url = urls.AGENCY;
  }
  console.log('state is right here', STATE)
  console.log('stage is right here', STAGE)
  console.log('url is right here', url)
  const canAddAgencies = permissions.addNewAgencies
  const agencyManagementService = new AgencyManagementService(url);

  const [sortOrder, setSortOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('ori');
  const [tableFilters, setTableFilters] = useState<Object>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [newAgencyInfo, setNewAgencyInfo] = useState<any>({})
  const [openAgencyEdit, setOpenAgencyEdit] = useState<boolean>(false);
  const [selectedRowText, setSelectedRowText] = useState<any>({});
  const [updatedAgency, setUpdatedAgency] = useState<boolean>(false);
  const [addedAgency, setAddedAgency] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [oriExists, setOriExists] = useState<boolean>(false);
  const [rfpExists, setRfpExists] = useState<boolean>(false);
  const [canAddAgency, setCanAddAgency] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [touchedFields, setTouchFields] = useState({});
  const [alreadyExistError, setAlreadyExistError] = useState('');
  const [edited, setEdited] = useState<boolean>(false);
  const [searched, setSearched] = useState<boolean>(false);
  const [value, setValue] = useState<any>([]);
  const [previousAgencyData, setPreviousAgencyData] = useState<any>({});


  const openNotification = useTableStore((state) => state.openNotification)
  const setOpenNotification = useTableStore((state) => state.setOpenNotification)
  const setAlertTitle = useTableStore(state => state.setAlertTitle);
  const setAlertMessage = useTableStore(state => state.setAlertMessage);
  const setHeaderButtonConfig = useTableStore(state => state.setHeaderButtonConfig)
  const searchParams = useTableStore((state) => state.searchParams)
  const pagination = useTableStore((state) => state.pagination)
  const setPagination = useTableStore(state => state.setPagination)

  const { rowsPerPage, page } = pagination

  //useConfig()
  useEffect(() => {
    setPagination({ ...pagination, page: 1 })
    console.log(`debug visibleRows page ${page}, rowsPerPage: ${rowsPerPage}`)
  }, [])

  const {
    mappedData,
    totalRecords,
    countError,
    loading, }: any = useQueryAgencyManagement(
      rowsPerPage,
      page,
      sortOrder,
      orderBy,
      tableFilters,
      searchParams)


  useEffect(() => {
    console.log('mappedData: ', mappedData)
    setData(
      mappedData?.map(({ json_data: agency }: any, isActive: any) => {
        return {
          key: uuid(),
          id: agency.id,
          agencyORI: agency.ori,
          secondaryOri: agency.secondaryOri,
          reasonFingerPrinted: agency.reasonFingerPrinted,
          agencyName: agency.agency_name,
          distributionMethod: agency.distribution_method,
          agencyEmail: agency.agency_email,
          agencyAddress: agency.agency_address,
          agencyCity: agency.agency_city,
          agencyCounty: agency.agency_county,
          agencyState: agency.agency_state,
          agencyZipCode: agency.agency_zipcode,
          agencyStatus: agency.isActive ? 'Active' : 'Inactive'
        };
      })
    );
  }, [mappedData, mappedData?.length]);

  const handleInputChange = ((index: any, value: any) => {
    if (value !== '') {
      if (index === 'agencyEmail') {
        const emails = value.split(",");
        setNewAgencyInfo({
          ...newAgencyInfo,
          agencyEmail: emails
        });
      } else {
        setNewAgencyInfo({
          ...newAgencyInfo,
          [index]: value
        })
      }
      setTouchFields({})
    } else {
      delete newAgencyInfo[index]
    }
    data?.map((agency: any) => {
      if (agency.agencyORI !== newAgencyInfo.agencyORI) {
        setOriExists(false)
      }
      if (agency.reasonFingerPrinted !== newAgencyInfo.reasonFingerPrinted) {
        setRfpExists(false)
      }
    })
    if (!rfpExists && !oriExists) setAlreadyExistError('');
  });

  useEffect(() => {
    if (Object.keys(newAgencyInfo).length >= 8) {
      setCanAddAgency(true);
    } else {
      setCanAddAgency(false);
    }
    if (Object.values(selectedRowText).includes('')) {
      setEdited(true);
    } else {
      setEdited(false);
    }
  }, [newAgencyInfo])

  useEffect(() => {
    data?.map((agency: any) => {
      if (agency.agencyORI === newAgencyInfo.agencyORI) {
        setOriExists(true)
      }
      if (agency.reasonFingerPrinted === newAgencyInfo.reasonFingerPrinted) {
        setRfpExists(true)
      }
    })

    if (oriExists && rfpExists) {
      setAlreadyExistError('Agency ORI/RFP combination already exists')
      // setError('')
    } else {
      setAlreadyExistError('')
    }
  }, [oriExists, rfpExists])

  const handleCancel = () => {
    setNewAgencyInfo('');
    setOpenModal(false);
    setOpenAgencyEdit(false);
    setError('');
    setOriExists(false);
    setRfpExists(false);
    setAlreadyExistError('');
    setFieldError(false);
  }

  const headerButtonConfig = {
    handler: () => setOpenModal(true),
    headerButtonIcon: null,
    buttonLabel: "",
    disabled: !canAddAgencies,
    text: "ADD AGENCY"
  }

  setHeaderButtonConfig(headerButtonConfig)

  const submitNewAgency = async () => {
    try {
      const mappedAgency = mapAgencyToApi(newAgencyInfo);
      setData([newAgencyInfo, ...data]);
      console.log("debeug add email edit/save update: ", mappedAgency);
      agencyManagementService.createUpdateAgency(mappedAgency?.id, mappedAgency);
      setOpenModal(false);
      setOpenNotification(true);
      setAddedAgency(true);
      addAuditEvent("Add Agency", `Update data: ${JSON.stringify(newAgencyInfo)}`, new Date(), undefined);
      setAlertTitle('Success')
      setAlertMessage('A new agency was successfully created!')
      setNewAgencyInfo('');
      setError('')
    } catch (error) {
      console.log("Creating New Agency Failed:", error);
    }
  }

  const handleEditInput = ((index: any, value: any) => {
    if (value !== '') {
      if (index === 'agencyEmail') {
        const emails = value.split(",");
        setSelectedRowText({
          ...selectedRowText,
          agencyEmail: emails
        });
      } else {
        setSelectedRowText({
          ...selectedRowText,
          [index]: value
        })
      }
      setTouchFields({});
      setEdited(true);
      setFieldError(false);
      setError('');
    } else {
      if (index === 'agencyEmail') {
        setSelectedRowText({
          ...selectedRowText,
          agencyEmail: value
        })
      } else {
        setSelectedRowText({
          ...selectedRowText,
          [index]: value
        })
      }
    }
  })


  const closeDrawer = () => {
    setOpenAgencyEdit(false)
    setSelectedRowText(null);
    setError('');
    setEdited(false)
  }

  const submitEditedAgency = async (e: any, updates: any, key: any, isDelete: boolean = false) => {
    e.preventDefault();

    try {
      const updatedMappedAgency = mapAgencyToApi(selectedRowText);
      const previousMappedAgency = mapAgencyToApi(previousAgencyData);

      console.log('updatedMappedAgency', updatedMappedAgency)
      setData((prevData: any) => {
        return prevData.map((agency: any) => {
          if (agency.key === key) {
            return { ...agency, ...updates }
          }
          return agency
        })
      })
      agencyManagementService.createUpdateAgency(selectedRowText.agencyORI, updatedMappedAgency)
      setOpenAgencyEdit(false);
      setOpenNotification(true);
      setAlertTitle('Success')
      setAlertMessage('Agency record was successfully updated!')
      setError('')
      addAuditEvent("Update Agency", "update data: " + selectedRowText.agencyName + " email: " + selectedRowText.agencyEmail, new Date(), selectedRowText.key.toString(), undefined)
      console.log("debeug add email edit/save update: ", updatedMappedAgency);

      if (isDelete) {
        agencyManagementService.deleteAgency(previousAgencyData?.agencyORI);
      }
      addAuditEvent("Deleted previous Agency", "Delete data: " + previousAgencyData.agencyName + " email: " + previousAgencyData.agencyEmail, new Date(), previousAgencyData.key.toString(), undefined)
      console.log("debug removed previous agency data: ", previousAgencyData);
      addAuditEvent(
        "Update Current Agency",
        `Update data: ${JSON.stringify(updatedMappedAgency)}`,
        new Date(),
        undefined
      );
    } catch (error) {
      console.log('Agency update failed: ', error);
    }
  }

  const handleTextFieldError = (e: any, fieldName: any) => {
    if (e.target.value === '' && fieldName !== 'secondaryOri' && fieldName !== 'agencyEmail') {
      setTouchFields({
        [fieldName]: true
      })
      setFieldError(true);
      setError('This is a required field.');
      setAlreadyExistError('');
    }
  }

  console.log('debug data in agency:', mappedData);

  const handleRequestSort = (event: any, property) => {
    console.log(`custom table agency-management handleRequestSort current sort ${JSON.stringify({
      orderBy,
      sortOrder
    })}`)
    const isAsc = orderBy === property && sortOrder === 'asc';
    console.log(`custom table agency-management handleRequestSort new sort ${JSON.stringify({
      sortOrder: isAsc ? 'desc' : 'asc',
      orderBy: property
    })}`)
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    console.log('debug openNotification:', openNotification);
    setTimeout(() => {
      setOpenNotification(false);
      setUpdatedAgency(false);
      setAddedAgency(false);
    }, 5000)
  }, [openNotification])

  const handleEditAgency = ((e: any, row: any) => {
    e.stopPropagation()
    setOpenAgencyEdit(true)
    setSelectedRowText(row)
  })

  return (
    <>
      {
        permissions.viewAgencies
          ?
          <Paper elevation={0} sx={{ ...pageStyles.pagePaperStyle, minWidth: `calc(100vw - ${expanded ? "256px" : "64px"})` }}>
            <CustomTableTitleAndAlert name="Agency Management" />
            {permissions.editExistingAgencies ?
              <EditAgencyDrawer
                openAgencyEdit={openAgencyEdit}
                closeDrawer={closeDrawer}
                fieldNames={fieldNames}
                submitEditedAgency={submitEditedAgency}
                selectedRowText={selectedRowText}
                handleEditInput={handleEditInput}
                touchedFields={touchedFields}
                fieldError={fieldError}
                error={error}
                handleTextFieldError={handleTextFieldError}
                distributionMethodOptions={distributionMethodOptions}
                handleCancel={handleCancel}
                edited={edited}
              />
              : null
            }
            {permissions.addNewAgencies ?
              <AddNewAgencyModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                submitNewAgency={submitNewAgency}
                fieldNames={fieldNames}
                touchedFields={touchedFields}
                fieldError={fieldError}
                error={error}
                alreadyExistError={alreadyExistError}
                oriExists={oriExists}
                rfpExists={rfpExists}
                handleTextFieldError={handleTextFieldError}
                handleInputChange={handleInputChange}
                distributionMethodOptions={distributionMethodOptions}
                handleCancel={handleCancel}
                canAddAgency={canAddAgency}
              />
              : null
            }
            <CustomTable
              //Required props
              tableName="agency-management"
              loading={loading}
              rows={data}
              orderBy={orderBy}
              order={sortOrder}
              totalRecords={totalRecords}
              currentPage={page}
              pageSize={rowsPerPage}
              searched={searched}
              value={value}
              handleRequestSort={handleRequestSort}
              setValue={setValue}
              setSearched={setSearched}
              //Per page props        
              defaultSortColumn="ori"
              headerButtonAriaLabel='ADD AGENCY'
              headerButtonText="ADD AGENCY"
              rowClickHandler={STATE !== "ga" ? handleEditAgency : () => { }}
              openAgencyEdit={openAgencyEdit}
              openModalBlur={openModal}
            />
          </Paper>
          :
          null
      }
    </>
  )
}