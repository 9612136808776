/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useMemo, useRef, useState } from "react";
import { Config, HeaderCells, UserRoles } from "interfaces/config";
import { useLocation } from "react-router-dom";
import { ColumnState, useTableStore } from "state/TableStore";
import { columnVisibilityReducer } from "../state/reducers/columnVisibilityReducer";
import { usePreviousValue } from "./usePreviousValue";
import { toBeRequired } from "@testing-library/jest-dom/matchers";
import { LogConfig, logger } from "../utils";
import { useLocalStorage } from "../common";

const extractPageName = (pathname: string): string => {
    const segments = pathname.split('/').filter(Boolean);
    const index = segments.indexOf('app');
    if (index !== -1 && index + 1 < segments.length) {
        return segments[index + 1];
    } else {
        return '';
    }
};

export const useConfig = () => {
    const [config, setConfig] = useState<Config>({});
    const [headerCells, setHeaderCells] = useState<HeaderCells>([]);
    const [userRoles, setUserRoles] = useState<UserRoles>([]);
    const [useQueryPagination, setUseInternalPagination] = useState();
    const [columnVisibility, setColumnVisibility] = useState({})
    const [filters, setFilters] = useState<any>([])
    const [filter, setFilter] = useState<boolean>(true)
    const [manageColumns, setManageColumns] = useState<boolean>(true)
    const [transactionViewing, setTransactionViewing] = useState<{ hardLimit?: number, softLimit?: number, extendDays?: number }>({})

    const setHeaderButtonConfig = useTableStore((state) => state.setHeaderButtonConfig);
    const setAdvancedSearch = useTableStore(state => state.setAdvancedSearch);
    const setColumnState = useTableStore(state => state.setColumnState)
    const setLoadingConfig = useTableStore(state => state.setLoadingConfig)
    const columnState: ColumnState = useTableStore(state => state.columnState)
    const locationObj = useLocation();
    const pageName = extractPageName(locationObj.pathname);

    const STATE = process.env.REACT_APP_CONFIG_STATE!;
    const configLoaded = useTableStore(state => state.configLoaded)
    const setConfigLoaded = useTableStore(state => state.setConfigLoaded)

    const [storedValue, setPageLoaded] = useLocalStorage("pageLoaded")
    const getConfig = async () => {
        try {
            const response = await fetch('../../config.json');
            const data = await response.json();
            const pages = Object.keys(data.states[STATE].pages)

            setConfig(data);
            setLoadingConfig(true)

            if (pages.includes(pageName)) {
                let headerCells = data.states[STATE].pages[pageName].headerCells;
                setHeaderCells(headerCells);
                
                let transactionViewing = data.states[STATE].transactionViewing
                if (transactionViewing) setTransactionViewing(transactionViewing)
                
                    let userRoles = data.states[STATE].cognito.userRoles;
                setUserRoles(userRoles);
                
                let advancedSearch = data.states[STATE].pages[pageName].advancedSearch;
                setAdvancedSearch(advancedSearch);
                
                let columnVisibility = data.states[STATE].pages[pageName].columnVisibility
                setColumnVisibility(columnVisibility)

                let filterList = data.states[STATE].pages[pageName].filters
                setFilters(filterList)

                let filter: boolean
                let manageColumns: boolean
                if (pageName != "applicant-details") {
                    filter = data.states[STATE].pages[pageName].tableTools.filter
                    setFilter(filter)
                    manageColumns = data.states[STATE].pages[pageName].tableTools.manageColumns
                    setManageColumns(manageColumns)
                }

                if (!configLoaded.includes(pageName) && pageName != "applicant-details") {

                    setColumnState({
                        ...columnState,
                        page: {
                            ...columnState?.page,
                            [pageName]: {
                                columnVisibility: {
                                    ...data.states[STATE].pages[pageName].columnVisibility,
                                },
                                filters: [
                                    ...data.states[STATE].pages[pageName].filters
                                ]
                            }
                        }

                    })
                }
            }
            setLoadingConfig(false);
            if (response.ok) {
                setPageLoaded([pageName])

            }
        } catch (err) {
            console.error(err);
        }

    };

    let pageRef = useRef(pageName)

    useEffect(() => {
        getConfig()
        setConfigLoaded([...configLoaded, pageName])
    }, [pageName])

    return { config, headerCells, userRoles, columnVisibility, filter, manageColumns, filters, transactionViewing }
};