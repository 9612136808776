/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect } from 'react'
import { urls } from '../urls'
import { useTableStore } from 'state/TableStore';

type ConfigServiceState = {
    searchData: any, 
    alertData: any, 
    loadingConfig: boolean, 
    postedConfig: boolean, 
    errorConfig: boolean
}

export function useConfigService(fileName: String, configBody: any, isGet: Boolean, isPost: Boolean, isDelete: Boolean) {
    const configService = useTableStore((state) => state.configService)
    const setConfigService = useTableStore((state) => state.setConfigService)
    const { searchData, alertData, loadingConfig, postedConfig, errorConfig } = configService
    const configUrl = urls.CONFIG;
    const controller = new AbortController();
    const { signal } = controller;
    const fetchUrl: string = `${process.env.NODE_ENV === 'production' ? configUrl : ""}/config/file/${fileName}`;
    const fetchFile: string = `${process.env.NODE_ENV === 'production' ? configUrl : ""}/config/file/static/rfpList.json`;

    useEffect(() => {
        console.log(`debug config service: useConfigService useEffect configFile: ${fileName} isGet: ${isGet} isPost: ${isPost} configUrl: ${configUrl}, configBody: ${JSON.stringify(configBody)}`)
        
        if (isGet && !isPost) {
            const fetchRfpList = async () => {
                try {
                    setConfigService({...configService, loadingConfig: true, errorConfig: true})
                    const resp = await (await fetch(fetchFile, {
                        method: "GET",
                        credentials: "include",
                        signal
                    })).json();
    
                    setConfigService({...configService, rfpListData: resp.value, loadingConfig: false, errorConfig: false})
                } catch (err: any) {
                    console.log(`Error getting ${fileName} from S3: `, err)
                }
            }
            const fetchConfigFile = async () => {
                try {
                    setConfigService({...configService, loadingConfig: true, errorConfig: true})
                    
                    const resp = await (await fetch(fetchUrl, {
                        method: "GET",
                        credentials: "include",
                        signal
                    })).json();
                    const parsedResp = JSON.parse(resp.value)
                    if (fileName === 'sticky-settings.json') {
                        setConfigService({...configService, searchData: parsedResp, loadingConfig: false, errorConfig: false})
                    }
                    if (fileName === 'alert-closed.json') {
                        setConfigService({...configService, alertData: parsedResp, loadingConfig: false, errorConfig: false})
                    }
                    console.log(`debug config service: Successfully fetched ${fileName} from S3`)
                } catch (err: any) {
                    console.log(`Error getting ${fileName} from S3: `, err)
                }
               
            };
             
            fetchConfigFile();
            if (fileName === "rfpList.json") {
                fetchRfpList();
            }
        } else if (isPost) {
            const updateConfigFile = async ()  => {
                try {
                    const signedUrlResp = await (await fetch(fetchUrl, {
                        method: "POST",
                        credentials: "include",
                        signal,
                        body: JSON.stringify(configBody)
                    })).json();
                    if (signedUrlResp.url) {
                        console.log(`debug config service: gotSigneUrlResp url ${signedUrlResp.url}`)
                        const resp = await fetch(signedUrlResp.url, {
                            method: "PUT",
                            headers: {
                            "Content-Type": "application/json",
                            },
                            body: JSON.stringify(configBody)
                        });
                        console.log(`debug config service: Successfully uploaded ${fileName} to S3 with response:`, await resp);
                        if (resp.ok && !isDelete) {
                            setConfigService({...configService, postedConfig: true})
                        }    
                    }
                } catch (err: any) {
                    console.log(`Error posting ${fileName}: `, err)
                }
            }
            updateConfigFile();
        }

    }, [isGet, isPost, configUrl, configBody, fileName])

    return { searchData, alertData, loadingConfig, errorConfig, postedConfig };
}