/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

class AgencyManagementService {
    private agency: string

    constructor(baseUrl: string) {
        this.agency = baseUrl
    }
    async getAgencyCount() {
        const endpoint = `${process.env.NODE_ENV === 'production' ? this.agency: ""}/agency/count`;
        const options: RequestInit = {
            method: "GET",
            credentials: "include",
        };

        try {
            const response = await (await fetch(endpoint, options)).json();
            return response;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    }

    async getAllAgencies(count: string, page: string) {
        const endpoint = `${process.env.NODE_ENV === 'production' ? this.agency: ""}/agency?count=${count}&page=${page}&isActive=true`;
        const options: RequestInit = {
            method: "GET",
            credentials: "include",
        };

        try {
            const response = await (await fetch(endpoint, options)).json();
            console.log('response', response);
            return response;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    }

    async getAgency(agencyId: string) {
        const endpoint = `${process.env.NODE_ENV === 'production' ? this.agency: ""}/agency/${agencyId}?isOri=false`;
        const options: RequestInit = {
            method: "GET",
            credentials: "include",
        };

        try {
            const response = await (await fetch(endpoint, options)).json();
            return response;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    }

    async createUpdateAgency(agencyId: string, agency: any) {
        console.log('createupdated agency agencyid: ', agencyId)
        const endpoint = `${process.env.NODE_ENV === 'production' ? this.agency: ""}/agency/${agencyId}`;
        const body = {
            id: agencyId,
            isActive: true,
            json_data: agency,
        };

        console.log('body.json_data1231232', body.json_data);

        const options: RequestInit = {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(body),
        };

        try {
            const response = await (await fetch(endpoint, options)).json();
            return response;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    }

    async deleteAgency(agencyId: string) {
        const endpoint = `${process.env.NODE_ENV === 'production' ? this.agency: ""}/agency/${agencyId}`;

        const options: RequestInit = {
            method: "DELETE",
            credentials: "include",
        };

        try {
            const response = await (await fetch(endpoint, options)).json();
            return response;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    }
}

export default AgencyManagementService;