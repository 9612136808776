import React from "react"
import { Button, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material'
import { ExtendProps } from '@propTypes/index';
import { TransactionSummaryStyles } from "../../../customStyles/index";
import { useTableStore } from "state";
import dayjs from "dayjs";
import { Config } from "interfaces/config";

export const ExtendModal: React.FC<ExtendProps> = ({ row, open, handleClose, handleExtend, data, config }) => {
  console.log('extend modal component')
  console.log('data coming through', data)
  let date;
  const STATE = process.env.REACT_APP_CONFIG_STATE!
  const { hardLimit, softLimit, extendDays } = config.states?.[STATE].transactionViewing;
  const applicantName = useTableStore(state => state.applicantName);
  const setApplicantName = useTableStore(state => state.setApplicantName);
  const transactionDate = useTableStore(state => state.transactionDate)
  
  let hardLimitDate = dayjs(transactionDate).add(hardLimit, 'days')
  let todayPlusExtension = dayjs().add(extendDays, "days").format("LL")
  let totalDays = dayjs(todayPlusExtension).diff(transactionDate, 'days')

  if (totalDays < hardLimit) {
    date = todayPlusExtension
  } else {
    date = hardLimitDate
  }

  console.log('date in extend modal', date)
  console.log('date in extend modal transaction', dayjs(transactionDate).format("LL"))

  return (
    <Dialog open={open} fullWidth={true} PaperProps={{ sx: TransactionSummaryStyles.extendModalDialog }}>
      <DialogTitle>Extend Access to Applicant?</DialogTitle>
      <DialogContent sx={TransactionSummaryStyles.extendModalContent}>
        <Typography variant="body1">
          Access to {applicantName} will be extended through {date}. Would you like to continue?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: '1rem 1.5rem', gap: '0.5rem' }}>
        <Button onClick={() => handleClose()} variant="outlined">CANCEL</Button>
        <Button onClick={() => handleExtend()} variant="contained">Yes, Extend</Button>
      </DialogActions>
    </Dialog>
  )
}