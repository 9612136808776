import React from 'react'
import { Grid, CircularProgress, Container } from '@mui/material'
import { useAuditHistory } from 'hooks';
import { CustomTable } from "common";
import { useTableStore } from 'state/TableStore';

export const AuditHistoryTable: React.FC<any> = ({ id, name }) => {
    const { auditMappedData, auditLoading, totalAuditRecords } = useAuditHistory(id)
    const multiTablePagination = useTableStore((state) => state.multiTablePagination)

    console.log('debug audit history table pagination: ', multiTablePagination);

    const notesLoadingContainer = {
        display: 'flex',
        padding: '1.5rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        alignSelf: 'stretch',
        backgroundColor: name !== "rb-sub-audit-history" || name !== "rb-summary-audit-history" ? 'common.black' : 'background.default'
    }

    if (auditLoading) {
        return (
            <Grid sx={notesLoadingContainer}>
                <CircularProgress />
            </Grid>
        );
    }

    console.log('Audit History mapped data: ', auditMappedData);

    return (
        <Container disableGutters maxWidth={false} sx={{ width: "100%" }}>
            <CustomTable
                tableName={name}
                columnState={
                    {
                        columnVisibility: {
                            operator: true,
                            location: true,
                            action: true,
                            date: true
                        },
                        filterStatus: {}
                    }
                }
                savedSearchState={[{}, () => { }]}
                pageSize={multiTablePagination[name].detailRowsPerPage}
                totalRecords={totalAuditRecords}
                currentPage={multiTablePagination[name].detailPage}
                hideSearch={true}
                defaultSortColumn="date"
                rows={auditMappedData?.length ? auditMappedData : [{ action: "NO DATA" }]}
            />
        </Container>
    )
}