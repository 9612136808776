import React, { useEffect, useReducer, useState } from 'react';
import { InputWrapper } from '.'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  AlertTitle,
} from '@mui/material'
import { useMUITheme } from "../../../theme/ThemeProvider";
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs'
import { AdvancedSearchAction, SavedSearchActionTypes } from '../../../customEnums/index';
import { CreateSearchProps } from 'interfaces/pages/components';
import { TransactionSummaryStyles } from '../../../customStyles/index';
import { AlertStyles } from '../../../customStyles/common';
import { useTableStore } from 'state/TableStore';
import {getCurrentEnv, logger} from "../../../utils";
import { States } from 'interfaces/common';

enum ModalActionTypes {
  CHANGE_LABEL_NAME = "CHANGE_LABEL_NAME",
}

const initialModalState = {
  labelName: ""
}

const modalReducer = (currentState: any, { type, payload }: any) => {

  switch (type) {
    case ModalActionTypes.CHANGE_LABEL_NAME:
      const { labelName } = payload;
      return {
        ...currentState,
        labelName
      };
    default: throw new Error('Invalid action type');
  }
};

export const AdvancedSearch: React.FC<CreateSearchProps> = (
  { filters,
    formState,
    setFormValues,
    handleRunSearch,
    setCreateSearch,
    usersPermissions,
    savedSearchState,
    postedConfig,
    displaySavedMessage,
    setDisplaySavedMessage,
    transactionViewing
  }) => {
  const { mode } = useMUITheme();
  const isDark = mode === 'dark';
  const [savedSearches, dispatchSearch] = savedSearchState;
  const [modalState, dispatch] = useReducer(modalReducer, initialModalState);
  const { labelName } = modalState;
  const [empty, setEmpty] = useState(true)
  const configService = useTableStore(state => state.configService)
  const setConfigService = useTableStore(state => state.setConfigService)
  const setIsDelete = useTableStore((state) => state.setIsDelete)
  const setConfigFile = useTableStore((state) => state.setConfigFile)
  const setConfigBody = useTableStore((state) => state.setConfigBody)
  const setIsPost = useTableStore((state) => state.setIsPost)
  const setInternalSearchArray = useTableStore(state => state.setInternalSearchArray)
  const STATE = getCurrentEnv

  // Display success message when posting config, defined in the config service hook, is set to true
  useEffect(() => {
    if (postedConfig) {
      setDisplaySavedMessage(postedConfig)
    }
  }, [postedConfig]);

  const onLabelNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ModalActionTypes.CHANGE_LABEL_NAME, payload: { labelName: e.target.value } });
  };

  const handleSavedSearch = async () => {
    dispatchSearch(
      {
        type: SavedSearchActionTypes.ADD_SAVED_SEARCH,
        savedSearch: {
          label: labelName,
          searchParams: formState[0]
        },
        setters: {
          setIsDelete,
          setConfigFile,
          setConfigBody,
          setIsPost,
          setInternalSearchArray
        }
      }
    );
    console.log('this is the function being invoked ')
    setFormValues({});
  };

  const { firstName, lastName, status, transactionType, lowerDate, upperDate, reasonFingerprinted, stateRFPCode, days } = formState[0]
  const dispatchFormValues = formState[1]

  useEffect(() => {
    console.log('savedsearchstate', savedSearchState)
    console.log('createSearch reset formState component lastName: ', formState)
    const searchParams = formState[0]
    console.log('createSearch searchParams: ', searchParams)
    const searchParamsKeys = Object.keys(searchParams)
    if (searchParamsKeys.includes("days")) {
      if (searchParams.days === transactionViewing.hardLimit!.toString()) {
        console.log('createSearch set empty false')
        setEmpty(false)
      } else {
        Object.keys(searchParams).map((key: string) => {
          if (key !== "days") {
            console.log(`createSearch set empty to ${searchParams[key] === ''}`)
            setEmpty(searchParams[key] === '')
          }
        })
      }
    } else {
      Object.keys(searchParams).map((key: string) => {

        setEmpty(searchParams[key] === '')

      })
    }

  }, [formState])

  console.log('debug select render AdvancedSearch')

  const debugExtend = {
    identifier: "debug extendView",
    pageName: "transaction-summary",
    thingToDebug: "extend",
    paramsToLog: {
      days,
      transactionViewing,
      toggleChecked: days === transactionViewing.hardLimit!.toString()
    }
  }
  // logger(debugExtend)

  let statusTypes = filters.find((filter) => filter.filterColumn === "status")!.filterValues

  return (
    <Dialog fullWidth={true} open={true} PaperProps={{ sx: TransactionSummaryStyles.createSearchDialog }}>
      <DialogTitle variant='h6'>SEARCH PARAMETERS</DialogTitle>
      <DialogContent sx={TransactionSummaryStyles.createSearchDialogContent}>
        <Typography variant='body1' sx={{ width: "100%", height: "40px", mb: '0.75rem' }}>
          Define one or more search parameters and select 'Run Search'. Otherwise, save  your search as a template or exit without searching.
        </Typography>
        <InputWrapper>
          <TextField
            label="First Name"
            name="firstName"
            value={firstName}
            placeholder="First Name"
            variant="outlined"
            sx={TransactionSummaryStyles.createSearchTextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchFormValues({ type: AdvancedSearchAction.FIRST_NAME, value: e.target.value })}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={lastName}
            placeholder="Last Name"
            variant="outlined"
            sx={TransactionSummaryStyles.createSearchTextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchFormValues({ type: AdvancedSearchAction.LAST_NAME, value: e.target.value })}
          />
        </InputWrapper>

        <InputWrapper>
          <FormControl>
            <InputLabel color="secondary">Status</InputLabel>
            <Select
              onChange={(e: any) => dispatchFormValues({ type: AdvancedSearchAction.STATUS, value: e.target.value })}
              variant="outlined"
              sx={TransactionSummaryStyles.createSearchTextField}
              label="Status">
              {
                Object.keys(statusTypes).map((key) => {
                  return (
                      <MenuItem value={statusTypes[key].label}>{statusTypes[key].label}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Transaction Type</InputLabel>
            <Select
              onChange={(e: any) => dispatchFormValues({ type: AdvancedSearchAction.TYPE, value: e.target.value })}
              sx={TransactionSummaryStyles.createSearchTextField}
              label="Transaction Type"
            >
              <MenuItem value={"MEWAPP"}>MEWAPP</MenuItem>
              <MenuItem value={"MENOADD"}>MENOADD</MenuItem>
              <MenuItem value={"NFUF"}>NFUF</MenuItem>
              <MenuItem value={"MEWADD"}>MEWADD</MenuItem>
              <MenuItem value={"MAP"}>MAP</MenuItem>
            </Select>
          </FormControl>
        </InputWrapper>
        <InputWrapper>
          <DatePicker
            maxDate={upperDate || dayjs()}
            minDate={days === "90" ? dayjs().subtract(90, 'days') : dayjs().subtract(30, 'days')}
            value={lowerDate}
            label="Lower date"
            slotProps={{
              textField: {
                placeholder: "MM / DD / YYYY",
              },
            }}
            sx={TransactionSummaryStyles.createSearchTextField}
            onChange={(newValue) => dispatchFormValues({ type: AdvancedSearchAction.LOWER_DATE, value: newValue })}
          />
          <DatePicker
            minDate={lowerDate}
            maxDate={dayjs()}
            value={upperDate}
            label="Upper date"
            slotProps={{
              textField: {
                placeholder: "MM / DD / YYYY",
              },
            }}
            sx={TransactionSummaryStyles.createSearchTextField}
            onChange={(newValue) => dispatchFormValues({ type: AdvancedSearchAction.UPPER_DATE, value: newValue })}
          />

          {/*
            May add expiry range within at some point in the future
            <FormControl>
              <InputLabel>Expiry Range Within</InputLabel>
              <Select sx={{width: "418px", height: "56px"}} label="Expiry Range Within"></Select>

            </FormControl>*/}
        </InputWrapper>
        {
          usersPermissions.viewAdvancedSearchExtend && (
            <Container disableGutters sx={TransactionSummaryStyles.createSearchSwitchContainer}>
              <Switch
                checked={days === transactionViewing.hardLimit!.toString() ? true : false}
                color='secondary'
                sx={{
                  '.MuiSwitch-track ': {
                    backgroundColor: '#10111C'
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatchFormValues({ hardLimit: transactionViewing.hardLimit, type: AdvancedSearchAction.DAYS, value: e.target.checked })}
              />
              {/*Make value 30 day window value configureable*/}
              <Typography>Enable searching of records beyond the default 30 day window</Typography>
            </Container>
          )
        }


        <FormControl sx={{ flexGrow: 1, height: "56px" }}>
          <TextField label={STATE === States.GA ? "Statute" : "Reason Fingerprinted"} name="reasonFingerprinted" value={STATE === States.GA ? stateRFPCode : reasonFingerprinted} onChange={(e: any) => dispatchFormValues({ type: AdvancedSearchAction.RFP, value: e.target.value })} placeholder="Reason Fingerprinted" variant="outlined" sx={{ width: "100%", height: "56px" }}></TextField>

          {/*
       Removing until we can populate this from some service
       <InputLabel sx={{ color: theme.palette.text.primary }}>Reason Finger Printed</InputLabel>
        <Select value={reasonFingerprinted} onChange={(e: any) => dispatchFormValues({type: AdvancedSearchAction.RFP, value: e.target.value})} sx={{ flexGrow: 1, height: "56px" }} label="Reason Finger Printed">
          <MenuItem value={"VISA APPLICANT"}>VISA APPLICANT</MenuItem>
          <MenuItem value={"NCPA/VCA VOLUNTEER"}>NCPA/VCA VOLUNTEER</MenuItem>
          <MenuItem value={"Firearms"}>Firearms</MenuItem>
    </Select>*/}

        </FormControl>
        <Divider />

        <Container disableGutters sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: "61px", width: "100%" }}>
          <Typography sx={{ width: "100%" }} variant="h6" color="text.primary">SAVE YOUR SEARCH</Typography>
          <Typography sx={{ width: "100%" }} variant='body1'>Save your search as a template. Search templates will be added to the 'Search' field.</Typography>
        </Container>

        {displaySavedMessage && (
          <Alert severity="success" variant='standard' sx={{ ...AlertStyles.success, width: '100%', display: 'flex' }}>
            <AlertTitle sx={{ color: 'success.contrastText' }}>
              Search Saved!
            </AlertTitle>
            <Typography color='success.contrastText' variant='body2'>
              This saved search has been added to the "Search" field.
            </Typography>
          </Alert>
        )}


        <TextField
          label="Saved Search Name"
          sx={{ height: "56px", width: "100%" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onLabelNameChange(e)}
          placeholder="Saved search name"
        />
        <Button
          disabled={labelName.length > 0 && !empty ? false : true}
          onClick={() => handleSavedSearch()}
          variant="contained"
          color='secondary'
          sx={TransactionSummaryStyles.createSearchSaveButton}>
          Save Search
        </Button>
      </DialogContent>
      <DialogActions sx={{ p: '1rem 1.5rem' }}>
        <Button
          onClick={() => {
            setConfigService({ ...configService, postedConfig: false })
            //setPostedConfig(false);
            setCreateSearch(false);
            setFormValues({});
            setDisplaySavedMessage(false);
            dispatchFormValues({ type: AdvancedSearchAction.RESET });
          }}
          variant="outlined"
          color='secondary'
          sx={{ ...TransactionSummaryStyles.createSearchButtons, color: isDark ? '#fff' : null }}>
          CANCEL
        </Button>
        <Button
          disabled={empty}
          onClick={() => handleRunSearch()}
          variant="contained"
          color='secondary'
          sx={{ ...TransactionSummaryStyles.createSearchButtons, width: "138px" }}
        >
          RUN SEARCH
        </Button>
      </DialogActions>
    </Dialog>
  )
}