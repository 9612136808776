
import { ColumnStateActions } from "../../customEnums/ColumnState";

const setAllValues = (subState: any, value: boolean) => {
    Object.keys(subState).map((key: string) => {
        subState[key] = value
    })
}

const setAllFilterValues = (subState: any, value: boolean) => {
    Object.keys(subState).map((key: string) => {
        subState[key].selected = value
    })
}


export const columnVisibilityReducer = (state: any, action: any): any => {
    console.log('101011011010 debug columnStateReducer running STATE: ', state)
    console.log('101011011010 debug columnStateReducer running ACTION: ', action)

    const { columnVisibility, filterStatus } = state.columnState.page[action.page]

    console.log('state.columnState.page[action.page]', state.columnState.page[action.page].filters);

    const updatedFilters = state.columnState.page[action.page].filters?.map((filter) => {
        if (filter.filterColumn === action.column) {
            return {
                ...filter,
                filterValues: {
                    ...filter.filterValues,
                    [action.filter]: {
                        ...filter.filterValues[action.filter],
                        selected: !filter.filterValues[action.filter].selected
                    }
                }
            };
        } else {
            return filter;
        }
    });

    switch (action.type) {
        case ColumnStateActions.SHOW_ALL_COLUMNS:
            console.log('debug columnState reducer debug in SHOW_ALL_COLUMNS action case state: ', state)
            setAllValues(columnVisibility, true)

            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                        }
                    }
                }

            }

        case ColumnStateActions.SHOW_ALL_FILTERS:
            setAllFilterValues(filterStatus.filters, true)
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                        }
                    }
                }

            }

        case ColumnStateActions.HIDE_ALL_COLUMNS:
            setAllValues(columnVisibility, false)
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                        }
                    }
                }

            }

        case ColumnStateActions.HIDE_ALL_FILTERS:
            setAllFilterValues(filterStatus.filters, false)
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                        }
                    }
                }

            }

        case ColumnStateActions.RESET_COLUMNS:
            console.log(`debug TableTools filter column ${action.dataIndex} reducer reset ran`)
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            columnVisibility: {
                                ...action.columnVisibility
                            }
                        }
                    }
                }


            }

        case ColumnStateActions.RESET_FILTERS:
            console.log('101011011010 debug reset filters return: ', {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],

                            filters: [
                                ...action.filters
                            ]


                        }
                    }
                }

            })
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],

                            filters: [
                                ...action.filters
                            ]


                        }
                    }
                }

            }

        case ColumnStateActions.TOGGLE_FILTER:
            console.log(`debug TableTools filter column reducer: `, columnVisibility)
            console.log(`debug TableTools filter column reducer: `, filterStatus)
            console.log('debug TableTools filter column reducer: ', action)
            console.log('debug TableTools filter column reducer return: ', {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            filters: updatedFilters
                        }
                    }
                }
            })



            //return
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            filters: updatedFilters
                        }
                    }
                }
            }
        case ColumnStateActions.TOGGLE_COLUMN:
            console.log(`debug TableTools filter column ${action.dataIndex} reducer: `, columnVisibility)
            console.log(`debug TableTools filter column ${action.dataIndex}  reducer: `, filterStatus)
            console.log(`debug TableTools filter column ${action.dataIndex}  reducer: `, action)
            console.log(`debug TableTools filter column ${action.dataIndex}  reducer return: `, {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            columnVisibility: {
                                ...state.columnState.page[action.page].columnVisibility,
                                [action.dataIndex]: !state.columnState.page[action.page].columnVisibility[action.dataIndex]
                            }
                        }
                    }
                }

            })
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            columnVisibility: {
                                ...state.columnState.page[action.page].columnVisibility,
                                [action.dataIndex]: !state.columnState.page[action.page].columnVisibility[action.dataIndex]
                            }
                        }
                    }
                }

            }

    }

}