/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState } from 'react';
import {
    Tabs,
    Tab,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    Divider
} from '@mui/material';
import { ApplicantDetailsForm } from './ApplicantDetailsForm/ApplicantDetailsForm';
import { Notes } from 'components/Notes/Notes';
import Results from './Results/Results';
import { ApplicantDetailsActionTypes, useApplicantDetailsContext } from 'pages/ApplicantDetails/components/ApplicantDetailsContext/ApplicantDetailsProvider'
import { Auth } from 'aws-amplify';
import { usePdfURL } from 'hooks/usePdfURL';
import { useMUITheme } from "theme/ThemeProvider";
import { formatDate } from "utils/formatDate";
import { ApplicantDetailsBodyProps } from 'interfaces/pages/index';
import { AuditHistoryTable } from 'common/AuditHistoryTable';

const formatResults = (results: any, status: string, addedDate: string, fileName: string) => {
    console.log('formatResults results: ', results)
    console.log('formatResults status: ', status)
    console.log('formatResults addedDate: ', addedDate)
    return results?.map((result: any) => {
        return {
            key: result,
            decision: status ?? "N/A",
            addedBy: result.addedBy ?? "N/A",
            addedDate: addedDate ?? "N/A",
            fileNameControl: fileName ? fileName : 'Adjudication.pdf',
            fileLink: result,
            deleteControl: 'delete'
        }
    });
};

export const ApplicantDetailsBody: React.FC<ApplicantDetailsBodyProps> = ({
    userRole,
    personalInfo,
    transactionalInfo,
    applicantFetchStatus,
    statusHistories,
    registrationId,
    results,
    aggregatedResponses,
    rapSheets,
    resultsResponses,
    usersPermissions
}) => {

    console.log('debug transactionalInfo', transactionalInfo);
    console.log('debug results results: ', results)
    console.log('debug Aggregated responses: ', aggregatedResponses)

    const { applicantDetailsDispatch } = useApplicantDetailsContext();
    const { pdfURLs } = usePdfURL(registrationId as string);
    const data = formatResults(pdfURLs, transactionalInfo.currentStatus, transactionalInfo.lastModifiedBy, transactionalInfo.fileName);
    console.log('adjudication documents data: ', data)
    
    const { mode } = useMUITheme();
    const [currentUser, setCurrentUser] = useState<any>()

    const [value, setValue] = useState(0);
    const [editRedactedMode, setEditRedactedMode] = useState<boolean>(false);
    const [textChanges, setTextChanges] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false)
    const [moveTabs, setMoveTab] = useState(1)

    const mappedData = data?.map((value: any) => ({
        ...value,
        addedDate: formatDate(value.addedDate),
        registrationId: registrationId
    }))

    const handleCloseToSave = () => {
        setOpen(false)
        setTextChanges(false)
        setEditRedactedMode(true)
    }

    const handleChange = (event?: any, newValue?: any, key?: any) => {
        if (editRedactedMode && textChanges) {
            setOpen(true)
            setMoveTab(newValue);
        } else {
            setEditRedactedMode(false);
            setValue(newValue);
        }
        applicantDetailsDispatch({ type: ApplicantDetailsActionTypes.UPDATE_TAB, payload: { currentTab: key } })
    };

    const handleCloseWithoutSaving = () => {
        setOpen(false)
        setTextChanges(false)
        setEditRedactedMode(false)
        setValue(moveTabs)
    }

    const getUserInfo = async () => {
        let res = await Auth.currentUserInfo()
        if (res) setCurrentUser(res.username)
    }

    const renderTabs = (items) => {
        if (!usersPermissions['createNewNotes'] && !usersPermissions['viewOthersNotes']) {
            return items.slice(0, -2).map((item, index) => <Tab key={index} label={item.label} />);
        }
        if (!usersPermissions['viewUserHistory']){
            return items.slice(0, -1).map((item, index) => <Tab key={index} label={item.label} />);
        }
        else {
            return items.map((item, index) => <Tab key={index} label={item.label} />);
        }
    }

    getUserInfo()

    let items = [
        {
            label: "DETAILS",
            key: 'DETAILS',
            children: statusHistories && (
                <ApplicantDetailsForm
                    usersPermissions={usersPermissions}
                    userRole={userRole}
                    personalInfo={personalInfo}
                    transactionalInfo={transactionalInfo}
                    applicantFetchStatus={applicantFetchStatus}
                    adjudicationDocuments={mappedData}
                    statusHistories={statusHistories} />
            )
        },
        {
            label: 'RESULTS',
            key: 'RESULTS',
            children:
                <>
                    <Dialog open={open} onClose={() => setOpen(false)}>
                        <DialogTitle sx={{ width: '37.5rem' }}>YOU HAVE UNSAVED CHANGES.</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography>
                                    You are leaving the redacted document with unsaved changes. If you proceed, all changes that are not saved will be lost. Would you like to continue?
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: '1.5rem', pb: '1rem' }}>
                            <Button variant='outlined' color='primary' sx={{ color: mode === 'dark' ? '#fff' : null }} onClick={handleCloseToSave}>CANCEL AND RETURN</Button>
                            <Button variant='contained' color='primary' onClick={handleCloseWithoutSaving}>EXIT WITHOUT SAVING</Button>
                        </DialogActions>
                    </Dialog>
                    <Results
                        setEditRedactedMode={setEditRedactedMode}
                        editRedactedMode={editRedactedMode}
                        setTextChanges={setTextChanges}
                        open={open}
                        setOpen={setOpen}
                        setValue={setValue}
                        textChanges={textChanges}
                        tabValue={value}
                        userRole={userRole}
                        results={results}
                        aggregatedResponses={aggregatedResponses}
                        rapSheets={rapSheets}
                        transactionalInfo={transactionalInfo}
                        resultsResponses={resultsResponses}
                        usersPermissions={usersPermissions}
                    />
                </>
        },
        {
            label: 'NOTES',
            key: 'NOTES',
            children:
                <Notes
                    personalInfo={personalInfo}
                    userRole={userRole}
                    currentUser={currentUser}
                    usersPermissions={usersPermissions}
                />
        },
        {
            label: 'HISTORY',
            key: 'HISTORY',
            children:
                <AuditHistoryTable
                    id={registrationId}
                    name="audit-history"
                />
        }
    ];

    return (
        <Grid width='100%' padding='0' sx={{ backgroundColor: 'background.paper', overflowY: 'scroll', borderRadius: 1 }}>
            <Grid item position='sticky' top={0} zIndex={3}>
                <Tabs value={value} onChange={handleChange} sx={{ bgcolor: 'white', padding: '0.5rem 1rem', backgroundColor: 'common.black' }}>
                    {renderTabs(items)}
                </Tabs>
                <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%' }} />
            </Grid>
            {items[value].children}
        </Grid>
    );
};
