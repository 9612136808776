/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { currentConfig } from "amplifyConfig";
import { useCookies } from "react-cookie";
import { useAudit } from "./useAudit";
import { getDomainName } from "../utils/getDomainName";
import { urls } from '../urls'
import { useLocalStorage } from "common";
import { useMUITheme } from '../theme/ThemeProvider';
import { useLoginStore } from "state/LoginStore";
import { jwtDecode } from 'jwt-decode';
import { useTableStore } from 'state/TableStore';


console.log(currentConfig); // DO NOT REMOVE THIS LINE

type AuthContextType = {
  login?: Function;
  logout?: Function;
  handleMFASubmit?: Function;
  token?: string;
  user?: any;
  mfa?: any;
  authCode?: any;
  userForgotPassword?: boolean;
  handleResetPassword?: Function;
  forgotPassword?: Function;
  forceResetPassword?: boolean;
  sendForgotPassCode?: Function;
  authFlowError?: string;
  resetMFA?: Function;
  resetForceResetPassword?: Function;
  resetForgotPassword?: Function;
  setToken?: Function;
  cancelFlow: Function;
  pwChangedDescrip?: string,
  pwChangedTitle?: any,
  loginLoading?: Boolean
  authFlowErrorType?: string;
  loading?: boolean
  setAuthFlowError?: Function
};

const AuthContext = createContext<AuthContextType>({
  cancelFlow: Function
});
interface Props {
  children?: ReactNode;
  userData: any;
  // any props that come into the component
}

export const AuthProvider = ({ children, ...props }: Props) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [authCode, setAuthCode] = useState({});
  const [mfa, setMFA] = useState(false);
  const [forceResetPassword, setForceResetPassword] = useState(false);
  const [userForgotPassword, setForgotPassword] = useState(false);
  const [pwChangedTitle, setPWChangedTitle] = useState("");
  const [pwChangedDescrip, setPWChangedDescrip] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [authFlowError, setAuthFlowError] = useState("");
  const [authFlowErrorType, setAuthFlowErrorType] = useState('');
  const [loading, setLoading] = useState(false);
  const [, setLoggedIn, loggedIn] = useLocalStorage("loggedIn", false);
  const { addAuditEvent } = useAudit();
  const authUrl = urls.AUTH;
  const { mode } = useMUITheme();
  let tokenHandler = (process.env.REACT_APP_INTERNAL_USER_POOL === 'true') ? 'token-handler' : 'multi-token-handler';

  const setOpenAlert = useLoginStore((state) => state.setOpenAlert)
  const setSeverity = useLoginStore((state) => state.setSeverity)
  const userActive = useTableStore(state => state.userActive)


  const expDate = useMemo(() => {
    return new Date("2024-11-29T12:53:50.000Z");
  }, []);

  const login = async (loginData: any) => {
    const userName = loginData.userName.trim();
    const password = loginData.password.trim();

    try {
      const userSession = await Auth.signIn(userName, password, { 'product_scope': 'mc' });
      console.log('debug token refresh userSession: ', userSession)
      setUser(userSession);
      return userSession;
    } catch (err: any) {
      setAuthFlowErrorType(err.code)
      setAuthFlowError(err.message);
      return { errorMessage: err.message, errorCode: err.code };
    }
  };

  const cancelFlow = () => {
    setMFA(false)
    setAuthFlowError("")
    setAuthFlowErrorType("")
    setForceResetPassword(false)
    setForgotPassword(false)
  }

  const updateAttribute = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const updatedAttribute = await Auth.updateUserAttributes(user, { 'custom:firstTimeSignedIn': "false" })
      console.log('Updated first time logged in attribute status: ', updatedAttribute)

    } catch (e) {
      console.log("Failed to update user's attributes", e);
    }
  }

  useEffect(() => {
    console.log('debug token refresh user updated: ', user)
    if (user) {
      const { challengeName } = user;
      const { prefferedMFA } = user;
      setAuthCode("");
      const setupMFA = async () => {
        try {
          const res = await Auth.setupTOTP(user);
          const code =
            "otpauth://totp/AWSCognito:" +
            user.username +
            "?secret=" +
            res +
            "&issuer=Cognito";
          setAuthCode({ auth: code, text: res });
          setMFA(true);
        } catch (e: any) {
          setSeverity("error")
          setOpenAlert(true)
          setAuthFlowError(e.message);
        }
      };

      if (challengeName === "MFA_SETUP" || !challengeName) {
        setupMFA();
        console.log("challengeName", challengeName);
      } else if (challengeName === "SOFTWARE_TOKEN_MFA") {
        setMFA(true);
        console.log("challengeName", challengeName);
      } else if (challengeName === "NEW_PASSWORD_REQUIRED") {
        setForceResetPassword(true);
        console.log("challengeName", challengeName);
      } else if (prefferedMFA === "NOMFA") {
        console.log("challengeName", challengeName);
        console.log("prefferedMFA", prefferedMFA);
      }
    }
  }, [user]);

  const sendForgotPassCode = async (userName: string) => {
    setLoading(true);
    try {
      const res = await Auth.forgotPassword(userName);
      if (res) { 
        forgotPassword();
      }
      console.log("forgot password res: ", res);
    } catch (e: any) {
      setSeverity("error")
      setOpenAlert(true);
      setAuthFlowErrorType(e.code);
      console.log('e.message', e);
    }
    setLoading(false);
  };

  const forgotPassword = async () => {
    setForgotPassword(true);
  };

  const handleResetPassword = useCallback(
    async (newPass: string, code = null, userName: string) => {
      try {
        setLoading(true);
        if (!code) {
          let res = await Auth.completeNewPassword(user, newPass, {
            given_name: user.challengeParam.userAttributes.given_name,
            family_name: user.challengeParam.userAttributes.family_name,
          }, { password: newPass });
          if (res) {
            setUser(res);
            setOpenAlert(true)
            setSeverity("success")
            // setPWChangedTitle('Password Updated!')
            // setPWChangedDescrip('Please login using your new password')
            setForceResetPassword(false);
            addAuditEvent(
              "Password Changed",
              `${userName} reset password on  ${new Date()}`,
              new Date(),
              undefined
            );
            setLoading(false);
            return res;
          }
        } else if (userForgotPassword && code) {
          try {
            let res = await Auth.forgotPasswordSubmit(userName, code, newPass, { password: newPass });
            if (res) {
              addAuditEvent(
                "Password Changed",
                `${userName} reset password on  ${new Date()}`,
                new Date(),
                undefined
              );
              setForgotPassword(false);
              setOpenAlert(true)
              setSeverity("success")
              // setPWChangedTitle('Password Updated!')
              // setPWChangedDescrip('Please login using your new password');
              setLoading(false);
              return res;
            }
          } catch (e: any) {
            setLoading(false);
            setAuthFlowError(e.message);
            setSeverity("error")
            setOpenAlert(true)
          }
        }
      } catch (e: any) {
        setLoading(false);
        setAuthFlowError(e.message);
      }
    },
    [addAuditEvent, user, userForgotPassword]
  );

  const setToken = useCallback(
    async (user: any) => {
      const { jwtToken } = user?.signInUserSession?.accessToken;
      console.log('token: ', jwtToken)
      let access_token = jwtToken
      let refresh_token = user.signInUserSession.refreshToken.token
      let body = {
        action: 'SECURE_TOKEN',
        access_token,
        refresh_token
      }
      console.log('process.env.NODE_ENV: ', process.env.NODE_ENV)
      if (process.env.NODE_ENV === 'production') {

        const resp = await fetch(`${authUrl}/auth/${tokenHandler}`, {
          method: 'POST',
          body: JSON.stringify(body),
          credentials: "include"
        })
        console.log('httpOnly login token handler resp: ', resp)
        if (resp.ok) {
          setLoggedIn(true)
          await addAuditEvent(
            "Login",
            `${user.username} logged into CARES dashboard at ${new Date()}`,
            new Date(),
            undefined
          );
        }
      } else {
        const yes = await setCookie("token", `${jwtToken}`, {
          path: "/",
          expires: expDate,
          sameSite: "lax",
          domain: getDomainName(),
        });

        setLoggedIn(true)
        await addAuditEvent(
          "Login",
          `${user.username} logged into CARES dashboard at ${new Date()}`,
          new Date(),
          undefined
        );
      }
    },
    [setCookie, addAuditEvent, expDate]
  );

  interface TokenPayload {
    exp: number;
  }

  const getExpTime = (token: string): number  => {
    const decoded: TokenPayload = jwtDecode(token)
    console.log('decodedexptime', decoded)
    return decoded.exp * 1000;
  }

  const refreshAuthToken = async () => {
    try {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      await setToken(currentAuthenticatedUser);
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  const useTokenRefresh = () => {
    useEffect(() => {
      const intervalId = setInterval(async () => {
        const user = await Auth.currentAuthenticatedUser();
        const { jwtToken } = user.signInUserSession.accessToken;
        const expTime = getExpTime(jwtToken);

        console.log('expiration time: ', expTime, Date.now());
  
        if (Date.now() > expTime && userActive) {
          await refreshAuthToken();
        }
      }, 5 * 60 * 1000);
  
      return () => clearInterval(intervalId);
    }, []);
  };

  const clearStorageRefresh = () => {
    if (process.env.NODE_ENV != 'production') {
      removeCookie("token", { path: "/", domain: getDomainName() });
    }
    localStorage.clear();
    localStorage.setItem("theme", mode);
    navigate("/", { replace: true });
    window.location.reload();
  }

  const logoutTokenHandler = async () => {
    let body = {
      action: 'CLEAR_TOKEN',
    }
    const resp = await fetch(`${authUrl}/auth/${tokenHandler}`, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: "include"
    })
    console.log('httpOnly resp: ', resp)
    if (resp.ok) {
      clearStorageRefresh()
    } else {
      console.log('Error logging out')
    }
  }

  const logout = useCallback(async () => {
    console.log("useAuth logut");
    setMFA(false);

    try {
      const { username } = await Auth.currentAuthenticatedUser();
      updateAttribute();
      await addAuditEvent(
        "Logout",
        `${username} logged out of CARES dashboard at ${new Date()}`,
        new Date(),
        undefined
      );

    } catch (e: any) {
      console.log('Error logging out: ', e)
      clearStorageRefresh()
    }

    if (process.env.NODE_ENV != 'production') {
      clearStorageRefresh()
    } else {
      logoutTokenHandler()
    }
    setLoginLoading(false)

    /*localStorage.clear()
    navigate("/", { replace: true });*/
  }, [navigate, removeCookie, addAuditEvent]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === null && event.newValue === null && !localStorage.getItem("access_token")) {
        localStorage.setItem("theme", mode);
        window.location.reload();
        console.log('Logout triggered from another tab');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleMFASubmit = useCallback(
    async (code: string, e: any) => {
      if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        try {
          const res = await Auth.confirmSignIn(
            user,
            code,
            "SOFTWARE_TOKEN_MFA",
            {'product_scope': 'mc'}
            );
            if (res) {
              setToken(res);            
              setLoginLoading(true)
          }
        } catch (e: any) {
          setAuthFlowErrorType(e.code);
          setAuthFlowError(e.message);
          setSeverity("error")
          setOpenAlert(true)
        }
      } else if (user.challengeName === "MFA_SETUP" || !user.challengeName) {
        try {
          let loggedInUser = await Auth.verifyTotpToken(user, code);
          if (loggedInUser) {
            await Auth.setPreferredMFA(user, "TOTP");
            setToken(user);
            setLoginLoading(true)
          }
        } catch (e: any) {
          setSeverity("error")
          setOpenAlert(true)
          setAuthFlowErrorType(e.code);
          setAuthFlowError(e.message);
        }
      }
    },
    [setToken, user]
  );

  const resetMFA = useCallback(() => {
    console.log("resetting mfa");
    setMFA(false);
  }, [setMFA]);

  const resetForceResetPassword = useCallback(() => {
    console.log("resetting mfa");
    setForceResetPassword(false);
  }, []);
  const resetForgotPassword = useCallback(() => {
    console.log("resetting mfa");
    setForgotPassword(false);
  }, []);

  const value = useMemo(
    () => ({
      login,
      logout,
      handleMFASubmit,
      handleResetPassword,
      forgotPassword,
      sendForgotPassCode,
      resetMFA,
      resetForceResetPassword,
      resetForgotPassword,
      setToken,
      cancelFlow,
      setLoginLoading,
      user,
      authCode,
      mfa,
      userForgotPassword,
      forceResetPassword,
      authFlowError,
      pwChangedDescrip,
      pwChangedTitle,
      loginLoading,
      authFlowErrorType,
      loading,
      setAuthFlowError,
      useTokenRefresh
    }),
    [
      user,
      mfa,
      authCode,
      userForgotPassword,
      forceResetPassword,
      authFlowError,
      pwChangedDescrip,
      pwChangedTitle,
      loginLoading,
      authFlowErrorType,
      loading,
      cancelFlow,
      resetForgotPassword,
      resetMFA,
      resetForceResetPassword,
      logout,
      sendForgotPassCode,
      handleMFASubmit,
      handleResetPassword,
      setToken,
      setLoginLoading,
      setAuthFlowError,
      useTokenRefresh
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return useContext(AuthContext);
};
