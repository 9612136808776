/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useUserCognito } from "hooks/useUserCognito";
import React, { createContext, useContext, useEffect } from "react";

type UserPermissionsContextProps = any;

const UserPermissionsContext = createContext<UserPermissionsContextProps>({
  role: "mc_admin",
});

const UserProvider: React.FC<UserPermissionsContextProps> = ({ children }) => {
  const [permissions, setPermissions] = React.useState<object>({});

  const roles = useUserCognito();
  const memodRoles = React.useMemo(() => roles, [JSON.stringify(roles)]);
  useEffect(() => {
    const { usersPermissions } = memodRoles;
    if (usersPermissions) {
      setPermissions(usersPermissions);
    }
  }, [memodRoles, permissions]);

  const memodPermissionsState = React.useMemo(
    () => ({ permissions, setPermissions, roles: memodRoles }),
    [JSON.stringify(permissions), memodRoles]
  );

  return (
    <UserPermissionsContext.Provider value={memodPermissionsState}>
      {children}
    </UserPermissionsContext.Provider>
  );
};

const useUserPermissions = () => {
  const context = useContext(UserPermissionsContext);

  if (!context)
    throw new Error("useUserRoles must be used within a UserRolesContext");
  return context;
};

export { UserProvider, useUserPermissions };
