/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { urls } from "../urls";
import { extendViewing, transform } from "utils";
import { generateQueryParams } from "utils";
import { QueryRequestBody } from "interfaces/common/QueryRequestBody"
import { SearchParams } from "@customTypes/SearchParam";
import { useTableStore } from "../state";
let localizedFormat = require('dayjs/plugin/localizedFormat')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(localizedFormat)
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
dayjs.extend(timezone)

export const useQueryTransactions = (
    pageSize: number,
    currentPage: number,
    order: string,
    orderBy: string,
    userORIs: any,
    userSecondaryORIs: any,
    userRole: any,
    searchParams: SearchParams,
    auditSearch: boolean
) => {

    const url = urls.QUERY;
    const [loading, setLoading] = useState(true);
    const [mappedData, setMappedData] = useState<any>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [error, setError] = useState<any>();
    const columnState = useTableStore(state => state.columnState)

    useEffect(() => {
        const controller = new AbortController();
        const param = searchParams.find((param) => param.column === "transactionDate" || param.column === "lowerDate" || param.column === "upperDate")

        let filters = columnState.page["transaction-summary"]!.filters

        const filterLabels: string[] = [];
        if (filters) {
            filters.forEach((filter) => {
                let filterValueKeys = Object.keys(filter.filterValues)
                filterValueKeys.forEach((key) => {
                    //console.log('key: ', key)
                    if (filter.filterValues[key].selected === true) {
                        //console.log('filter.filterValues[key].label: ', filter.filterValues[key].label)
                        filterLabels.push(filter.filterValues[key].label)
                    }
                })
            })
        }


        if (userORIs && userORIs.length && userRole) {

            const queryParams = generateQueryParams(userORIs, userSecondaryORIs, searchParams, filterLabels, orderBy, order, param)

            let queryDataRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [
                            ...queryParams,
                            {
                                "value": order,
                                "name": "order"
                            }
                        ],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_TRANSACTION_TABLE_QUERY!

                    }
                ]
            }

            let queryCountRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_TRANSACTION_TABLE_COUNT_QUERY!
                    }
                ]
            }

            const fetchData = async (queryDataRequest: QueryRequestBody, queryCountRequest: QueryRequestBody) => {
                let runSearch = false
                let searchParamsKeys = searchParams.length > 0 ? Object.keys(searchParams[0]) : []

                if (searchParamsKeys.length > 0) {
                    runSearch = searchParams.some((param: any) => param.hasOwnProperty("value"))
                } else {
                    runSearch = true
                }

                if (runSearch) {
                    try {
                        const transactionalUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;
                        const data = await (await fetch(transactionalUrl, {
                            method: "POST",
                            credentials: "include",
                            body: JSON.stringify(queryDataRequest)
                        })).json();


                        const countData = await (await fetch(transactionalUrl, {
                            method: "POST",
                            credentials: "include",
                            body: JSON.stringify(queryCountRequest)
                        })).json();

                        setTotalRecords(countData['queriesResults'][0].results[0].count)


                        const { results } = data['queriesResults'][0];
                        const transformedData = results.map((obj: any, index: number) => { return transform(obj, index) });
                        setMappedData(transformedData);
                        setLoading(false);
                        
                        if (auditSearch) {
                            //addAuditEvent("Search executed", "Search params: " +  JSON.stringify(queryParams), new Date(), undefined, undefined);
                        }

                    } catch (err) {
                        setError(err);
                        setLoading(false);
                    }
                }
            }
            //setLoading(true);
            fetchData(queryDataRequest, queryCountRequest);
        }

        return () => controller.abort();
    }, [userRole, userORIs, pageSize, currentPage, order, orderBy, searchParams, columnState.page["transaction-summary"]!.filters]);

    return { data: mappedData, extendViewing, error, loading, totalRecords };
};
