import { urls } from "../urls";
import { useEffect, useState } from "react";
import {
    generateRBScubscriptionQueryParams, getCurrentEnv,
    transformMaintenanceData, transformPrenotificationsData,
    transformRapBackData, transformValidationsData
} from "../utils";
import { QueryRequestBody } from "interfaces/common";
import { PageNames } from "interfaces/config";
import { useTableStore } from "../state";
import { AlertColor } from "customEnums/AlertColor";
import { useRapbackMaintenanceStore } from '../pages/RapbackSubscriptions/RapbackMaintenance/state'


export const useRapbackSubscription = (
    pageName: string,
    pageSize: number,
    currentPage: number,
    order: string,
    orderBy: string,
    userORIs: any,
    userSecondaryORIs: any,
    userRole: any,
    searchParams: any,
    auditSearch: boolean
) => {

    const STATE = getCurrentEnv
    const queryUrl = urls.QUERY
    const rapbackUrl = urls.RAPBACK

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [mappedData, setMappedData] = useState<any>()
    const [totalRecords, setTotalRecords] = useState(0);    
    const [queryDataRequest, setQueryDataRequest] = useState<any>()
    const [queryCountRequest, setQueryCountRequest] = useState<any>()

    const setAlertTitle = useTableStore(state => state.setAlertTitle);
    const setAlertMessage = useTableStore(state => state.setAlertMessage);
    const setSeverity = useTableStore((state) => state.setSeverity)
    const setOpenNotification = useTableStore((state) => state.setOpenNotification)
    const setOutStandingRapback = useTableStore((state) => state.setOutStandingRapback)
    const outstandingRapback = useTableStore((state) => state.outstandingRapback)
    const setNeedsAttention = useTableStore(state => state.setNeedsAttention);
    const fullName = useRapbackMaintenanceStore(state => state.fullName)
    const receivedResponse = useTableStore((state) => state.receivedResponse)
    const setReceivedResponse = useTableStore((state) => state.setReceivedResponse)
    const columnState = useTableStore(state => state.columnState)

    const fetchMaintenance = async (queryDataRequest: any, queryCountRequest: any) => {
        const url: string = `${process.env.NODE_ENV === "production" ? queryUrl : ""}/query`;
        try {
            setLoading(true)

            const data = await (await fetch(url, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(queryDataRequest)
            })).json();

            const { results } = data['queriesResults'][0];

            console.log('rapbacksubscription results: ', results)

            let updatedOutstandingRapback = { ...outstandingRapback }
            results.map((result: any) => {
                if (result.needsConfirm === "true") {
                    updatedOutstandingRapback = { ...updatedOutstandingRapback, preNotification: true };
                    setNeedsAttention(true)
                }
                if (result.daysToExpire < 60 && result.daysToExpire !== null) {
                    updatedOutstandingRapback = { ...updatedOutstandingRapback, validations: true };
                    setNeedsAttention(true)
                }
            })

            setOutStandingRapback(updatedOutstandingRapback);

            const countData = await (await fetch(url, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(queryCountRequest)
            })).json();

            const count = countData['queriesResults'][0].results[0]['count'];

            const transformedData = results.map((obj: any, index: number) => {
                console.log(`debug validations  pageName ${pageName}`)

                switch (pageName) {
                    case PageNames.RAPBACK_MAINTENANCE:
                        return transformMaintenanceData(obj, index)
                    case PageNames.RAPBACK_PRENOTIFICATIONS:
                        return transformPrenotificationsData(obj, index)
                    case PageNames.RAPBACK_VALIDATIONS:
                        let transformed = transformValidationsData(obj, index)
                        return transformed
                }
            });

            setMappedData(transformedData);
            setTotalRecords(count);
            setLoading(false);
            
        } catch (err: any) {
            setError(err);
            setLoading(false);
        }
    }

    const handleConfirmPrenotification = async (subscriptionId: string) => {
        console.log(`handleConfirmPrenotification ran`)
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/confirm/${subscriptionId}`;

        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            if (resp.ok) {
                fetchMaintenance(queryDataRequest, queryCountRequest)
                setAlertTitle("Request Submitted");
                setAlertMessage(`A pre-notification acceptance request has been submitted for ${fullName}. View pending details on the Rapback Maintenance screen.`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Failed`)
                setAlertMessage(`Please try again to accept ${fullName}'s subscription`)
                setReceivedResponse(true)
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleDenyPrenotification = async (subscriptionId: string) => {
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/deny/${subscriptionId}`;

        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            if (resp.ok) {
                fetchMaintenance(queryDataRequest, queryCountRequest)
                setAlertTitle("Request Submitted");
                setAlertMessage(`A pre-notification rejection request has been submitted for ${fullName}. View pending detials on the Rapback Maintenance Screen.`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Failed`)
                setAlertMessage(`Please try again to decline ${fullName}'s subscription`)
                setReceivedResponse(true)
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleUpdateSubscription = async (subscriptionId: string, expirationDate: string, dob: string) => {
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/update/${subscriptionId}`;

        const body = {
            expiration_date: expirationDate,
            dob,
            rapback_id: subscriptionId
        }

        console.log('handleUpdateSub body: ', body)
        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })
            if (resp.ok) {
                fetchMaintenance(queryDataRequest, queryCountRequest)
                setAlertTitle("Request Successfully Submitted");
                setAlertMessage(`Edit Requests will be reviewed`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)

            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Error`)
                setAlertMessage(`Your attempt to edit has failed, please try again or contact support`)
                setReceivedResponse(true)
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleUnsubscribe = async (subscriptionId: string) => {
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/unsubscribe/${subscriptionId}`;

        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            console.log('unsubscribe response: ', resp)
            if (resp.ok) {
                if (pageName === "rapback-maintenance") {
                    fetchMaintenance(queryDataRequest, queryCountRequest)
                    setOpenNotification(true);
                    setAlertTitle("Request Successfully Submitted");
                    setAlertMessage(`Unsubscribe request will be reviewed. If approved, ${fullName} will be unsubscribed from receiving Rapbacks.`)
                    setSeverity(AlertColor.SUCCESS)
                    setReceivedResponse(true)
                }
                if (pageName === "rapback-validations") {
                    setAlertTitle("Request Submitted");
                    setAlertMessage(`A rapback subscription cancellation request has been submitted for ${fullName}. View pending detials on the Rapback Maintenance Screen.`)
                    setSeverity(AlertColor.SUCCESS)
                    setOpenNotification(true)
                    setReceivedResponse(true)

                }
                return resp.ok
            } else {
                if (pageName === "rapback-maintenance") {
                    setOpenNotification(true);
                    setSeverity(AlertColor.ERROR)
                    setAlertTitle(`Request Error`)
                    setAlertMessage(`Your attempt to unsubscribe ${fullName} has failed, please try again or contact support.`)
                    setReceivedResponse(true)
                }
                if (pageName === "rapback-validations") {
                    setOpenNotification(true);
                    setSeverity(AlertColor.ERROR)
                    setAlertTitle(`Request Failed`)
                    setAlertMessage(`Please try again to cancel ${fullName}'s subscription`)
                    setReceivedResponse(true)
                }
                return false
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleRenew = async (subscriptionId: string) => {
        console.log(`Validations handleRenew hook subscriptionId: ${subscriptionId}`)

        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/renew/${subscriptionId}`;

        console.log(`Validations handleRenew hook url: ${url}`)

        try {
            console.log(`Validations handleRenew hook fetching....`)

            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            if (resp.ok) {
                fetchMaintenance(queryDataRequest, queryCountRequest)
                setAlertTitle("Request Submitted");
                setAlertMessage(`A rapback subscription renewal request has been submitted for ${fullName}. View pending details on the Rapback Maintenance screen.`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Failed`)
                setAlertMessage(`Please try again to renew ${fullName}'s subscription`)
            }
            return resp.ok
        } catch (err: any) {
            setError(err)
        }
    }

    useEffect(() => {
        console.log('requerying rapback table')
        console.log('recevied response or no', receivedResponse)
        const controller = new AbortController();

        //update column names for dates
        const param = searchParams.find((param) => param.column === "transactionDate" || param.column === "lowerDate" || param.column === "upperDate")

        let filters = columnState.page["rapback-maintenance"]!.filters

        const filterLabels: string[] = []
        if (filters) {
            filters.forEach((filter) => {
                let filterValueKeys = Object.keys(filter.filterValues)
                filterValueKeys.forEach((key) => {
                    console.log('key: ', key)
                    if (filter.filterValues[key].selected === true) {
                        console.log('filter.filterValues[key].label: ', filter.filterValues[key].label)
                        filterLabels.push(filter.filterValues[key].label)
                    }
                })
            })
        }

        if (userORIs && userORIs.length && userRole) {
            const queryParams = generateRBScubscriptionQueryParams(pageName, userORIs, userSecondaryORIs, searchParams, filterLabels, orderBy, order, param)
            console.log('rapbacksub query params: ', queryParams)
            let queryDataRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackSubscriptionTable"
                    }
                ]
            }

            setQueryDataRequest(queryDataRequest)

            let queryCountRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackSubscriptionTableCount"
                    }
                ]
            }

            setQueryCountRequest(queryCountRequest)

            setLoading(true)
            if(STATE === "ga") fetchMaintenance(queryDataRequest, queryCountRequest)

        }

        console.log('debug searchParams: ', searchParams)
    }, [STATE, userRole, userORIs, pageSize, currentPage, order, orderBy, searchParams, columnState])

    return { data: mappedData, error, loading, totalRecords, fetchMaintenance, handleConfirmPrenotification, handleDenyPrenotification, handleUpdateSubscription, handleUnsubscribe, handleRenew };
}