import * as React from 'react';
import { pageStyles } from "customStyles/common";
import { Paper } from "@mui/material";
import { ConfirmModal, CustomTable, CustomTableTitleAndAlert } from "../../../common";
import { useCallback, useEffect, useState } from "react";
import { Order } from "customTypes/OrderType";
import { useTableStore } from "../../../state";
import { useExpanded } from "../../../components/layouts/ProtectedLayout";
import { useAudit } from "hooks/useAudit";
import { useRapbackSubscription } from "../../../hooks/useRapbackSubscription";
import { useUserCognito } from "../../../hooks";
import { useLocation } from "react-router-dom";
import { extractPageName } from "../../../common/CustomTable/helpers";
import { useRapbackMaintenanceStore } from '../RapbackMaintenance/state/MaintenanceStore'

export const RapbackValidations: React.FC = () => {
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const { addAuditEvent } = useAudit();

    //Local State
    const [data, setData] = useState()
    const [sortOrder, setSortOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('expirationDate');
    const [searched, setSearched] = useState<boolean>(false); //Set true when an advanced form multi parameter search has run
    const [value, setValue] = React.useState<any>([]);
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [confirmModalTitle, setConfirmModalTitle] = useState("")
    const [confirmModalContent, setConfirmModalContent] = useState("")
    const [cancelButtonText, setCancelButtonText] = useState("")
    const [confirmButtonText, setConfirmButtonText] = useState("")
    const [action, setAction] = useState("")
    const [rapbackId, setRapbackId] = useState("")
    const [displaySSN, setDisplaySSN] = useState('')
    const [showSSN, setShowSSN] = useState<boolean>(false);

    //Table Store
    const setPagination = useTableStore(state => state.setPagination)
    const pagination = useTableStore((state) => state.pagination)
    const setOpenNotification = useTableStore((state) => state.setOpenNotification)
    const openNotification = useTableStore((state) => state.openNotification)
    const searchParams = useTableStore((state) => state.searchParams)
    const setActionsConfig = useTableStore(state => state.setActionsConfig)
    
    // Rapback Subscription Store:
    const setFullName = useRapbackMaintenanceStore(state => state.setFullName)
    
    const { rowsPerPage, page } = pagination

    //Hooks
    const { expanded } = useExpanded()
    const { userORIs, userRole, userSecondaryORIs } = useUserCognito();

    //Data
    const { data: mappedData, totalRecords, loading, handleRenew, handleUnsubscribe } = useRapbackSubscription(pageName, rowsPerPage,
        page,
        sortOrder,
        orderBy,
        userORIs,
        userSecondaryORIs,
        userRole,
        searchParams, true)

    console.log(`debug validations data transformedData mappedData`, mappedData, data)

    useEffect(() => {
        setData(
            mappedData?.map((data: any, index) => {
                return {
                    key: data.key,
                    fullName: data.fullName,
                    dateOfBirth: data.dateOfBirth,
                    ssn: data.ssn,
                    oris: data.oris,
                    rapbackId: data.rapbackId, //is this rapback id?
                    primaryOri: data.primaryOri,
                    startDate: data.startDate,
                    expirationDate: data.expirationDate,
                    timeRemaining: data.timeRemaining
                }
            })
        );
    }, [mappedData?.length, mappedData])

    //Needed for all pages that use query service
    useEffect(() => {
        setPagination({ ...pagination, page: 1 })
        console.log(`debug visibleRows page ${page}, rowsPerPage: ${rowsPerPage}`)
    }, [])

    //Potentially make generic and import
    const handleRequestSort = (event: any, property) => {
        console.log(`sort date handleReuqestSort sortOrder: ${sortOrder} orderBy ${orderBy} property: ${property}`)
        const isAsc = orderBy === property && sortOrder === 'asc';
        console.log(`sort date handleRequestSort sortOrder: isAsc ${isAsc}`)
        setSortOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property);
    };

    const handleRenewSubscription = (e, row) => {
        console.log('asdfsadfsdafasd', row);
        setRapbackId(row.rapbackId)
        setAction("renew")
        setConfirmModalTitle("CONFIRM RENEW REQUEST")
        setConfirmModalContent(`Are you sure you want to renew the Rapback Subscription request
        for ${row.fullName}? This applicant will continue to receive Rapbacks.`)
        setCancelButtonText("CANCEL")
        setConfirmButtonText("RENEW SUBSCRIPTION")
        setOpenConfirmModal(true)
        setFullName(row.fullName)
        addAuditEvent("Subscription Renewal Request Submitted", "Rapback Validation", new Date(), row.rapbackId)
    }

    const handleCancelSubscription = (e, row) => {
        setRapbackId(row.rapbackId)
        setAction("cancel_subscription")
        setConfirmModalTitle("CONFIRM CANCEL REQUEST")
        setConfirmModalContent(`Are you sure you want to cancel Rapback Subscriptions
        for ${row.fullName}? This applicant will no longer receive Rapbacks. This action cannot be undone.`)
        setCancelButtonText("CANCEL")
        setConfirmButtonText("CANCEL SUBSCRIPTION")
        setOpenConfirmModal(true)
        setFullName(row.fullName)
        addAuditEvent("Subscription Cancellation Request Submitted", "Rapback Validation", new Date(), row.rapbackId)
    }

    useEffect(() => {
        if (openNotification) {
            setTimeout(() => {
                setOpenNotification(false)
            }, 5000)
        }
    })

    const actionsConfig: { actionLabel: string, actionHandler: Function }[] = [
        {
            actionLabel: "Renew Subscription",
            actionHandler: handleRenewSubscription
        },
        {
            actionLabel: "Cancel Subscription",
            actionHandler: handleCancelSubscription
        }
    ]

    setActionsConfig(actionsConfig)

    const handleClickShowSSN = useCallback(async (record: string, e: any) => {
        if (displaySSN !== record) {
            setDisplaySSN(record)
            setShowSSN(!showSSN)

        } else {
            setDisplaySSN('')
            setShowSSN(!showSSN)
        }
    }, [displaySSN])

    return (
        <>
            <Paper elevation={0} sx={
                {
                    ...pageStyles.pagePaperStyle,
                    maxWidth: `calc(100vw - ${expanded ? "256px" : "64px"})`
                }}>
                <CustomTableTitleAndAlert
                    name="Rapback Validations"

                />
                <CustomTable
                    //Required props
                    tableName="rapback-validations"
                    loading={loading}
                    rows={data}
                    orderBy={orderBy}
                    order={sortOrder}
                    totalRecords={totalRecords}
                    currentPage={page}
                    pageSize={rowsPerPage}
                    searched={searched}
                    value={value}
                    handleRequestSort={handleRequestSort}
                    setValue={setValue}
                    handleClickShowSSN={handleClickShowSSN}
                    displaySSN={displaySSN}
                    setSearched={setSearched}
                    //Per page props
                    defaultSortColumn="expirationDate"
                    rowClickHandler={(e, row) => { }}

                />
                <ConfirmModal
                    customWidth={'600px'}
                    customHeight={'193px'}
                    title={confirmModalTitle}
                    content={confirmModalContent}
                    cancelButtonText={cancelButtonText}
                    confirmButtonText={confirmButtonText}
                    handleCancel={() => setOpenConfirmModal(false)}
                    handleConfirm={async (e) => {
                        switch (action) {
                            case "renew":
                                setOpenConfirmModal(false)
                                const handleRenewResp = await handleRenew(rapbackId)
                                break;
                            case "cancel_subscription":
                                setOpenConfirmModal(false)
                                const resp = await handleUnsubscribe(rapbackId)
                                break;
                        }
                    }}
                    openDialog={openConfirmModal}
                />
            </Paper>{ }
        </>
    )
}