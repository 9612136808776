import dayjs from 'dayjs'
import { LogConfig, logger } from "../../utils";

export const descendingComparator = (a: any, b: any, orderBy: any) => {
    ////console.log(`debug agency orderBy a ${JSON.stringify(a, null, 2)} ${orderBy}`)
    ////console.log(`debug agency orderBy b ${JSON.stringify(b, null, 2)} ${orderBy}`)
    /*if (orderBy === "agency_email") {
        orderBy = orderBy
    }*/
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const extractPageName = (pathname: string): string => {
    const segments = pathname.split('/').filter(Boolean);
    const index = segments.indexOf('app');
    if (index !== -1 && index + 1 < segments.length) {
        return segments[index + 1];
    } else {
        return '';
    }
};

export const getComparator = (order: any, orderBy: any) => {
    ////console.log(`debug sort getComparator order ${order} orderBy ${orderBy}`)
    return order === 'desc'
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export const stableSortNonQuery = (array: any, comparator: (a: any, b: any) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    const debugTablePagination: LogConfig = {
        identifier: "debugTablePagination",
        pageName: "transaction-summary",
        thingToDebug: "stableSortNonQuery params and return value",
        paramsToLog: {
            returnValue: stabilizedThis.map((el) => el[0])
        }
    }
    //loggerdebugTablePagination)
    return stabilizedThis.map((el) => el[0]);
}

export const stableSort = (array: any, comparator: (a: any, b: any) => number, state: any, pageName: string, orderBy: string | undefined = undefined, tableName?: string) => {
    let filterColumn: string | null = null;
    let filterIsBool: boolean | null = null;
    let filterIsNumber: boolean | null = null;
    let activeFilters: any = [];

    state.columnState.page[pageName]?.filters?.forEach((filter: any) => {
        const { filterColumn: column, filterIsBool: isBool, filterValues, filterIsNumber: isNumber } = filter;

        Object.keys(filterValues)?.forEach((key: string) => {
            if (filterValues[key].selected) {
                if (isBool) {
                    activeFilters.push(filterValues[key].boolMap);
                    return filterValues[key].boolMap
                } else if (isNumber) {
                    activeFilters.push(filterValues[key].boolNumber);
                    return filterValues[key].boolNumber
                } else {
                    activeFilters.push(filterValues[key].label.toLowerCase());
                    return filterValues[key].label.toLowerCase()
                }
            }
            filterColumn = column
            filterIsBool = isBool
        });
    });

    const stabilizedThis: any = [];
    array.forEach((el: any, index: any) => {
        const pair = [el, index];
        stabilizedThis.push(pair);
    });

    let timeRemainingOrdering = {}
    let timeRemaining: number[] = [];

    for (let i = 1; i <= 60; i++) {
        timeRemaining.push(i);
    }


    for (var i = 0; i < timeRemaining.length; i++) {
        timeRemainingOrdering[timeRemaining[i]] = i;
    }

    let ordering = {}
    let sortOrder: any = ["Subscribed", "Edit Pending", "Pre-Notification Pending", "Unsubscribe Pending", "Unsubscribed", null]

    for (var i = 0; i < sortOrder.length; i++) {
        ordering[sortOrder[i]] = i;
    }

    if (pageName === "rapback-maintenance" && orderBy === "status") {
        stabilizedThis.sort((a: any, b: any) => {
            return ordering[a[0].status] - ordering[b[0].status]
        });
    } else if (orderBy === "subscription_date" || orderBy === "startDate") {
        orderBy = "startDate"
        stabilizedThis.sort((a: any, b: any) => {
            return new Date((a[0][orderBy!])).getTime() - new Date(b[0][orderBy!]).getTime()
        })
    } else if (orderBy === "timeRemaining") {
        stabilizedThis.sort((a: any, b: any) => {
            return a[0][orderBy!] - b[0][orderBy!]
        })
    } else if (orderBy != undefined) {
        stabilizedThis.sort((a: any, b: any) => {
            const order = comparator(a[0][orderBy!], b[0][orderBy!]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
    }

    let stableSortReturn = stabilizedThis.map((el: any) => el[0]);
    
    if (filterColumn && ((filterIsBool) || (!filterIsBool)) && ((filterIsNumber) || (!filterIsNumber))) {
        let visibleRowsCopy = stableSortReturn.filter((row: any) => {
            const { timeRemaining } = row
            if (pageName === "transaction-summary" || (pageName === "rapback-maintenance" && tableName !== 'rb-sub-audit-history')) {
                return activeFilters.includes(row.status.toLowerCase()) /* activeFilters.includes(row.viewed) */
            } if (pageName == "rapback-summary") {
                return activeFilters.includes(row.viewed)
            } if (pageName === "user-management") {
                return activeFilters.includes(row.securityAndStatus.securityLevel.toLowerCase()) && activeFilters.includes(row.securityAndStatus.status.toLowerCase())
            } if (pageName === "rapback-validations") {
                const filterMatch = activeFilters.some(filter => {
                    if (filter === timeRemaining) {
                        return true;
                    }
                    if (filter === 1 && timeRemaining < 1) {
                        return true;
                    }
                    if (filter === 10 && timeRemaining >= 2 && timeRemaining < 11) {
                        return true;
                    }
                    if (filter === 11 && timeRemaining >= filter) {
                        return true;
                    }
                    return false;
                });
                return filterMatch;
            }
        });
        return visibleRowsCopy;
    } else {
        return stableSortReturn;
    }
}