/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect, useState } from "react";
import { urls } from "urls";
import { useParams } from "react-router-dom";
import { Button, Container, Divider, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CustomTable } from "common";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ApplicantDetailsFormProps } from '@propTypes/index';
import { useTableStore } from "state";
import { getCurrentEnv } from "../../../../../utils";
import { useAudit } from "hooks/useAudit";
import printJS from 'print-js';
import PrintIcon from '@mui/icons-material/Print';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactNode } from 'react';

const inputContainer = {
  width: "100%",
  height: "56px",
  gap: "16px",
  display: "flex",
  justifyContent: "space-around",
  marginTop: "24px",
  marginBottom: "24px",
}

const textFieldStyle = {
  flex: 1,
  height: "56px",
  width: '25%',
}

const sectionHeaderStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}

export const ApplicantDetailsForm: React.FC<ApplicantDetailsFormProps> = ({
  usersPermissions,
  personalInfo,
  transactionalInfo,
  applicantFetchStatus,
  statusHistories,
  adjudicationDocuments
}) => {

  const [openModal, setOpenModal] = useState(false);
  const { registrationId } = useParams();
  const { addAuditEvent } = useAudit();

  const setActionsConfig = useTableStore(state => state.setActionsConfig)

  const [ssnValues, setSsnValues] = useState({
    SSN: personalInfo["ssn"],
    showSSN: false
  });

  const printDoc = async (e, row) => {
    const data = await fetch(row.fileLink).then((res) => res.blob())
    const uri = URL.createObjectURL(data);
    //console.log('pdf data ', data)
    addAuditEvent("Printed", "Details / Adjudication Document", new Date(), row.registrationId)
    printJS(uri);
  }

  const saveDoc = async (e, row) => {
    const link = document.createElement("a");
    link.download = row.fileNameControl;
    const data = await fetch(row.fileLink).then((res) => res.blob())
    link.href = window.URL.createObjectURL(
      new Blob([data], { type: 'application/pdf' })
    );
    link.click();
    link.remove();
    addAuditEvent("Downloaded", "Details / Adjudication Document", new Date(), row.registrationId)
    window.URL.revokeObjectURL(link.href);
  }

  const openFileLink = (link: string) => {
    const newTab = window?.open(link, '_blank');
    newTab?.focus();
  }

  const openDoc = async (e: any, row: any) => {
    addAuditEvent("Opened Adjudication Document", "Details / Adjudication Document", new Date(), row.registrationId)
    openFileLink(row.fileLink)
  }

  const actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler: Function }[] = [
    {
      actionIcon: <PrintIcon sx={{ color: 'text.secondary', mr: '1rem'}} fontSize="small"/>,
      actionLabel: "Print",
      actionHandler: printDoc
    },
    // {
    //   actionIcon: <SaveAltIcon sx={{ color: 'text.secondary', mr: '1rem'}} fontSize="small"/>,
    //   actionLabel: "Save as",
    //   actionHandler: saveDoc
    // },
    {
      actionIcon: <OpenInNewIcon sx={{ color: 'text.secondary', mr: '1rem'}} fontSize="small"/>,
      actionLabel: "View",
      actionHandler: openDoc
     }
  ]

  setActionsConfig(actionsConfig)

  console.log(`applicantData: personalInfo ${JSON.stringify(personalInfo, null, 2)}, transactionalInfo ${JSON.stringify(transactionalInfo, null, 2)}, status histories: ${JSON.stringify(statusHistories, null, 2)}`)

  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    console.log("emails", emails);
  }, [emails]);

  const handleResendEmail = async (event: any) => {
    // const reporting_url = urls[env].REPORTING;
    const endpoint = `${process.env.NODE_ENV === "production" ? urls.REPORTING : ""
      }/adjudication/resend-email/${registrationId}`;

    const body = { emails: emails };

    const options: RequestInit = {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(body),
    };

    console.log("options", JSON.stringify(options));

    await fetch(endpoint, options)
      .then((response: Response) => {
        console.log("resend email decision success", response);
      })
      .catch((error: Error) => {
        console.error("resend email decision error", error);
      });

    alert(
      "Documents have been sent. If you have not received an email, please check the address provided"
    );
  };

  const handleClickShowSSN = () => {
    setSsnValues({ ...ssnValues, showSSN: !ssnValues.showSSN })
  }

  const [hide, setHide] = useState<any>([]);

  const hideSection = (key: any) => {
    if (hide.includes(key)) {
      setHide(hide.filter((section) => section !== key))
    } else {
      setHide([...hide, key])
    }
  }

  const isSectionVisible = (key) => hide.includes(key)

  const multiTablePagination = useTableStore((state) => state.multiTablePagination)

  return (
    <Container disableGutters maxWidth={false} sx={{ width: "100%", gap: 2, paddingTop: 3, paddingLeft: 3, paddingBottom: 4, paddingRight: 3, backgroundColor: 'common.black' }}>
      <Container disableGutters maxWidth={false} sx={sectionHeaderStyle}>
        <Typography height="max-content" variant="h6" color='text.primary'>PERSONAL INFORMATION</Typography>
        <Button size="small" variant="text" color="primary" endIcon={!isSectionVisible('personal-info') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} onClick={() => hideSection('personal-info')}>{!isSectionVisible('personal-info') ? 'HIDE' : 'SHOW'}</Button>
      </Container>
      {!isSectionVisible('personal-info') &&
        <>
          <Container disableGutters maxWidth={false} sx={inputContainer} key='personal-info'>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["firstName"] ? false : true} value={personalInfo["firstName"]} InputLabelProps={{ shrink: true }} label={personalInfo["firstName"] ? "First name" : ''} placeholder={!personalInfo["firstName"] ? "" : "First name"} sx={textFieldStyle}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["middleName"] ? false : true} value={personalInfo["middleName"]} InputLabelProps={{ shrink: true }} label={personalInfo["middleName"] ? "Middle name" : ''} placeholder="Middle name" sx={textFieldStyle}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["lastName"] ? false : true} value={personalInfo["lastName"]} InputLabelProps={{ shrink: true }} label={personalInfo["lastName"] ? "Last name" : ''} placeholder="Last name" sx={textFieldStyle}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["suffix"] ? false : true} value={personalInfo["suffix"]} InputLabelProps={{ shrink: true }} label={personalInfo["suffix"] ? "Suffix" : ''} placeholder="Suffix" sx={textFieldStyle}>
              {/* add select prop to parent TextField and uncomment this section when we eventually make this editable<MenuItem>
            DDS
          </MenuItem>*/}
            </TextField>
          </Container>


          <Container disableGutters maxWidth={false} sx={inputContainer} >
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["residenceAddress"] ? false : true} value={personalInfo["residenceAddress"]} InputLabelProps={{ shrink: true }} label={personalInfo["residenceAddress"] ? "Residence address (street, city, state/province, zip code)" : ''} placeholder="Address" sx={{ width: "49.55%", height: "56px" }}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["placeOfBirth"] ? false : true} value={personalInfo["placeOfBirth"]} InputLabelProps={{ shrink: true }} label={personalInfo["placeOfBirth"] ? "Place of birth (State / Province)" : ''} placeholder="City, State" sx={{ flex: 1, height: "56px", width: '25%' }}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["countryOfCitizenship"] ? false : true} value={personalInfo["countryOfCitizenship"]} InputLabelProps={{ shrink: true }} label={personalInfo["countryOfCitizenship"] ? "Country of citizenship" : ''} placeholder="Address" sx={{ flex: 1, height: "56px", width: '25%' }} />
          </Container>
          <Container disableGutters maxWidth={false} sx={inputContainer}>
            <TextField
              InputProps={{
                disabled: true, endAdornment: usersPermissions['viewAllDetailsData'] ?
                  <InputAdornment position="end">
                    <Tooltip title={ssnValues.showSSN ? "Hide" : "Show"}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowSSN}
                        edge="end"
                      >
                        {ssnValues.showSSN ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment> : null
              }}
              disabled={personalInfo["ssn"] ? false : true}
              value={ssnValues.showSSN ? personalInfo["ssn"] : "*********"}
              InputLabelProps={{ shrink: true }}
              label={personalInfo["ssn"] ? "SSN" : ''}
              placeholder="SSN"
              sx={textFieldStyle}
            >
            </TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["birthDate"] ? false : true} value={personalInfo["birthDate"]} InputLabelProps={{ shrink: true }} label={personalInfo["birthDate"] ? "Date of Birth" : ''} sx={textFieldStyle}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["race"] ? false : true} value={personalInfo["race"]} InputLabelProps={{ shrink: true }} label={personalInfo["race"] ? "Race" : ''} sx={textFieldStyle}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["gender"] ? false : true} value={personalInfo["gender"]} InputLabelProps={{ shrink: true }} label={personalInfo["gender"] ? "Gender" : ''} sx={{ flex: 1, height: "56px" }} />
          </Container>

          <Container disableGutters maxWidth={false} sx={inputContainer}>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["height"] ? false : true} value={personalInfo["height"]} label={personalInfo["height"] ? "Height" : ''} placeholder="height" InputLabelProps={{ shrink: true }} sx={textFieldStyle}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["weight"] ? false : true} value={personalInfo["weight"]} label="Weight" placeholder={personalInfo["weight"] ? "weight" : ''} InputLabelProps={{ shrink: true }} sx={textFieldStyle}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["hairColor"] ? false : true} value={personalInfo["hairColor"]} label={personalInfo["hairColor"] ? "Hair color" : ''} placeholder="hair color" InputLabelProps={{ shrink: true }} sx={textFieldStyle}></TextField>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["eyeColor"] ? false : true} value={personalInfo["eyeColor"]} label={personalInfo["eyeColor"] ? "Eye color" : ''} InputLabelProps={{ shrink: true }} sx={{ flex: 1, height: "56px" }} />
          </Container>

          <Container disableGutters maxWidth={false} sx={inputContainer}>
            <TextField InputProps={{ disabled: true }} disabled={personalInfo["email"] ? false : true} value={personalInfo["email"] ? personalInfo["email"] : ""} label={personalInfo["email"] ? "Email address" : ''} placeholder="example@email.com" InputLabelProps={{ shrink: true }} sx={{ flex: 2, height: "56px" }}></TextField>
          </Container>
        </>
      }
      <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%', marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />
      <Container disableGutters maxWidth={false} sx={sectionHeaderStyle}>
        <Typography variant="h6" color='text.primary'  >TRANSACTIONAL INFORMATION</Typography>
        <Button size="small" variant="text" color="primary" endIcon={!isSectionVisible('transaction-info') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} onClick={() => hideSection('transaction-info')}>{!isSectionVisible('transaction-info') ? 'HIDE' : 'SHOW'}</Button>
      </Container>
      {!isSectionVisible('transaction-info') &&
        <>
          <Container disableGutters maxWidth={false} sx={inputContainer}>
            <TextField InputProps={{ disabled: true }} disabled={transactionalInfo["UEID"] ? false : true} value={transactionalInfo["UEID"]} InputLabelProps={{ shrink: true }} label={transactionalInfo["UEID"] ? "UEID / REGID" : ''} placeholder="UEID / REGID" sx={textFieldStyle} />
            <TextField InputProps={{ disabled: true }} disabled={transactionalInfo["currentStatus"] ? false : true} value={transactionalInfo["currentStatus"]} InputLabelProps={{ shrink: true }} label={transactionalInfo["currentStatus"] ? "Current status" : ''} placeholder="Current status" sx={textFieldStyle} />
            <TextField InputProps={{ disabled: true }} disabled={transactionalInfo["ORI"] ? false : true} value={transactionalInfo["ORI"]} InputLabelProps={{ shrink: true }} label={transactionalInfo["ORI"] ? "ORI" : ''} placeholder="ORI" sx={textFieldStyle} />
            <TextField InputProps={{ disabled: true }} disabled={transactionalInfo["secondaryOri"] ? false : true} value={transactionalInfo["secondaryOri"]} InputLabelProps={{ shrink: true }} label={transactionalInfo["secondaryOri"] ? "Secondary ORI" : ''} placeholder="secondaryOri" sx={textFieldStyle} />
          </Container>
          <Container disableGutters maxWidth={false} sx={inputContainer}>
            <TextField disabled={transactionalInfo["reasonFingerprinted"] ? true : false} value={transactionalInfo["reasonFingerprinted"]} InputLabelProps={{ shrink: true }} label={transactionalInfo["reasonFingerprinted"] ? "Reason Literal" : ''} placeholder="Reason Fingerprinted" sx={textFieldStyle} />
            <TextField InputProps={{ disabled: true }} disabled={transactionalInfo["transactionNumber"] ? false : true} value={transactionalInfo["transactionNumber"]} InputLabelProps={{ shrink: true }} label={transactionalInfo["transactionNumber"] ? "Transaction number" : ''} placeholder="001100010010" sx={textFieldStyle} />
            <TextField InputProps={{ disabled: true }} disabled={transactionalInfo["transactionType"] ? false : true} value={transactionalInfo["transactionType"]} InputLabelProps={{ shrink: true }} label={transactionalInfo["transactionType"] ? "Transaction type" : ''} placeholder="Transaction type" sx={textFieldStyle} />
            <TextField InputProps={{ disabled: true }} disabled={transactionalInfo["lastModifiedBy"] ? false : true} value={transactionalInfo["lastModifiedBy"]} InputLabelProps={{ shrink: true }} label={transactionalInfo["lastModifiedBy"] ? "Last modified by" : ''} placeholder="12/31/1999" sx={textFieldStyle} />
          </Container>
          <Container disableGutters maxWidth={false} sx={inputContainer}>
            <TextField InputProps={{ disabled: true }} disabled={transactionalInfo["agency"] ? false : true} value={transactionalInfo["agency"]} label={transactionalInfo["agency"] ? "Agency name" : ''} InputLabelProps={{ shrink: true }} placeholder="Agency name" sx={textFieldStyle} />
            <TextField InputProps={{ disabled: true }} disabled={transactionalInfo["agencyIdentifier"] ? false : true} value={transactionalInfo["agencyIdentifier"]} label={transactionalInfo["agencyIdentifier"] ? "Agency Identifier" : ''} InputLabelProps={{ shrink: true }} placeholder="Agency Identifier" sx={textFieldStyle} />
          </Container>
        </>
      }

      <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%', marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />

      <Container disableGutters maxWidth={false} sx={sectionHeaderStyle}>
        <Typography variant="h6" color='text.primary'>STATUS HISTORY</Typography>
        <Button size="small" variant="text" color="primary" endIcon={!isSectionVisible('status-info') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} onClick={() => hideSection('status-info')}>{!isSectionVisible('status-info') ? 'HIDE' : 'SHOW'}</Button>
      </Container>
      {!isSectionVisible('status-info') &&
        <Container disableGutters maxWidth={false} sx={{ marginTop: "24px", marginBottom: "24px", width: "100%" }}>
          <CustomTable
            tableName="status-history"
            columnState={
              {
                columnVisibility: {
                  status: true,
                  statusDate: true,
                  agency: true
                },
                filterStatus: {}
              }
            }
            savedSearchState={[{}, () => { }]}
            pageSize={multiTablePagination["status-history"].detailRowsPerPage}
            totalRecords={statusHistories?.length || 0}
            currentPage={multiTablePagination["status-history"].detailPage}
            hideSearch={true}
            defaultSortColumn="statusDate"
            rows={statusHistories?.length ? statusHistories : [{ status: "NO DATA" }]}
          />
        </Container>
      }
      {
        <>
          <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%', marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />
          <Container disableGutters maxWidth={false} sx={sectionHeaderStyle}>
            <Typography variant="h6" color='text.primary'>ADJUDICATION DOCUMENTS</Typography>
            <Button size="small" variant="text" color="primary" endIcon={!isSectionVisible('adjudication-doc') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} onClick={() => hideSection('adjudication-doc')}>{!isSectionVisible('adjudication-doc') ? 'HIDE' : 'SHOW'}</Button>
          </Container>
          {!isSectionVisible('adjudication-doc') &&
            <Container disableGutters maxWidth={false} sx={{ marginTop: "24px", marginBottom: "24px", width: "100%" }}>
              <CustomTable
                tableName="adjudication-docs"
                columnState={{
                  columnVisibility: {
                    addedDate: true,
                    decision: true,
                    fileNameControl: true,
                    actions: true
                  },
                  filterStatus: {
                    decisionComplete: { selected: true, label: "Decision Complete" }
                  }
                }}
                savedSearchState={[{}, () => { }]}
                pageSize={multiTablePagination["adjudication-docs"].detailRowsPerPage}
                totalRecords={adjudicationDocuments?.length || 0}
                currentPage={multiTablePagination["adjudication-docs"].detailPage}
                hideSearch={true}
                defaultSortColumn="date"
                rows={adjudicationDocuments?.length ? adjudicationDocuments : [{ addedDate: "NO DATA" }]}
              />
            </Container>
          }
        </>
      }
    </Container>
  );
};
