import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { CustomTable } from "common";
import { useTableStore } from "state/TableStore";
import { LogConfig, logger } from "../../../utils";
import { extractPageName } from "../../../common/CustomTable/helpers";

export const RejectCodeTable: React.FC<any> = ({ rejectData, id, name, totalRejectCodes, loadingRejectCodes }) => {

    const multiTablePagination = useTableStore((state) => state.multiTablePagination)

    let rowsPerPage, page;
    const detailsTableData = (tableName: string, data: any) => {
        const { detailPage, detailRowsPerPage } = multiTablePagination[tableName];
        rowsPerPage = detailRowsPerPage
        page = detailPage
        const startIndex = detailPage * detailRowsPerPage;
        const endIndex = detailPage * startIndex + detailRowsPerPage;
        return data
    };
    let paginatedData = detailsTableData(name, rejectData)

    const debugRejectCodes: LogConfig = {
        identifier: "debugRejectCodes",
        pageName: "transaction-summary",
        thingToDebug: "data loaded",
        paramsToLog: {
            rejectData,
            paginatedData,
            multiTablePagination
        }
    }

    logger(debugRejectCodes)

    return (
        <Container disableGutters maxWidth={false} sx={{ height: "95%", width: "100%" }}>

            <CustomTable
                //Required props
                tableName={name}
                loading={loadingRejectCodes}
                rows={paginatedData?.length ? paginatedData : [{ action: "NO DATA" }]}
                columnState={
                    {
                        columnVisibility: {
                            rejectIndex: true,
                            rejectCode: true,
                            rejectDescription: true
                        },
                        filterStatus: {}
                    }
                }
                hideSearch={true}
                totalRecords={totalRejectCodes}
                currentPage={page}
                pageSize={rowsPerPage}
            />
        </Container>
    )
}