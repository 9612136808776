/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React from "react";
import { useParams } from "react-router-dom";
import { useApplicantDetails } from "hooks/useApplicantDetails";
import { ApplicantDetailsBody } from "./components/ApplicantDetailsBody/ApplicantDetailsBody";
import { ApplicantDetailsHeader } from "./components";
import { ApplicantDetailsProvider } from "./components/ApplicantDetailsContext/ApplicantDetailsProvider";
import { useTransactionHistory } from "hooks/useTransactionHistory";
import { useAggregatedResponses } from "hooks/useAggregatedResponses";
import { useResultsResponse } from "hooks/useResultsResponses";
import { useApplicantData } from "hooks/useApplicantData";
import { useUserRole } from "hooks/useUserRole";
import { useRapSheets } from "hooks/useRapSheets";
import { Container, Breadcrumbs, Typography, Link } from "@mui/material";
import { useExpectedResponse } from "hooks/useExpectedResponses";
import { useUserPermissions } from "contexts"

export const ApplicantDetails: React.FC = () => {
  type MyParams = {
    registrationId: string;
  };
  const { registrationId } = useParams<keyof MyParams>() as MyParams;
  const { mappedHistory, mappedHistoryPerAgency } = useTransactionHistory(registrationId);
  const { applicantInfo, applicantImageUrl, applicantFetchStatus } = useApplicantDetails(registrationId as string);
  const { userRole } = useUserRole();
  const { rapSheets } = useRapSheets(registrationId as string);
  const { resultsResponse } = useResultsResponse(registrationId as string);
  const { expectedResponse } = useExpectedResponse(registrationId);
  const { userProfile, personalInfo, transactionalInfo } = applicantInfo;
  const { applicantData } = useApplicantData(registrationId as string);
  const { permissions } = useUserPermissions();

  console.log('mappedHistory: ', mappedHistory)

  const ids: any = [];
  if (mappedHistoryPerAgency && mappedHistoryPerAgency.length > 0)
    mappedHistoryPerAgency.map((obj: any) => ids.push(obj.uuid));
  const { aggregatedResponses } = useAggregatedResponses(ids);
  console.log(
    "debug applicantData: mappedHistoryPerAgency: ",
    mappedHistoryPerAgency
  );
  console.log(
    `debug applicantData applicantInfo ${JSON.stringify(
      applicantInfo,
      null,
      "\t"
    )}`
  );

  return (
    <ApplicantDetailsProvider>
      <Container maxWidth={false} disableGutters sx={{ overflowY: "hidden", height: "calc(100vh - 64px)", display: "flex", flexDirection: "column", backgroundColor: "background.paper", width: "100%", padding: 3, gap: 0 }}>
          <Breadcrumbs sx={{ width: "100%", height: "30px", gap: 3, display: 'flex', alignItems: 'center' }}>
            <Link underline="none" href="/app/transaction-summary"><Typography variant='body1' color='text.secondary'>Transaction Summary</Typography></Link>
            <Typography variant='body1' color='text.primary' >{userProfile.name}</Typography>
          </Breadcrumbs>
        {userRole && (
          <>
            <ApplicantDetailsHeader
              userRole={userRole}
              userProfile={userProfile}
              applicantImageUrl={applicantImageUrl}
              applicantFetchStatus={applicantFetchStatus}
              registrationId= {registrationId}
              applicantData= {applicantData}
              usersPermissions={permissions}
              aggregatedResponses={aggregatedResponses}
              resultsResponses={resultsResponse}
              transactionalInfo={transactionalInfo}
              expectedResponse={expectedResponse}
            />
            {
               mappedHistory && (
                <ApplicantDetailsBody
                userRole={userRole}
                personalInfo={personalInfo}
                transactionalInfo={transactionalInfo}
                applicantFetchStatus={applicantFetchStatus}
                statusHistories={mappedHistory}
                registrationId={registrationId}
                results={mappedHistoryPerAgency}
                aggregatedResponses={aggregatedResponses}
                rapSheets={rapSheets}
                resultsResponses={resultsResponse}
                usersPermissions={permissions}
              />
              )
            }
          </>
        )}
      </Container>
    </ApplicantDetailsProvider>
  );
};
