import {
    Box,
    Typography,
    Button,
    Modal,
    TextField,
    MenuItem,
    Grid,
} from '@mui/material'
import { USSTATES } from "../helpers/USStates";
import { AddNewAgencyProps } from "@propTypes/index"
import { AgencyManagementStyles } from 'customStyles';
import { getCurrentEnv } from 'utils/getCurrentEnv';

let lowerSliceIndex = 4
let midSliceIndex = 5
let upperSliceIndex = 9

let titleToNameMap = {
    agencyORI: getCurrentEnv === 'me' ? "Primary ORI" : "ORI",
    reasonFingerPrinted: "Reason Fingerprinted",
    agencyName: "Agency Name",
    distributionMethod: "Distribution Method",
    agencyEmail: "E-mail",
    agencyAddress: "Address",
    agencyCity: "City",
    agencyState: "State",
    agencyZipCode: "Zip Code",
    agencyStatus: "Status"
} as any;

export const AddNewAgencyModal: React.FC<AddNewAgencyProps> = ({
    openModal,
    setOpenModal,
    submitNewAgency,
    fieldNames,
    touchedFields,
    fieldError,
    error,
    alreadyExistError,
    oriExists,
    rfpExists,
    handleTextFieldError,
    handleInputChange,
    distributionMethodOptions,
    handleCancel,
    canAddAgency
}) => {
    if (getCurrentEnv === 'me') {
        titleToNameMap = { ...titleToNameMap, secondaryOri: "Secondary ORI" };
        fieldNames = [...fieldNames.slice(0, 1), "secondaryOri", ...fieldNames.slice(1)];
        lowerSliceIndex = 5
        midSliceIndex = 5
        upperSliceIndex = 10
    }

    return (
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <Box sx={AgencyManagementStyles.addAgencyModalBoxStyle}>
                <Typography id='add-new-agency-modal-title' variant='h6' color='text.primary' sx={{ pt: '1.5rem', pb: '0.5rem' }}>
                    ADD NEW AGENCY
                </Typography>
                <form style={{ paddingTop: '1rem' }} onSubmit={submitNewAgency}>
                    <Grid container spacing={2} sx={{ mb: '1.5rem' }}>
                        {fieldNames.slice(0, lowerSliceIndex).map((fieldName: any, index: any) => {
                            const isError = fieldName.includes('agencyORI') || fieldName.includes('reasonFingerPrinted')
                            const requiredField = !fieldName.includes('secondaryOri')
                            return (
                                <Grid item xs={6} >
                                    <TextField
                                        fullWidth
                                        key={titleToNameMap[fieldName]}
                                        label={titleToNameMap[fieldName]}
                                        defaultValue={fieldName.includes('distributionMethod') ? '' : ''}
                                        helperText={touchedFields[fieldName] ? error : isError ? alreadyExistError : ''}
                                        error={touchedFields[fieldName] ? fieldError : isError ? (oriExists && rfpExists) : false}
                                        onBlur={(e) => handleTextFieldError(e, fieldName)}
                                        select={fieldName.includes('distributionMethod')}
                                        required={fieldName.includes('secondaryOri') ? false : true}
                                        onChange={(e) => handleInputChange(fieldName, e.target.value)}
                                    >
                                        {distributionMethodOptions.map((options: any) => {
                                            return (
                                                <MenuItem value={options.value} sx={{ backgroundColor: 'background.default' }}>{options.value}</MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </Grid>
                            )
                        })}

                        {getCurrentEnv !== 'me' &&
                            <Grid item xs={12} >
                                <TextField
                                    fullWidth
                                    key={titleToNameMap['agencyEmail']}
                                    label={titleToNameMap['agencyEmail']}
                                    inputProps={{
                                        pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$",
                                    }}
                                    onChange={(e) => handleInputChange('agencyEmail', e.target.value)} />
                            </Grid>
                        }

                        {fieldNames.slice(midSliceIndex, upperSliceIndex).map((fieldName: any, index: any) => {
                            return (
                                <Grid item xs={6} >
                                    <TextField
                                        key={fieldName}
                                        onChange={(e) => handleInputChange(fieldName, e.target.value)}
                                        fullWidth
                                        helperText={touchedFields[fieldName] ? error : false}
                                        error={touchedFields[fieldName] ? fieldError : false}
                                        onBlur={(e) => handleTextFieldError(e, fieldName)}
                                        label={titleToNameMap[fieldName]}
                                        select={fieldName.includes('agencyState')}
                                        defaultValue={fieldName.includes('agencyState') ? '' : ''}
                                        required={fieldName.includes('agencyEmail') ? false : true}
                                        inputProps={fieldName.includes('agencyZipCode') ? {
                                            pattern: "[0-9]*",
                                            maxLength: 5
                                        } : {}}
                                        SelectProps={{
                                            MenuProps: {
                                                style: {
                                                    height: '28.125rem'
                                                }
                                            }
                                        }}
                                    >
                                        {USSTATES.map((value: any) => {
                                            return (
                                                <MenuItem key={value.label} value={value.value} sx={{ overflowY: 'auto', backgroundColor: 'background.default' }} >
                                                    {`${value.value} - ${value.label}`}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </Grid>
                            )
                        })}
                        {/* <Grid item xs={12} >
                            <TextField fullWidth label={titleToNameMap['agencyStatus']} defaultValue='Active' select required disabled>
                                <MenuItem value='Active'>Active</MenuItem>
                                <MenuItem value='Inactive'>Inactive</MenuItem>
                            </TextField>
                        </Grid> */}
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', pb: '1rem' }}>
                        <Button variant='outlined' color='secondary' sx={{ color: 'text.primary', mr: '1rem' }} onClick={handleCancel}>
                            CANCEL
                        </Button>
                        <Button type="submit" variant='contained' color='secondary' disabled={!canAddAgency ? true : Boolean(error) ? true : false}>
                            ADD AGENCY
                        </Button>
                    </Grid>
                </form>
            </Box>
        </Modal>
    )
}