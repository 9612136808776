export enum AttributeNames {
    CUSTOM_ORI = "custom:ori",
    CUSTOM_ORI_LONG = "custom:ori-long",
    SUB = "sub",
    EMAIL_VERIFIED = "email_verified",
    CUSTOM_FIRST_TIME_SIGNED_IN = "custom:firstTimeSignedIn",
    CUSTOM_SORI = "custom:sori",
    GIVEN_NAME = "given_name",
    FAMILY_NAME = "family_name",
    EMAIL = "email",
}

export enum GroupNames {
    CREATION_DATE = "CreationDate",
    DESCRIPTION = "Description",
    GROUP_NAME = "GroupName"
}

export type UserAttribute = {
    Name: AttributeNames
    Value: string
}

export type UserGroup = {
    Name: GroupNames
    Value: string
}

export type UserAttributes = UserAttribute[]
export type userGroup = UserGroup[]

export interface UserManagementData {
    Users: Users
    PaginationToken: string
}

export interface UserInfo {
    Username: string
    Attributes: UserAttributes
    UserCreatedDate: string
    UserLastModifiedDate: string
    Enabled: boolean
    UserStatus: string
    userGroup: userGroup
}

export type Users = UserInfo[]

export interface UserRow {
    key: string
    fullName: string | undefined
    loginName: string | undefined
    securityLevel: string | undefined
    status: string | undefined
    emailAddress: string | undefined
    primaryOri: string | undefined
    secondaryOri: string | undefined
    reasonFingerprinted: string | undefined
    lastModified: string | undefined
    securityAndStatus: {securityLevel: string, status: string} | undefined
    oriRfpGroups: Array<{}>
    userStatus: string | undefined,
    loginStatus: string | undefined
}